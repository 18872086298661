import React from 'react'
import {Link} from 'react-router-dom'
import './footer.css'
import logo from './../../../autresImages/logosAlfajiri.png'

const Footer = () => {
  return (
    <>
      {/*  <section className='newLetter'>
        {<div className='container flexSB'>
          <div className='left row'>
            <h1>Infolettre : Restez informé sur les dernières mises à jour</h1>
            <span>Toujours près du Collège</span>
          </div>
          <div className='right row'>
            <input
              type='email'
              name='email'
              id='email'
              placeholder='Entrez votre adresse email'
            />
            <i className='fa fa-paper-plane'></i>
          </div>
        </div>}
      </section> */}
      <div className='separator'></div>
      <footer>
        <div className='flexSB footerContainer'>
          <div className='logoCollege'>
            <img src={logo} alt='' height={150} />
            <div className='socialFooter'>
              <p>
                Au Collège se forge en toi <br /> l'homme/la femme de demain
              </p>

              <i className='fab fa-facebook-f icon'></i>
              <i className='fab fa-instagram icon'></i>
              <i className='fab fa-twitter icon'></i>
              <i className='fab fa-youtube icon'></i>
            </div>
          </div>
          <div className='separation'></div>
          <div className='flexSB footerContent'>
            <div className='col apropos'>
              <Link to='/apropos'>
                <h3>A propos</h3>
              </Link>
              <div className='links'>
                <ul>
                  <li>
                    <Link to='/historique'>Historique</Link>
                    <Link to='/comingsoon'>Structure</Link>
                    <Link to='/comingsoon'>Organigramme</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col roi'>
              <h3>R.O.I.</h3>
              <div className='links'>
                <ul>
                  <li>
                    <Link to='/roi/roimaternelle'>Ecole Maternelle</Link>
                    <Link to='/roi/roiprimaire'>Ecole Primaire</Link>
                    <Link to='/roi/roisecondaire'>Ecole Secondaire</Link>
                    <Link to='/roi/roiinternat'>Ecole Internat</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col publications'>
              <h3>Publications</h3>
              <div className='links'>
                <ul>
                  <li>
                    <Link to='/comingsoon'>Revue Ngoma</Link>
                    <Link to='/comingsoon'>Revue Reminder</Link>
                    <Link to='/comingsoon'>Alfa News</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col inscription'>
              <h3>Inscriptions</h3>
              <div className='links'>
                <ul>
                  <li>
                    <Link to='/inscriptionMaternelle'>Maternelle</Link>
                    <Link to='/inscriptionPrimaire'>Primaire</Link>
                    <Link to='/comingsoon'>Secondaire</Link>
                    <Link to='/comingsoon'>C.S.P.</Link>
                    <Link to='/comingsoon'>Internat</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col pedagogie'>
              <h3>Pedagogie</h3>
              <div className='links'>
                <ul>
                  <li>
                    <Link to='/pedagogie/calendrierscolaire'>
                      Calendrier Scolaire
                    </Link>
                    <Link to='/pedagogie/horairecours'>Horaire des cours</Link>
                    <Link to='/pedagogie/programmecours'>
                      Programme des cours
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col parascolaire'>
              <h3>Parascolaire</h3>
              <div className='links'>
                <ul>
                  <li>
                    <Link to='/comingsoon'>Spiritualité</Link>
                  </li>
                  <li>
                    <Link to='/comingsoon'>Sport</Link>
                  </li>
                  <li>
                    <Link to='/comingsoon'>Culture et Art</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div className='legal'>
        <p>Copyright 2024 All rights reserved | Collège Alfajiri</p>
      </div>
    </>
  )
}

export default Footer
