import React from 'react'
import {FaAddressBook, FaAdversal, FaSchlix} from 'react-icons/fa'

const AWrapper2 = () => {
  return (
    <div>
      <section className='awrapper2'>
        <div className='container grid'>
          <div className='box flex'>
            <div className='im'></div>
            <FaSchlix className='iconReact' />
            <div className='text'>
              <h1>150</h1>
              <h1>Personnels</h1>
            </div>
          </div>
          <div className='box flex'>
            <div className='im'></div>
            <FaAddressBook className='iconReact' />
            <div className='text'>
              <h1>2900</h1>
              <h1>Elèves</h1>
            </div>
          </div>
          <div className='box flex'>
            <div className='im'></div>
            <FaAdversal className='iconReact' />
            <div className='text'>
              <h1>60</h1>
              <h1>Classes</h1>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default AWrapper2
