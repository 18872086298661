import React, {useState} from 'react'
import {motion} from 'framer-motion'
import './galerie.css'

import {Player} from 'video-react'
import prayer from './../../autresImages/prayer.mp4'
import {imageDatae} from './galerieStore'

const GalerieContent = () => {
  const [currentVue, setCurrentVue] = useState(2)

  return (
    <div>
      <div className='galerieContainer'>
        <h1>Le Collège en Image</h1>
        <div
          className='galerieVue'
          style={{
            backgroundImage: `url(${imageDatae[currentVue].image})`,
            width: '50%',
            height: '50%',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div className='imageDetails'>
            <h1>{imageDatae[currentVue].titre}</h1>{' '}
            <h3>{imageDatae[currentVue].description}</h3>{' '}
          </div>
        </div>
        <motion.div className='caroussel'>
          <motion.div className='galerieSlider'>
            {imageDatae.map((image, index) => {
              return (
                <motion.div className='galerieCaroussel'>
                  <img
                    className='items'
                    src={image.image}
                    alt={image.description}
                    height={180}
                    onClick={() => setCurrentVue(image.id - 1)}
                  />
                </motion.div>
              )
            })}
          </motion.div>
        </motion.div>
      </div>
    </div>
  )
}

export default GalerieContent
