import React from 'react'
import {Link} from 'react-router-dom'
import './roi.css'
import {FaBaby, FaBuilding, FaChild, FaPersonBooth} from 'react-icons/fa'
import CardItem from '../common/card/CardItem'
import AWrapper from '../about/AWrapper'

function RoiCard() {
  const icons = ['<FaBaby/>', 'FaChild']
  return (
    <>
      <section className='aboutHome roiContainer '>
        <div className='roiCard row roiCard'>
          <h3>Parcourir et lire ...</h3>
          <div className='items roiItems'>
            <div className='item flexSB roiItem'>
              <Link to='/roi/roimaternelle'>
                <CardItem
                  itemIcon={<FaBaby className='iconReact' />}
                  itemTitle='Ecole maternelle'
                  itemText='Le Collège Alfajiri organise cette année les classes de troisième maternelle pour les enfants  ...'
                />
              </Link>
            </div>

            <div className='item flexSB roiItem'>
              <Link to='/roi/roiprimaire'>
                <CardItem
                  itemIcon={<FaChild className='iconReact' />}
                  itemTitle='Ecole Primaire'
                  itemText='Avant de quitter la maison, l’élève doit s’assurer que le responsable (papa/maman/tuteur) a signé ...'
                />
              </Link>
            </div>
            <div className='item flexSB roiItem'>
              <Link to='/roi/roisecondaire'>
                <CardItem
                  itemIcon={<FaPersonBooth className='iconReact' />}
                  itemTitle='Ecole Secondaire'
                  itemText=' Le port de l’uniforme est obligatoire tous les jours ouvrables. Les élèves doivent être propres et  ...'
                />
              </Link>
            </div>

            <div className='item flexSB roiItem'>
              <Link to='/roi/roiinternat'>
                <CardItem
                  itemIcon={<FaBuilding className='iconReact' />}
                  itemTitle='Internat'
                  itemText=' L’internat est un milieu éducatif dans lequel les élèves constituent une famille. Chaque interne doit  ...'
                />
              </Link>
            </div>
          </div>
        </div>
      </section>
      <AWrapper />
    </>
  )
}

export default RoiCard
