import React from 'react'
import ArticleCard from '../common/articleCard/ArticleCard'
import Back from '../common/back/Back'
import eleve from '../../autresImages/Second/rentrEScolaire.jpg'

const EventCard = () => {
  return (
    <div>
      <Back title={'Evenements'} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-around',
          /*  backgroundColor: 'red', */
        }}
      >
        <ArticleCard
          image={eleve}
          titre={'Rentrée scolaire 2023-2024'}
          imageDescription={'Lancement des activités parascolaires'}
          sousTitre={
            'L’effectivité de la rentrée scolaire au collège Alfajiri s’est fait remarquer ...'
          }
          link={'/rentreeScolaire2324'}
        />
      </div>
    </div>
  )
}

export default EventCard
