import React from 'react'
import eleve from '../../../autresImages/Second/culture4.jpg'
import './articleCard.css'
import {Link} from 'react-router-dom'
const ArticleCard = ({titre, sousTitre, link, image, imageDescription}) => {
  return (
    <Link
      style={{
        width: '400px',
        height: '400px',
        /*   display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center', */
      }}
      to={link}
    >
      <div
        className='articleCard'
        style={{
          height: '100%',
          width: '100%',
          paddingBottom: 15,
          borderRadius: 20,
          cursor: 'pointer',
        }}
      >
        <div style={{width: '100%'}}>
          <img
            style={{borderTopLeftRadius: 20, borderTopRightRadius: 20}}
            width={'100%'}
            height={'100%'}
            src={image}
            alt={imageDescription}
          />
        </div>
        <div style={{padding: 20}}>
          <h3 style={{fontSize: '20px', color: 'rgb(25, 87, 219)'}}>{titre}</h3>
          <p>{sousTitre}</p>
        </div>
      </div>
    </Link>
  )
}

export default ArticleCard
