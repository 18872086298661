export const roiMaternelle = [{
        article: 'Article 1',
        intitulE: 'Le Collège Alfajiri organise cette année les classes de troisième maternelle pour les enfants agés de cinq ans et les classes de deuxième maternelle pour les enfants de quatre ans.',
    },
    {
        article: 'Article 2',
        intitulE: ' Le but poursuivi par l’école maternelle du collège Alfajiri est d’assurer le plein épanouissement de l’enfant sur le plan social, intellectuel, spirituel et humain. Il s’agit d’éveiller tous les sens de l’enfant pour le préparer à l’enseignement primaire et à la vision éducative jésuite.',
    },
    {
        article: 'Article 3',
        intitulE: " L'école s'occupe des fournitures scolaires de son enfant. Celles-ci doivent être complètes et déposée à la direction de l'école au moins deux jours avant la rentrée scolaire.",
    },
    {
        article: 'Article 4',
        intitulE: ' Les cours se donnent du lundi au vendredi de 07h30 à 12h30.',
    },
    {
        article: 'Article 5',
        intitulE: "Toute absence et tout retard à l’école doivent être justifiés par écrirt à l'arrivée de l'enfant. Les absences dues aux maladies sont l'object d'un billet médical dûment établi. Tout retrait de l'enfant pendant les heures de cours nécessite un billetde sortie de la direction.",
    },
    {
        article: 'Article 6',
        intitulE: "L’école décline toute responsabilité sur les incidents qui se produirait en dehors des heures susmentionnées de cours. Chaque enfant doit être accompagné jusqu'à école et être repris de l'école dès 12h30.",
    },
    {
        article: 'Article 7',
        intitulE: 'Chaque enfant doit se munir de son cahier de communication pour un contrat régulier entre la famille et l’école. Les parents ou le tuteur de l’enfant doivent le consulter chaque jour et s’assurer chaque matin qu’il est signé. Tout manque d’intérêt à son cahier de communication sera pénalisé.',
    },
    {
        article: 'Article 8',
        intitulE: 'L’enfant doit se présenter à l’école propre, à temps et muni de sa collation.',
    },
    {
        article: 'Article 9',
        intitulE: 'Aucun enfant ne peut venir à l’école malade. Par ailleurs, si un enfant est sous médication, les parents doivent en informer la direction.',
    },

    {
        article: 'Article 10',
        intitulE: 'L’enfant atteint d’une maladie contagieuse doit rester à la maison jusqu’à la guérison complète. Il ne peut être admis à l’école que sur présentation d’un certificat médical de guérison signé par un médecin d’une institution médicale reconnue.',
    },
    {
        article: 'Article 11',
        intitulE: 'Tout enfant atteint d’un handicap physique ou mentale qui l’empêcherait de s’adapter normalement à la vie de l’école devra quitter celle-ci.',
    },
    {
        article: 'Article 12',
        intitulE: 'Les parents doivent signaler à l’école tout changement d’adresse de résidence et toute absence prolongée et communiquer le nom du responsable suppléant.',
    },
    {
        article: 'Article 13',
        intitulE: 'Les visites des enfants à l’école ne sont pas autorisées. En cas de nécessité, les parents s’adresseront à la direction de l’école.',
    },
    {
        article: 'Article 14',
        intitulE: 'La direction reçoit les parents à partir de 10h00, sauf en cas d’extrême urgence.',
    },
    {
        article: 'Article 15',
        intitulE: 'Lors des réunions avec les parents, aucune autre personne ne peut y prendre part à la place des parents.',
    },
    {
        article: 'Article 16',
        intitulE: 'Toute détérioration ou casse d’un bien de l’école ou d’un autre enfant, causée volontairement ou par négligence par un enfant, doit être réparée par la famille de l’auteur.',
    },
    {
        article: 'Article 17',
        intitulE: 'Les transgressions du présent règlement seront sanctionnées conformément à la gravité de la faute.',
    },
]

export const roiPrimaire = [{
        article: 'Article 1',
        intitulE: 'Avant de quitter la maison, l’élève doit s’assurer que le responsable (papa/maman/tuteur) a signé son Journal de classe, son cahier de communication, ses cahiers de devoirs ou son bulletin.',
    },
    {
        article: 'Article 2',
        intitulE: 'L’élève doit, dès la rentrée, avoir tous les objets classiques et les manuels scolaires. Le collège appréciera le délai pour renvoyer les cas de récidive.',
    },
    {
        article: 'Article 3',
        intitulE: 'Le port de l’uniforme est obligatoire tous les jours ouvrables. L’élève doit être propre et dans une tenue vestimentaire décente : chemise/blouse blanche rentrée dans une culotte/jupe bleue, chaussures convenables, coiffure règlementaire. Les jupes doivent être suffisamment amples. Pas de T-shirt avec inscription fantaisiste ou avec de la réclame. Pas de tenue de couleur voyante en dessous de la chemise/blouse blanche.',
    },
    {
        article: 'Article 4',
        intitulE: 'Les bijoux, les maquillages, les tresses, les casquettes ou chapeaux, les téléphones mobiles et les appareils électroniques sont prohibés au collège. Tout manquement sera sanctionné. La sanction variera de la confiscation de l’objet à l’exclusion de l’élève récidiviste.',
    },
    {
        article: 'Article 5',
        intitulE: 'Les cours commencent à 7h 30 et se terminent à 12 h 30 ou à 11h 30 le samedi. Il y a une récréation de 10h°° à 10h 20. A la sonnerie pour le rassemblement les jeux cessent et le silence est obligatoire. Avant l’arrivée au collège et après la sortie des classes, l’élève n’est pas sous la responsabilité du collège.',
    },
    {
        article: 'Article 6',
        intitulE: 'En cas de maladie ou d’un légitime empêchement de l’élève, les parents doivent aviser la direction le plus tôt possible. Les absences dues aux maladies font l’objet d’un billet médical. La sortie d’un élève pendant les heures de cours nécessite un billet de sortie émanant de la direction.',
    },
    {
        article: 'Article 7',
        intitulE: 'Pour se rendre à l’infirmerie du collège, l’élève malade doit se munir de son Journal de classe annoté par l’enseignant de sa classe.',
    },
    {
        article: 'Article 8',
        intitulE: 'Toute absence et tout retard doivent être justifiés par une note à l’arrivée de l’enfant. Les absences et retards répétés peuvent entrainer une exclusion temporaire ou définitive de l’élève, le conseil de discipline entendu.',
    },
    {
        article: 'Article 9',
        intitulE: 'Tout cas de fraude, faux en écriture ou usage de faux, corruption, vol, coups, bagarre ou grossièreté ou toutes autres formes d’inconduite notoire entraine une exclusion temporaire ou définitive, le conseil de discipline entendu.',
    },
    {
        article: 'Article 10',
        intitulE: 'Toute tentative ou recours à des moyens frauduleux ou à la tricherie entraine la note zéro et expose l’élève à une exclusion. La participation aux interrogations, devoirs, examens et autres contrôles des connaissances est un droit et un devoir. Aucun élève ne peut s’y soustraire.',
    },
    {
        article: 'Article 11',
        intitulE: 'Tout cas d’injures, impolitesse ou insolence à l’endroit d’un éducateur, désobéissance ou vagabondage, peut être sanctionné par une exclusion temporaire ou définitive, le conseil de discipline entendu.',
    },
    {
        article: 'Article 12',
        intitulE: 'Le respect vis-à-vis des éducateurs et des visiteurs de l’école doit se manifester par une tenue soignée, un langage correct et une attitude courtoise. Ce respect doit se manifester aussi bien à l’intérieur qu’à l’extérieur de l’école.',
    },
    {
        article: 'Article 13',
        intitulE: 'Toute détérioration ou casse d’un bien du collège ou d’une autre personne élève/enseignant, causée volontairement ou par négligence par un élève, doit être réparée par le responsable de l’auteur.',
    },
    {
        article: 'Article 14',
        intitulE: 'Il est interdit aux élèves de recevoir des cours privés ou des leçons de répétition dispensée par des enseignants du collège (du primaire ou du secondaire). Si des cours supplémentaires s’avèrent nécessaires pour un élève et si ses parents expriment le désir d’une aide supplémentaire, la direction s’en chargera et en définira les modalités dans le temps et dans l’espace.',
    },
    {
        article: 'Article 15',
        intitulE: 'Toute question concernant les études se traite seulement avec la direction de l’école de l’élève.',
    },
    {
        article: 'Article 16',
        intitulE: 'L’élève est tenu à participer dans au moins une des disciplines sportives ou culturelles organisées au collège, sauf avis contraire justifié du médecin. L’élève sélectionné par l’école pour une manifestation sportive ou culturelle ne peut pas s’y soustraire.',
    },
    {
        article: 'Article 17',
        intitulE: 'Le français est la seule langue de communication au sein du collège.',
    },
    {
        article: 'Article 18',
        intitulE: 'L’élève souffrant d’une maladie contagieuse doit rester à la maison jusqu’à la guérison complète. Il ne peut être réadmis à l’école que sur présentation d’un certificat médical de guérison.',
    },
    {
        article: 'Article 19',
        intitulE: 'L’élève est tenu à signaler à l’école toutes les vaccinations reçues, tout changement d’adresse de résidence, toute absence prolongée du responsable et le nom de son suppléant.',
    },
    {
        article: 'Article 20',
        intitulE: 'Tout cas de transgression grave ou répétée du présent ROI peut entrainer une exclusion définitive si le conseil de discipline le décide.',
    },
]

export const roiSecondaire = [{
        article: 'Article 1',
        intitulE: 'Le port de l’uniforme est obligatoire tous les jours ouvrables. Les élèves doivent être propres et porter une tenue vestimentaire décente : chemise/blouse blanche rentrée dans un (e) pantalon/jupe bleu (e), chaussures convenables, coiffure réglementaire. Les jupes doivent être suffisamment amples. Pas de T-shirt avec inscription fantaisiste ou avec de la réclame. Pas de tenue de couleur voyante en dessous de la chemise/ blouse blanche.',
    },
    {
        article: 'Article 2',
        intitulE: ' Les bijoux, les maquillages, les tresses, les casquettes ou chapeaux, les téléphones mobiles, les appareils électroniques autre que les calculatrices sont prohibés au collège. Tout manquement sera sanctionné. La sanction variera de la confiscation définitive de l’objet à l’exclusion de l’élève récidiviste.',
    },
    {
        article: 'Article 3',
        intitulE: 'Le Journal de classe est obligatoire tous les jours. C’est la carte d’accès en classe. Il doit être visé par le parent ou le tuteur régulièrement et nécessairement lors d’un avis aux parents. Il contient le cahier de communication entre la famille et l’école.',
    },
    {
        article: 'Article 4',
        intitulE: 'Les cours commencent à 7h 30 tous les jours ouvrables. Il y a une récréation de 10h°° à 10h 20. Au premier coup de sonnerie à 7h 25 et à 10h 18, les élèves se mettent rapidement en rangs. Au deuxième coup de sonnerie à 7h 28 et à 10h 19, ils font le silence complet, se dirigent vers leurs locaux de classe et entrent en classe en bon ordre accompagnés de leurs professeurs. Une classe qui est sans professeur attend le signal de la direction de discipline pour se rendre en classe.',
    },
    {
        article: 'Article 5',
        intitulE: 'Les places attribuées en classe sont fixes. Seul le professeur titulaire de la classe est habilité à changer l’un ou l’autre élève de place. Aucun élève ne peut changer de place sans l’accord du titulaire de la classe.',
    },
    {
        article: 'Article 6',
        intitulE: 'Une sortie de classe pendant les heures de cours doit relever de l’exceptionnel (cas de maladie ou de convocation à la direction).',
    },
    {
        article: 'Article 7',
        intitulE: 'Lors d’un changement de local de cours, les élèves doivent se déplacer sans tarder, en ordre, en silence et sans trainer les pieds.',
    },
    {
        article: 'Article 8',
        intitulE: 'Tout élève exclu d’un cours par un professeur pour une raison grave doit se présenter immédiatement à la direction de discipline qui lui signifiera sa sanction.',
    },
    {
        article: 'Article 9',
        intitulE: 'A l’interruption de 10h °°, aucun élève ne peut rester trainer dans les locaux, les barzas et le hall. Tous se tiennent dans la cour ou sur la terrasse du collège. Il est interdit de quitter l’enceinte du collège. Ni avant, ni pendant, ni après les cours, les élèves ne doivent stationner de manière désœuvrée dans la rue ou à l’entrée du collège ou aux alentours des écoles voisines.',
    },
    {
        article: 'Article 10',
        intitulE: 'L’élève qui doit s’absenter ou est empêché d’assister à un cours, présente à la direction de discipline une demande écrite et signée par ses parents ou ses responsables. En cas de maladie ou d’empêchement légitime et imprévu, les parents font connaitre sans tarder à la direction la raison de l’absence de leur enfant.',
    },
    {
        article: 'Article 11',
        intitulE: 'L’élève qui a été absent se présente avant la sonnerie à la direction de discipline, muni de son journal de classe et des éventuelles pièces justificatives. L’élève qui est en retard se présente d’abord à la direction de discipline. Il ne sera pas reçu en classe sans un billet émanant de la direction de discipline. Toute absence ou tout retard non justifié sera sanctionné.',
    },
    {
        article: 'Article 12',
        intitulE: 'L’élève qui a manqué une interrogation ou un travail coté retire à la direction de discipline un billet à présenter promptement au professeur pour rattraper l’interrogation ou le travail raté. Sans billet ou présentation immédiate du billet, l’élève obtiendra la note zéro à cette interrogation ou au travail manqué. L’élève ne doit pas attendre la dernière semaine de la période pour réclamer les interrogations ou travaux manqués.',
    },
    {
        article: 'Article 13',
        intitulE: 'Pour se rendre à l’infirmerie, l’élève malade doit se munir de son Journal de classe annoté par le professeur et par la direction de discipline.',
    },
    {
        article: 'Article 14',
        intitulE: 'Le respect vis-à-vis des professeurs, éducateurs et visiteurs de l’école doit être manifesté par une tenue soignée, un langage correct et une attitude courtoise. Ce respect doit se manifester aussi bien à l’intérieur qu’à l’extérieur du collège. On ne reste pas assis quand un éducateur passe.',
    },
    {
        article: 'Article 15',
        intitulE: 'Les élèves contribuent à la propreté du mobilier et de l’immobilier du collège. L’élève qui manquera à son devoir de balayer la classe s’exposera à des mesures disciplinaires. Les élèves veilleront à l’usage des corbeilles et des poubelles pour ne pas jeter au sol ni laisser trainer des morceaux de papier ou d’autres ordures.',
    },
    {
        article: 'Article 16',
        intitulE: 'Les élèves contribuent au maintien en bon état du mobilier et de l’immobilier du collège ainsi que de tous les matériels scolaires. Toute détérioration ou casse d’un bien du collège, volontaire ou par négligence, sera soumise à une amende à la charge de l’auteur. On ne mange pas dans les locaux de classe. Il est interdit de fumer au collège et à ses alentours.',
    },
    {
        article: 'Article 17',
        intitulE: 'Des livres, revues, musiques qui ne favorisent pas l’étude ou la rectitude morale et chrétienne des élèves ne peuvent pas être introduits au collège.',
    },
    {
        article: 'Article 18',
        intitulE: 'Tout cas de tricherie, tentative ou recours à des moyens frauduleux, à la tricherie pendant les interrogations, devoirs, travaux dirigés et examens entraine les sanctions suivantes : la cote mauvaise (Ma) en conduite, la note zéro à cette épreuve, il écope 3 jours d’exclusion de l’école et durant son exclusion, l’élève est obligé de participer à la messe de 6h°° à la Paroisse Saint Pierre Claver de Nguba (avec preuve du Prêtre officiant), obligation d’un accompagnement avec le Père spirituel du Complexe. Tout cas de récidive entrainera une exclusion définitive du collège.',
    },
    {
        article: 'Article 19',
        intitulE: 'Tout cas de fraude, faux en écriture ou usage de faux, corruption, vol, coups, bagarre, grossièreté et toute forme d’inconduite notoire entraine une exclusion temporaire ou définitive, le conseil de discipline entendu.',
    },
    {
        article: 'Article 20',
        intitulE: 'Tout cas d’injures, impolitesse ou insolence à l’endroit d’un éducateur, désobéissance ou vagabondage est sanctionné par une exclusion temporaire ou définitive, le conseil de discipline entendu.',
    },
    {
        article: 'Article 21',
        intitulE: 'Il est interdit aux élèves de recevoir des cours privés ou des leçons de répétition dispensée par des professeurs du collège (du primaire ou du secondaire). Si des cours supplémentaires s’avèrent nécessaires pour un élève et que ses parents en expriment le désir d’une aide pour leur enfant, la direction s’en chargera et en définira les modalités dans le temps et dans l’espace.',
    },
    {
        article: 'Article 22',
        intitulE: 'Pendant la période des examens, l’élève qui ne se présentera pas à un examen le rate. Après un temps raisonnable, aucun retardataire ne sera admis dans la salle d’examen. A la session d’examens du second semestre, chaque élève doit remettre les manuels correspondant aux examens du jour. L’élève qui n’aura pas son manuel se verra renvoyé pour aller le chercher, avec le risque d’arriver au-delà de l’heure réglementaire et de n’être pas admis dans la salle d’examen.',
    },
    {
        article: 'Article 23',
        intitulE: 'Le français et l’anglais sont les seuls langues en usage au sein du collège. La récidive entraine la cote « MEDIOCRE » en conduite. La persistance dans l’erreur entraîne une exclusion temporaire ou définitive, le conseil de discipline entendu.',
    },
    {
        article: 'Article 24',
        intitulE: 'L’étude de l’après – midi est libre, elle devient obligatoire pour tout élève qui s’y inscrit et pout tout élève qui aura échoué à la première période. Le présent ROI est valable aussi aux heures de l’étude.',
    },
    {
        article: 'Article 25',
        intitulE: 'Trois cotes « MEDIOCRE » ou deux « MEDIOCRE » + un « MAUVAIS » ou deux « MAUVAIS » successives en période entrainent une exclusion définitive de l’élève.',
    },
    {
        article: 'Article 26',
        intitulE: 'Tout cas de récidive ou de transgression grave du présent ROI peut entrainer une exclusion définitive si le conseil de discipline le décide. Toute exclusion entraine une cote « MAUVAIS » en conduite.',
    },
    {
        article: 'Article 27',
        intitulE: 'L’élève atteint d’une maladie contagieuse quitte le collège jusqu’à la guérison complète. Il ne pourra être réadmis au collège que sur présentation d’un certificat médical de guérison.',
    },
    {
        article: 'Article 28',
        intitulE: 'L’élève est tenu à signaler à la direction de discipline toutes les vaccinations reçues, tout changement d’adresse de résidence, toute absence de son tuteur hors de la ville et le nom de son remplaçant.',
    },
    {
        article: 'Article 29',
        intitulE: 'L’évaluation d’un élève pour son passage de classe, son redoublement ou son exclusion en fin d’année tient compte à la fois de sa conduite et de son travail en classe selon les critères de délibération connus de l’élève.',
    },
]

export const roiInternat = [{
        article: 'Article 1',
        intitulE: 'L’internat est un milieu éducatif dans lequel les élèves constituent une famille. Chaque interne doit collaborer pour y maintenir un climat d’accueil, de travail, de respect et de cordialité vis-à-vis des autres et à l’égard du personnel.',
    },
    {
        article: 'Article 2',
        intitulE: 'L’internat est ouvert du lundi midi au samedi matin pendant les périodes proposées par la direction ou pendant une période définie par la direction. Les internes qui n’ont pas de parents à Bukavu doivent avoir un répondant chez qui ils se rendront le weekend quand la direction offre cette opportunité. Dans ce cas, le ROI vaut comme les autres jours et un horaire adhoc est proposé aux internes.',
    },
    {
        article: 'Article 3',
        intitulE: 'Le port de l’uniforme est obligatoire tous les jours de 7h°° à 16h°°.',
    },
    {
        article: 'Article 4',
        intitulE: 'Lorsque l’opportunité de passer un week-en en famille a été offerte, c’est le lundi que l’interne arrive avec sa valise personnelle contenant les linges, vêtements nécessaires, PH, une paire de draps propres et une couverture bien prêts pour la semaine ainsi que tout son nécessaire de toilette.',
    },
    {
        article: 'Article 5',
        intitulE: 'L’internat pourvoit à sa vaisselle, assiettes, fourchettes, couteaux, cuillères, verre ou gobelet. « Qui casse paie ! »',
    },
    {
        article: 'Article 6',
        intitulE: 'Pour une bonne marche de la vie en famille, certains élèves assumeront certaines charges pour le bien de tous : réglementaire, chef coq, chargé des sports et de la culture, chargé de la liturgie,…',
    },
    {
        article: 'Article 7',
        intitulE: 'Les internes auront messe tous les mercredis à 18h 30 ou le matin à 06h45. Les réunions de prière sont prohibées à l’internat. La prière personnelle est encouragée, elle se fait en silence et dans la discrétion. Si l’internat est ouvert le weekend, les internes assisteront à la messe du dimanche selon le programme établi.',
    },
    {
        article: 'Article 8',
        intitulE: 'L’étude se fait en silence dans la salle d’étude, les dortoirs, les couloirs sont des zones de silence absolu, quel que soit le moment où on s’y trouve. Les travaux de groupe se font dans l’après –midi et dans un local de classe avec l’accord du surveillant. Il est formellement interdit d’organiser des travaux de groupe le soir.',
    },
    {
        article: 'Article 9',
        intitulE: 'Les sports, les jeux de salle et la détente sont obligatoires aux heures prévues sur l’horaire. Aux heures libres sont interdites les discussions agitées sur les vedettes et stars du sport, de la musique, des films, sur la religion, la politique, les femmes ou les hommes. Sont à proscrire les discussions creuses, stériles et sans conclusion, elles ont pour effet de distraire la discipline de travail.',
    },
    {
        article: 'Article 10',
        intitulE: 'Il n’est pas possible de tenir compte des préférences, des régimes ou des coutumes alimentaires de chaque interne. Tout interne se contentera de ce que lui offrira l’internat. Il est strictement interdit d’introduire à l’internat de la nourriture ou des boissons venues de l’extérieur. Il est à noter que le repas se prend au réfectoire et toute nourriture se mange au réfectoire. La chambre, les couloirs, le hall, les locaux de classe ou la cour ne sont pas des lieux où manger ou pour s’occuper à mâchonner quelque chose, bonbons, chewing-gum, etc.',
    },
    {
        article: 'Article 11',
        intitulE: 'Il est interdit de fumer et de prendre de l’alcool à l’internat.',
    },
    {
        article: 'Article 12',
        intitulE: 'Tout cas de vol, quel qu’il soit, est sanctionné par une exclusion temporaire ou définitive de l’internat.',
    },
    {
        article: 'Article 13',
        intitulE: 'Les internes contribuent au maintien en bon état des bâtiments et matériels scolaires. Ils sont tenus de maintenir en ordre et en bon état de propreté les locaux qu’ils utilisent. Ils doivent veiller à la bonne conservation du mobilier et du matériel mis à leur disposition. Toute détérioration ou casse du bien de l’internat volontaire ou par négligence sera soumise à une amende à payer par le coupable au début de la semaine qui suit.',
    },
    {
        article: 'Article 14',
        intitulE: 'Chaque interne veillera à la propreté de sa chambre, régulièrement balayée. On ne cloue rien et on n’affiche rien aux murs, ni image, ni photo, de quelque genre que ce soit. Personne n’est autorisé à nettoyer sa chambre à un jour autre que le mercredi aux heures indiquées sur l’horaire.',
    },
    {
        article: 'Article 15',
        intitulE: 'Les appareils électroniques audio ou vidéo (radio, ordinateur, lecteur cassette, lecteur vidéo, jeux électroniques, téléphones mobiles, etc) sont prohibés à l’internat. Le fer à repasser est prohibé à l’internat.',
    },
    {
        article: 'Article 16',
        intitulE: 'L’interne ne quitte pas l’enceinte du collège sans une permission explicite du responsable de l’internat ou de son mandaté. Aucune permission ne peut être présupposée.',
    },
    {
        article: 'Article 17',
        intitulE: 'L’accès au dortoir des garçons est strictement interdit aux filles et pareillement celui des filles aux garçons, qu’on soit ou non élève du collège. Les visites en chambre sont prohibées, car elles créent inutilement des occasions de dérangements et de bruit.',
    },
    {
        article: 'Article 18',
        intitulE: 'En cas de maladie, le dispensaire du collège s’occupera de l’interne. Si le cas dépasse les compétences du dispensaire du collège, l’interne malade sera remis à la disposition de ses parents et quittera l’internat.',
    },
    {
        article: 'Article 19',
        intitulE: 'Aucun élève n’est autorisé à accueillir des visites à l’internat, encore moins en chambre, même s’il s’agit de membres de sa famille. Les dortoirs restent la zone privée des internes.',
    },
    {
        article: 'Article 20',
        intitulE: 'L’exclusion de l’internat est prononcée par le Père Recteur ou par le responsable de l’internat, l’élève ayant été entendu.',
    },
]