import React, {useState} from 'react'
import Back from '../../common/back/Back'
// Import the main component
import {Viewer} from '@react-pdf-viewer/core' // install this library
// Plugins
import {defaultLayoutPlugin} from '@react-pdf-viewer/default-layout' // install this library
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
// Worker
import {Worker} from '@react-pdf-viewer/core' // install this library
import reminder1 from './../../../autresImages/Revue Reminder.pdf'
import reminder from '../../../documents/reminder2022.pdf'
import ArticleCard from '../../common/articleCard/ArticleCard'

const Reminder = () => {
  const openDocument = (document) => {
    window.open(document)
  }
  return (
    <>
      <Back title={'Revue des finalistes'} />
      <div className='alfaNews'>
        <a onClick={() => openDocument(reminder)} className='newsContainer'>
          <ArticleCard
            titre={'N°006/2022'}
            sousTitre={'La famme du souvenir flambe encore ...'}
            image={require('../../../autresImages/Second/finaliste2.jpg')}
            className='newsContainer'
          />{' '}
        </a>
      </div>
    </>
  )
}

export default Reminder
