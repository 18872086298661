import React from 'react'
import ArticleTemplate1 from '../ArticleTemplate1'
import {historique} from '.'

const Historique = () => {
  return (
    <div>
      <ArticleTemplate1
        title={historique.historique.titre}
        content={historique.historique.content}
      />{' '}
    </div>
  )
}

export default Historique
