import React, {useState} from 'react'

import './galerie.css'
import Back from '../common/back/Back'

import GalerieContent from './GalerieContent'
import AWrapper from '../about/AWrapper'
/* import Prayer from './prayer' */

const Galerie = () => {
  return (
    <div>
      <Back title={'Notre Galerie Photo'} />
      <GalerieContent />
      {/* <Prayer /> */}
      <AWrapper />
    </div>
  )
}

export default Galerie
