import React from 'react'
import {Link} from 'react-router-dom'
import {motion, Variants} from 'framer-motion'
import Title from '../common/title/Title'
import './apropos.css'
import AWrapper from './AWrapper'
import eleve3 from './../../autresImages/Second/eleve3.jpg'
import {FaBook, FaWalking, FaWarehouse} from 'react-icons/fa'

const imageAnimate = {
  offScreen: {x: -100},
  onScreen: {
    x: 0,
    rotate: [0, -90, 90, 0],
    transition: {type: 'spring', bounce: 0.4, duration: 3},
  },
}

const textAnimate = {
  offScreen: {y: 100, opacity: 0},
  onScreen: {
    y: 0,
    opacity: 1,
    transition: {type: 'spring', bounce: 0.4, duration: 3},
  },
}

const divAnimate = {
  offScreen: {opacity: 0, scale: '40%'},
  onScreen: {
    opacity: 1,
    scale: '100%',
    transition: {type: 'spring', bounce: 0.4, duration: 3},
  },
}

const animateDiv1 = {
  offScreen: {translateX: 200, opacity: 0},
  onScreen: {translateX: 0, opacity: 1},
}

const animateDiv2 = {
  offScreen: {translateX: -200, opacity: 0},
  onScreen: {translateX: 0, opacity: 1},
}

const animatePhoto = {
  offScreen: {translateX: -200, opacity: 0},
  onScreen: {translateX: 0, opacity: 1},
}

function AproposCard() {
  return (
    <>
      <section className='aboutHome'>
        <div className='container home-container flexSB'>
          <motion.div
            className='left row'
            initial={'offScreen'}
            whileInView={'onScreen'}
            viewport={{once: false, amount: 0}}
            variants={animatePhoto}
          >
            <img src={eleve3} alt='batiment' />
          </motion.div>
          <div className='right row'>
            <Title subtitle='Qui sommes  nous ...' title='A propos' />
            <motion.div
              className='items '
              initial={'offScreen'}
              whileInView={'onScreen'}
              viewport={{once: false, amount: 0}}
              transition={{staggerChildren: 0.5}}
            >
              <Link to='/historique'>
                <motion.div
                  className='item flexSB'
                  initial={'offScreen'}
                  whileInView={'onScreen'}
                  viewport={{once: false, amount: 0}}
                  transition={{staggerChildren: 0.5}}
                  variants={animateDiv2}
                >
                  <div>
                    <FaBook className='iconReact iconAbout' />
                  </div>
                  <div className='text'>
                    <h2>Historique</h2>
                    <p>
                      Anciennement Collège Notre Dame de la Victoire, le Collège
                      Alfajiri est un établissement scolaire situé à Bukavu en
                      ...
                    </p>
                  </div>
                </motion.div>
              </Link>
              <motion.div className='item flexSB' variants={animateDiv1}>
                <div className='img'>
                  <FaWarehouse className='iconReact iconAbout' />
                </div>
                <div className='text'>
                  <h2>Structure</h2>
                  <p>
                    Le Collège Alfajiri est un établissement scolaire composé
                    d'un complexe : école maternelle, primaire, secondaire et
                    Centre de Spécialsation ...
                  </p>
                </div>
              </motion.div>
              <motion.div className='item flexSB' variants={animateDiv2}>
                <div className='img'>
                  <FaWalking className='iconReact iconAbout' />
                </div>
                <div className='text'>
                  <h2>Organigramme</h2>
                  <p>
                    Pour son fonctionnement, le complexe Alfari se refère à une
                    autorité : le père Recteur. C'est lui qui chapote les
                    différentes directions ...
                  </p>
                </div>
              </motion.div>
            </motion.div>
          </div>
        </div>
      </section>
      <div className='marigin2'></div>
      <AWrapper />
    </>
  )
}

export default AproposCard
