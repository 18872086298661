import React from 'react'
import ArticleTemplate1 from '../ArticleTemplate1'
import {ArticleContent} from '../SomeArticles'
const RentreeScolaire2324 = () => {
  return (
    <div>
      <ArticleTemplate1
        title={ArticleContent.rentreeScolaire2324.titre}
        content={ArticleContent.rentreeScolaire2324.content}
      />{' '}
    </div>
  )
}

export default RentreeScolaire2324
