import React from 'react'
import './card.css'

function CardImage({image}) {
  return (
    <>
      <div className='left row'>
        <img src={image} alt='batiment' />
      </div>
    </>
  )
}

export default CardImage
