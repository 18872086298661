import React from 'react'
import RoiComponent from './RoiComponent'
import {roiMaternelle} from './roiEcoleTxt'

function RoiMaternelle() {
  return (
    <div>
      <RoiComponent
        roiTitre={"Reglèment de l'Ecole Maternelle"}
        roiArticles={roiMaternelle}
      />
    </div>
  )
}

export default RoiMaternelle
