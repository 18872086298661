import React from 'react'
import {Link} from 'react-router-dom'
import {motion} from 'framer-motion'
import {useState} from 'react'

const FirstHead = () => {
  const [currentUpdate, setCurrentUpdate] = useState(0)
  const nouvelles = [
    'Rentrée scolaire 2022-2023...',
    'Messe du Saint-Esprit...',
    'Schola Brevis...',
    'Lancement des activités parascolaires...',
  ]
  const catchRecent = () => {
    if (currentUpdate === nouvelles.length - 1) {
      setCurrentUpdate(0)
    } else {
      setCurrentUpdate(currentUpdate + 1)
    }
  }
  setTimeout(catchRecent, 10000)

  return (
    <div>
      <div className='firstContainer'>
        <div className='recentUpdates'>
          <div className='lesNews'>
            <div className='recents'>Recents : </div>
            <div className='nouvelles'>
              <p>{nouvelles[currentUpdate]}</p>
            </div>
          </div>
        </div>
        <div className='rapidLinks'>
          <nav>
            <ul className='flexSB'>
              <Link to={'/comingsoon'}>
                <li>Communiqués</li>
              </Link>
              <Link to={'/evenement'}>
                <li>Evenements</li>
              </Link>
              <Link to={'/comingsoon'}>
                <li>Contacts</li>
              </Link>
              <Link to={'/comingsoon'}>
                <li>Services</li>
              </Link>
              <Link to={'/galerie'}>
                <li>Galerie</li>
              </Link>
              <Link to={'/comingsoon'}>
                <li>Connexion</li>
              </Link>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  )
}

export default FirstHead
