import React from 'react'
import Back from '../common/back/Back'
import AproposCard from './ApropoCard'

function Apropos() {
  return (
    <>
      <div className='apropospage'>
        <Back title={'A propos'} />
        <div className='separator'></div>
        <AproposCard />
      </div>
    </>
  )
}

export default Apropos
