import React from 'react'
import RoiComponent from './RoiComponent'
import {roiPrimaire} from './roiEcoleTxt'

function RoiPrimaire() {
  return (
    <div>
      <RoiComponent
        roiArticles={roiPrimaire}
        roiTitre="Règlement de l'Ecole Primaire"
      />
    </div>
  )
}

export default RoiPrimaire
