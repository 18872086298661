import React from 'react'
import {Link} from 'react-router-dom'
import Back from '../../common/back/Back'

import {withStyles, makeStyles} from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'


const StyleTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell)

const StyleTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow)

function createData(name, calories, flat, carbs, protein) {
  return {name, calories, flat, carbs, protein}
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
]

const useStyles = makeStyles({
  table: {
    marginTop: '5%',
    marginLeft: '10%',
    width: '70%',
  },
})

function CalendrierSolaire() {
  const classes = useStyles()
  return (
    <div className='roiTable'>
      <Back title={'Calendrier des Activités'} />
      <div className='reglement'>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label='customised table'>
            <TableHead>
              <TableRow>
                <StyleTableCell colSpan='4' align='center'>
                  1ère Pédiode (51 jours)
                </StyleTableCell>
                <StyleTableCell colSpan='7' align='center'>
                  2ème Période (73 jours)
                </StyleTableCell>
                <StyleTableCell align='center'>
                  Fin premier Semestre
                </StyleTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  1
                </StyleTableCell>
                <StyleTableCell align='left'>Lundi</StyleTableCell>
                <StyleTableCell align='left'>8/22/2022</StyleTableCell>
                <StyleTableCell align='left'>
                  Rentrée des administratifs
                </StyleTableCell>
                <StyleTableCell align='left'>49</StyleTableCell>
                <StyleTableCell align='left'>Lundi</StyleTableCell>
                <StyleTableCell align='left'>10/17/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>146</StyleTableCell>
                <StyleTableCell align='left'>Lundi</StyleTableCell>
                <StyleTableCell align='left'>12/12/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  2
                </StyleTableCell>
                <StyleTableCell align='left'>Mardi</StyleTableCell>
                <StyleTableCell align='left'>8/23/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>50</StyleTableCell>
                <StyleTableCell align='left'>Mardi</StyleTableCell>
                <StyleTableCell align='left'>10/18/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>147</StyleTableCell>
                <StyleTableCell align='left'>Mardi</StyleTableCell>
                <StyleTableCell align='left'>12/13/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  3
                </StyleTableCell>
                <StyleTableCell align='left'>Mercredi</StyleTableCell>
                <StyleTableCell align='left'>8/24/2022</StyleTableCell>
                <StyleTableCell align='left'>
                  Conseil de direction du Complexe
                </StyleTableCell>
                <StyleTableCell align='left'>51</StyleTableCell>
                <StyleTableCell align='left'>Mercredi</StyleTableCell>
                <StyleTableCell align='left'>10/19/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>148</StyleTableCell>
                <StyleTableCell align='left'>Mercredi</StyleTableCell>
                <StyleTableCell align='left'>12/14/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  4
                </StyleTableCell>
                <StyleTableCell align='left'>Jeudi</StyleTableCell>
                <StyleTableCell align='left'>8/25/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>52</StyleTableCell>
                <StyleTableCell align='left'>Jeudi</StyleTableCell>
                <StyleTableCell align='left'>10/20/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>149</StyleTableCell>
                <StyleTableCell align='left'>Jeudi</StyleTableCell>
                <StyleTableCell align='left'>12/15/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  5
                </StyleTableCell>
                <StyleTableCell align='left'>Vendredi</StyleTableCell>
                <StyleTableCell align='left'>8/26/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>53</StyleTableCell>
                <StyleTableCell align='left'>Vendredi</StyleTableCell>
                <StyleTableCell align='left'>10/21/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>150</StyleTableCell>
                <StyleTableCell align='left'>Vendredi</StyleTableCell>
                <StyleTableCell align='left'>12/16/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  6
                </StyleTableCell>
                <StyleTableCell align='left'>Samedi</StyleTableCell>
                <StyleTableCell align='left'>8/27/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>54</StyleTableCell>
                <StyleTableCell align='left'>Samedi</StyleTableCell>
                <StyleTableCell align='left'>10/22/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>151</StyleTableCell>
                <StyleTableCell align='left'>Samedi</StyleTableCell>
                <StyleTableCell align='left'>12/17/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'></StyleTableCell>
                <StyleTableCell align='left'>Dimanche</StyleTableCell>
                <StyleTableCell align='left'>8/28/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'black'}}
                ></StyleTableCell>
                <StyleTableCell align='left'></StyleTableCell>
                <StyleTableCell align='left'>Dimanche</StyleTableCell>
                <StyleTableCell align='left'>10/23/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'black'}}
                ></StyleTableCell>
                <StyleTableCell align='left'></StyleTableCell>
                <StyleTableCell align='left'>Dimanche</StyleTableCell>
                <StyleTableCell align='left'>12/18/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'black'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  7
                </StyleTableCell>
                <StyleTableCell align='left'>Lundi</StyleTableCell>
                <StyleTableCell align='left'>8/29/2022</StyleTableCell>
                <StyleTableCell align='left'>
                  Rentrée des Enseignants
                </StyleTableCell>
                <StyleTableCell align='left'>55</StyleTableCell>
                <StyleTableCell align='left'>Lundi</StyleTableCell>
                <StyleTableCell align='left'>10/24/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>152</StyleTableCell>
                <StyleTableCell align='left'>Lundi</StyleTableCell>
                <StyleTableCell align='left'>12/19/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  8
                </StyleTableCell>
                <StyleTableCell align='left'>Mardi</StyleTableCell>
                <StyleTableCell align='left'>8/30/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>56</StyleTableCell>
                <StyleTableCell align='left'>Mardi</StyleTableCell>
                <StyleTableCell align='left'>10/25/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>153</StyleTableCell>
                <StyleTableCell align='left'>Mardi</StyleTableCell>
                <StyleTableCell align='left'>12/20/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  9
                </StyleTableCell>
                <StyleTableCell align='left'>Mercredi</StyleTableCell>
                <StyleTableCell align='left'>8/31/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>57</StyleTableCell>
                <StyleTableCell align='left'>Mercredi</StyleTableCell>
                <StyleTableCell align='left'>10/26/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>154</StyleTableCell>
                <StyleTableCell align='left'>Mercredi</StyleTableCell>
                <StyleTableCell align='left'>12/21/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  10
                </StyleTableCell>
                <StyleTableCell align='left'>Jeudi</StyleTableCell>
                <StyleTableCell align='left'>9/1/2022</StyleTableCell>
                <StyleTableCell align='left'>
                  Journée de réflexion
                </StyleTableCell>
                <StyleTableCell align='left'>58</StyleTableCell>
                <StyleTableCell align='left'>Jeudi</StyleTableCell>
                <StyleTableCell align='left'>10/27/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>155</StyleTableCell>
                <StyleTableCell align='left'>Jeudi</StyleTableCell>
                <StyleTableCell align='left'>12/22/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  11
                </StyleTableCell>
                <StyleTableCell align='left'>Vendredi</StyleTableCell>
                <StyleTableCell align='left'>9/2/2022</StyleTableCell>
                <StyleTableCell align='left'>
                  Réunions pédagogiques + UP
                </StyleTableCell>
                <StyleTableCell align='left'>59</StyleTableCell>
                <StyleTableCell align='left'>Vendredi</StyleTableCell>
                <StyleTableCell align='left'>10/28/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>156</StyleTableCell>
                <StyleTableCell align='left'>Vendredi</StyleTableCell>
                <StyleTableCell align='left'>12/23/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  12
                </StyleTableCell>
                <StyleTableCell align='left'>Samedi</StyleTableCell>
                <StyleTableCell align='left'>9/3/2022</StyleTableCell>
                <StyleTableCell align='left'>
                  Retrait des documents pédagogiques
                </StyleTableCell>
                <StyleTableCell align='left'>60</StyleTableCell>
                <StyleTableCell align='left'>Samedi</StyleTableCell>
                <StyleTableCell align='left'>10/29/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>157</StyleTableCell>
                <StyleTableCell align='left'>Samedi</StyleTableCell>
                <StyleTableCell align='left'>12/24/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'lightsalmon'}}
                >
                  Vacances de Noël
                </StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'></StyleTableCell>
                <StyleTableCell align='left'>Dimanche</StyleTableCell>
                <StyleTableCell align='left'>9/4/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'black'}}
                ></StyleTableCell>
                <StyleTableCell align='left'></StyleTableCell>
                <StyleTableCell align='left'>Dimanche</StyleTableCell>
                <StyleTableCell align='left'>10/30/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'black'}}
                ></StyleTableCell>
                <StyleTableCell align='left'></StyleTableCell>
                <StyleTableCell align='left'>Dimanche</StyleTableCell>
                <StyleTableCell align='left'>12/25/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'lightsalmon'}}
                >
                  NATIVITE DU SEIGNEUR
                </StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  13
                </StyleTableCell>
                <StyleTableCell align='left'>Lundi</StyleTableCell>
                <StyleTableCell align='left'>9/5/2022</StyleTableCell>
                <StyleTableCell align='left'>Rentrée Scolaire</StyleTableCell>
                <StyleTableCell align='left'>61</StyleTableCell>
                <StyleTableCell align='left'>Lundi</StyleTableCell>
                <StyleTableCell align='left'>10/31/2022</StyleTableCell>
                <StyleTableCell align='left' rowSpan={2}>
                  Journées des Statistiques Scolaires
                </StyleTableCell>
                <StyleTableCell align='left'>158</StyleTableCell>
                <StyleTableCell align='left'>Lundi</StyleTableCell>
                <StyleTableCell align='left'>12/26/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'lightsalmon'}}
                >
                  Vacances de Noël
                </StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  14
                </StyleTableCell>
                <StyleTableCell align='left'>Mardi</StyleTableCell>
                <StyleTableCell align='left'>9/6/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>62</StyleTableCell>
                <StyleTableCell align='left'>Mardi</StyleTableCell>
                <StyleTableCell align='left'>11/1/2022</StyleTableCell>

                <StyleTableCell align='left'>159</StyleTableCell>
                <StyleTableCell align='left'>Mardi</StyleTableCell>
                <StyleTableCell align='left'>12/27/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'lightsalmon'}}
                >
                  Vacances de Noël
                </StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  15
                </StyleTableCell>
                <StyleTableCell align='left'>Mercredi</StyleTableCell>
                <StyleTableCell align='left'>9/7/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>63</StyleTableCell>
                <StyleTableCell align='left'>Mercredi</StyleTableCell>
                <StyleTableCell align='left'>11/2/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>160</StyleTableCell>
                <StyleTableCell align='left'>Mercredi</StyleTableCell>
                <StyleTableCell align='left'>12/28/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'lightsalmon'}}
                >
                  Vacances de Noël
                </StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  16
                </StyleTableCell>
                <StyleTableCell align='left'>Jeudi</StyleTableCell>
                <StyleTableCell align='left'>9/8/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>64</StyleTableCell>
                <StyleTableCell align='left'>Jeudi</StyleTableCell>
                <StyleTableCell align='left'>11/3/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'lightblue'}}
                >
                  Detente (Retraite 4H)
                </StyleTableCell>
                <StyleTableCell align='left'>161</StyleTableCell>
                <StyleTableCell align='left'>Jeudi</StyleTableCell>
                <StyleTableCell align='left'>12/29/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'lightsalmon'}}
                >
                  Vacances de Noël
                </StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  17
                </StyleTableCell>
                <StyleTableCell align='left'>Vendredi</StyleTableCell>
                <StyleTableCell align='left'>9/9/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>65</StyleTableCell>
                <StyleTableCell align='left'>Vendredi</StyleTableCell>
                <StyleTableCell align='left'>11/4/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'lightblue'}}
                >
                  Detente (Retraite 4H)
                </StyleTableCell>
                <StyleTableCell align='left'>162</StyleTableCell>
                <StyleTableCell align='left'>Vendredi</StyleTableCell>
                <StyleTableCell align='left'>12/30/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'lightsalmon'}}
                >
                  Vacances de Noël
                </StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  18
                </StyleTableCell>
                <StyleTableCell align='left'>Samedi</StyleTableCell>
                <StyleTableCell align='left'>9/10/2022</StyleTableCell>
                <StyleTableCell align='left'>Scola Brevis I</StyleTableCell>
                <StyleTableCell align='left'>66</StyleTableCell>
                <StyleTableCell align='left'>Samedi</StyleTableCell>
                <StyleTableCell align='left'>11/5/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'lightblue'}}
                >
                  Detente (Retraite 4H)
                </StyleTableCell>
                <StyleTableCell align='left'>163</StyleTableCell>
                <StyleTableCell align='left'>Samedi</StyleTableCell>
                <StyleTableCell align='left'>12/31/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'lightsalmon'}}
                >
                  Vacances de Noël
                </StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'></StyleTableCell>
                <StyleTableCell align='left'>Dimanche</StyleTableCell>
                <StyleTableCell align='left'>9/11/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'black'}}
                ></StyleTableCell>
                <StyleTableCell align='left'></StyleTableCell>
                <StyleTableCell align='left'>Dimanche</StyleTableCell>
                <StyleTableCell align='left'>11/6/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'black'}}
                ></StyleTableCell>
                <StyleTableCell align='left'></StyleTableCell>
                <StyleTableCell align='left'>Dimanche</StyleTableCell>
                <StyleTableCell align='left'>1/1/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'lightsalmon'}}
                >
                  Vacances de Noël
                </StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  19
                </StyleTableCell>
                <StyleTableCell align='left'>Lundi</StyleTableCell>
                <StyleTableCell align='left'>9/12/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>67</StyleTableCell>
                <StyleTableCell align='left'>Lundi</StyleTableCell>
                <StyleTableCell align='left'>11/7/2022</StyleTableCell>
                <StyleTableCell align='left'>
                  Reprise des cours (début de la deuxième période)
                </StyleTableCell>
                <StyleTableCell align='left'>164</StyleTableCell>
                <StyleTableCell align='left'>Lundi</StyleTableCell>
                <StyleTableCell align='left'>1/2/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'lightsalmon'}}
                >
                  Vacances de Noël
                </StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  20
                </StyleTableCell>
                <StyleTableCell align='left'>Mardi</StyleTableCell>
                <StyleTableCell align='left'>9/13/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>68</StyleTableCell>
                <StyleTableCell align='left'>Mardi</StyleTableCell>
                <StyleTableCell align='left'>11/8/2022</StyleTableCell>
                <StyleTableCell align='left'>
                  Délibération (retraite 4e H)
                </StyleTableCell>
                <StyleTableCell align='left'>165</StyleTableCell>
                <StyleTableCell align='left'>Mardi</StyleTableCell>
                <StyleTableCell align='left'>1/3/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'lightsalmon'}}
                >
                  Vacances de Noël
                </StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  21
                </StyleTableCell>
                <StyleTableCell align='left'>Mercredi</StyleTableCell>
                <StyleTableCell align='left'>9/14/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>69</StyleTableCell>
                <StyleTableCell align='left'>Mercredi</StyleTableCell>
                <StyleTableCell align='left'>11/9/2022</StyleTableCell>
                <StyleTableCell align='left'>
                  Délibération (retraite 4e H)
                </StyleTableCell>
                <StyleTableCell align='left'>166</StyleTableCell>
                <StyleTableCell align='left'>Mercredi</StyleTableCell>
                <StyleTableCell align='left'>1/4/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'lightsalmon'}}
                >
                  Vacances de Noël/Martyrs de l'indépendance
                </StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  22
                </StyleTableCell>
                <StyleTableCell align='left'>Jeudi</StyleTableCell>
                <StyleTableCell align='left'>9/15/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>70</StyleTableCell>
                <StyleTableCell align='left'>Jeudi</StyleTableCell>
                <StyleTableCell align='left'>11/10/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>167</StyleTableCell>
                <StyleTableCell align='left'>Jeudi</StyleTableCell>
                <StyleTableCell align='left'>1/5/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'lightsalmon'}}
                >
                  Vacances de Noël
                </StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  23
                </StyleTableCell>
                <StyleTableCell align='left'>Vendredi</StyleTableCell>
                <StyleTableCell align='left'>9/16/2022</StyleTableCell>
                <StyleTableCell align='left'>
                  Rappel remise des prévisions des matières
                </StyleTableCell>
                <StyleTableCell align='left'>71</StyleTableCell>
                <StyleTableCell align='left'>Vendredi</StyleTableCell>
                <StyleTableCell align='left'>11/11/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>168</StyleTableCell>
                <StyleTableCell align='left'>Vendredi</StyleTableCell>
                <StyleTableCell align='left'>1/6/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'lightsalmon'}}
                >
                  Vacances de Noël
                </StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  24
                </StyleTableCell>
                <StyleTableCell align='left'>Samedi</StyleTableCell>
                <StyleTableCell align='left'>9/17/2022</StyleTableCell>
                <StyleTableCell align='left'>Scola brevis II</StyleTableCell>
                <StyleTableCell align='left'>72</StyleTableCell>
                <StyleTableCell align='left'>Samedi</StyleTableCell>
                <StyleTableCell align='left'>11/12/2022</StyleTableCell>
                <StyleTableCell align='left'>
                  Communication des points
                </StyleTableCell>
                <StyleTableCell align='left'>169</StyleTableCell>
                <StyleTableCell align='left'>Samedi</StyleTableCell>
                <StyleTableCell align='left'>1/7/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'lightsalmon'}}
                >
                  Vacances de Noël
                </StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'></StyleTableCell>
                <StyleTableCell align='left'>Dimanche</StyleTableCell>
                <StyleTableCell align='left'>9/18/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'black'}}
                ></StyleTableCell>
                <StyleTableCell align='left'></StyleTableCell>
                <StyleTableCell align='left'>Dimanche</StyleTableCell>
                <StyleTableCell align='left'>11/13/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'black'}}
                ></StyleTableCell>
                <StyleTableCell align='left'></StyleTableCell>
                <StyleTableCell align='left'>Dimanche</StyleTableCell>
                <StyleTableCell align='left'>1/8/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'lightsalmon'}}
                >
                  Vacances de Noël
                </StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  25
                </StyleTableCell>
                <StyleTableCell align='left'>Lundi</StyleTableCell>
                <StyleTableCell align='left'>9/19/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>73</StyleTableCell>
                <StyleTableCell align='left'>Lundi</StyleTableCell>
                <StyleTableCell align='left'>11/14/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>170</StyleTableCell>
                <StyleTableCell align='left'>Lundi</StyleTableCell>
                <StyleTableCell align='left'>1/9/2023</StyleTableCell>
                <StyleTableCell align='left'>Reprise des cours</StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  26
                </StyleTableCell>
                <StyleTableCell align='left'>Mardi</StyleTableCell>
                <StyleTableCell align='left'>9/20/2022</StyleTableCell>
                <StyleTableCell align='left'>
                  Remise des prévisions des matières
                </StyleTableCell>
                <StyleTableCell align='left'>74</StyleTableCell>
                <StyleTableCell align='left'>Mardi</StyleTableCell>
                <StyleTableCell align='left'>11/15/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>171</StyleTableCell>
                <StyleTableCell align='left'>Mardi</StyleTableCell>
                <StyleTableCell align='left'>1/10/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  27
                </StyleTableCell>
                <StyleTableCell align='left'>Mercredi</StyleTableCell>
                <StyleTableCell align='left'>9/21/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>75</StyleTableCell>
                <StyleTableCell align='left'>Mercredi</StyleTableCell>
                <StyleTableCell align='left'>11/16/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>172</StyleTableCell>
                <StyleTableCell align='left'>Mercredi</StyleTableCell>
                <StyleTableCell align='left'>1/11/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  28
                </StyleTableCell>
                <StyleTableCell align='left'>Jeudi</StyleTableCell>
                <StyleTableCell align='left'>9/22/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>76</StyleTableCell>
                <StyleTableCell align='left'>Jeudi</StyleTableCell>
                <StyleTableCell align='left'>11/17/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>173</StyleTableCell>
                <StyleTableCell align='left'>Jeudi</StyleTableCell>
                <StyleTableCell align='left'>1/12/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  29
                </StyleTableCell>
                <StyleTableCell align='left'>Vendredi</StyleTableCell>
                <StyleTableCell align='left'>9/23/2022</StyleTableCell>
                <StyleTableCell align='left'>
                  Messe du Saint-Esprit
                </StyleTableCell>
                <StyleTableCell align='left'>77</StyleTableCell>
                <StyleTableCell align='left'>Vendredi</StyleTableCell>
                <StyleTableCell align='left'>11/18/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>174</StyleTableCell>
                <StyleTableCell align='left'>Vendredi</StyleTableCell>
                <StyleTableCell align='left'>1/13/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  30
                </StyleTableCell>
                <StyleTableCell align='left'>Samedi</StyleTableCell>
                <StyleTableCell align='left'>9/24/2022</StyleTableCell>
                <StyleTableCell align='left'>
                  Messe du Saint-Esprit
                </StyleTableCell>
                <StyleTableCell align='left'>78</StyleTableCell>
                <StyleTableCell align='left'>Samedi</StyleTableCell>
                <StyleTableCell align='left'>11/19/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>175</StyleTableCell>
                <StyleTableCell align='left'>Samedi</StyleTableCell>
                <StyleTableCell align='left'>1/14/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'></StyleTableCell>
                <StyleTableCell align='left'>Dimanche</StyleTableCell>
                <StyleTableCell align='left'>9/25/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'black'}}
                ></StyleTableCell>
                <StyleTableCell align='left'></StyleTableCell>
                <StyleTableCell align='left'>Dimanche</StyleTableCell>
                <StyleTableCell align='left'>11/20/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'black'}}
                ></StyleTableCell>
                <StyleTableCell align='left'></StyleTableCell>
                <StyleTableCell align='left'>Dimanche</StyleTableCell>
                <StyleTableCell align='left'>1/15/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'black'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  31
                </StyleTableCell>
                <StyleTableCell align='left'>Lundi</StyleTableCell>
                <StyleTableCell align='left'>9/26/2022</StyleTableCell>
                <StyleTableCell align='left'>
                  Processus gouvernement des élèves
                </StyleTableCell>
                <StyleTableCell align='left'>79</StyleTableCell>
                <StyleTableCell align='left'>Lundi</StyleTableCell>
                <StyleTableCell align='left'>11/21/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>176</StyleTableCell>
                <StyleTableCell align='left'>Lundi</StyleTableCell>
                <StyleTableCell align='left'>1/16/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'lightsalmon'}}
                >
                  LD Kabila
                </StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  32
                </StyleTableCell>
                <StyleTableCell align='left'>Mardi</StyleTableCell>
                <StyleTableCell align='left'>9/27/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>80</StyleTableCell>
                <StyleTableCell align='left'>Mardi</StyleTableCell>
                <StyleTableCell align='left'>11/22/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>177</StyleTableCell>
                <StyleTableCell align='left'>Mardi</StyleTableCell>
                <StyleTableCell align='left'>1/17/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'lightsalmon'}}
                >
                  PE Lumumba
                </StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  33
                </StyleTableCell>
                <StyleTableCell align='left'>Mercredi</StyleTableCell>
                <StyleTableCell align='left'>9/28/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>81</StyleTableCell>
                <StyleTableCell align='left'>Mercredi</StyleTableCell>
                <StyleTableCell align='left'>11/23/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>178</StyleTableCell>
                <StyleTableCell align='left'>Mercredi</StyleTableCell>
                <StyleTableCell align='left'>1/18/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  34
                </StyleTableCell>
                <StyleTableCell align='left'>Jeudi</StyleTableCell>
                <StyleTableCell align='left'>9/29/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>82</StyleTableCell>
                <StyleTableCell align='left'>Jeudi</StyleTableCell>
                <StyleTableCell align='left'>11/24/2022</StyleTableCell>
                <StyleTableCell align='left'>Journée de l'arbre</StyleTableCell>
                <StyleTableCell align='left'>179</StyleTableCell>
                <StyleTableCell align='left'>Jeudi</StyleTableCell>
                <StyleTableCell align='left'>1/19/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  35
                </StyleTableCell>
                <StyleTableCell align='left'>Vendredi</StyleTableCell>
                <StyleTableCell align='left'>9/30/2022</StyleTableCell>
                <StyleTableCell align='left'>
                  Contrôle des documents pédagogiques
                </StyleTableCell>
                <StyleTableCell align='left'>83</StyleTableCell>
                <StyleTableCell align='left'>Vendredi</StyleTableCell>
                <StyleTableCell align='left'>11/25/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>180</StyleTableCell>
                <StyleTableCell align='left'>Vendredi</StyleTableCell>
                <StyleTableCell align='left'>1/20/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  36
                </StyleTableCell>
                <StyleTableCell align='left'>Samedi</StyleTableCell>
                <StyleTableCell align='left'>10/1/2022</StyleTableCell>
                <StyleTableCell align='left'>
                  Critères Diplômes SJ
                </StyleTableCell>
                <StyleTableCell align='left'>84</StyleTableCell>
                <StyleTableCell align='left'>Samedi</StyleTableCell>
                <StyleTableCell align='left'>11/26/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>181</StyleTableCell>
                <StyleTableCell align='left'>Samedi</StyleTableCell>
                <StyleTableCell align='left'>1/21/2023</StyleTableCell>
                <StyleTableCell align='left'>QE 1 à la DE</StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'></StyleTableCell>
                <StyleTableCell align='left'>Dimanche</StyleTableCell>
                <StyleTableCell align='left'>10/2/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'black'}}
                ></StyleTableCell>
                <StyleTableCell align='left'></StyleTableCell>
                <StyleTableCell align='left'>Dimanche</StyleTableCell>
                <StyleTableCell align='left'>11/27/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'black'}}
                ></StyleTableCell>
                <StyleTableCell align='left'></StyleTableCell>
                <StyleTableCell align='left'>Dimanche</StyleTableCell>
                <StyleTableCell align='left'>1/22/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'black'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  37
                </StyleTableCell>
                <StyleTableCell align='left'>Lundi</StyleTableCell>
                <StyleTableCell align='left'>10/3/2022</StyleTableCell>
                <StyleTableCell align='left'>
                  Elect° gouv. des élèves, visites des classes
                </StyleTableCell>
                <StyleTableCell align='left'>85</StyleTableCell>
                <StyleTableCell align='left'>Lundi</StyleTableCell>
                <StyleTableCell align='left'>11/28/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>183</StyleTableCell>
                <StyleTableCell align='left'>Lundi</StyleTableCell>
                <StyleTableCell align='left'>1/23/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  38
                </StyleTableCell>
                <StyleTableCell align='left'>Mardi</StyleTableCell>
                <StyleTableCell align='left'>10/4/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>86</StyleTableCell>
                <StyleTableCell align='left'>Mardi</StyleTableCell>
                <StyleTableCell align='left'>11/29/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>184</StyleTableCell>
                <StyleTableCell align='left'>Mardi</StyleTableCell>
                <StyleTableCell align='left'>1/24/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  39
                </StyleTableCell>
                <StyleTableCell align='left'>Mercredi</StyleTableCell>
                <StyleTableCell align='left'>10/5/2022</StyleTableCell>
                <StyleTableCell align='left'>JME</StyleTableCell>
                <StyleTableCell align='left'>87</StyleTableCell>
                <StyleTableCell align='left'>Mercredi</StyleTableCell>
                <StyleTableCell align='left'>11/30/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>185</StyleTableCell>
                <StyleTableCell align='left'>Mercredi</StyleTableCell>
                <StyleTableCell align='left'>1/25/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  40
                </StyleTableCell>
                <StyleTableCell align='left'>Jeudi</StyleTableCell>
                <StyleTableCell align='left'>10/6/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>88</StyleTableCell>
                <StyleTableCell align='left'>Jeudi</StyleTableCell>
                <StyleTableCell align='left'>12/1/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>186</StyleTableCell>
                <StyleTableCell align='left'>Jeudi</StyleTableCell>
                <StyleTableCell align='left'>1/26/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  41
                </StyleTableCell>
                <StyleTableCell align='left'>Vendredi</StyleTableCell>
                <StyleTableCell align='left'>10/7/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>89</StyleTableCell>
                <StyleTableCell align='left'>Vendredi</StyleTableCell>
                <StyleTableCell align='left'>12/2/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>187</StyleTableCell>
                <StyleTableCell align='left'>Vendredi</StyleTableCell>
                <StyleTableCell align='left'>1/27/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  42
                </StyleTableCell>
                <StyleTableCell align='left'>Samedi</StyleTableCell>
                <StyleTableCell align='left'>10/8/2022</StyleTableCell>
                <StyleTableCell align='left'>
                  Lancement des activités culturelles et sportives
                </StyleTableCell>
                <StyleTableCell align='left'>90</StyleTableCell>
                <StyleTableCell align='left'>Samedi</StyleTableCell>
                <StyleTableCell align='left'>12/3/2022</StyleTableCell>
                <StyleTableCell align='left'>
                  JI des personnes handicapées
                </StyleTableCell>
                <StyleTableCell align='left'>188</StyleTableCell>
                <StyleTableCell align='left'>Samedi</StyleTableCell>
                <StyleTableCell align='left'>1/28/2023</StyleTableCell>
                <StyleTableCell align='left'>CP2 à la DE</StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'></StyleTableCell>
                <StyleTableCell align='left'>Dimanche</StyleTableCell>
                <StyleTableCell align='left'>10/9/2022</StyleTableCell>
                <StyleTableCell align='left' style={{backgroundColor: 'black'}}>
                  AGOP
                </StyleTableCell>
                <StyleTableCell align='left'></StyleTableCell>
                <StyleTableCell align='left'>Dimanche</StyleTableCell>
                <StyleTableCell align='left'>12/4/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'black'}}
                ></StyleTableCell>
                <StyleTableCell align='left'></StyleTableCell>
                <StyleTableCell align='left'>Dimanche</StyleTableCell>
                <StyleTableCell align='left'>1/29/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'black'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  43
                </StyleTableCell>
                <StyleTableCell align='left'>Lundi</StyleTableCell>
                <StyleTableCell align='left'>10/11/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>91</StyleTableCell>
                <StyleTableCell align='left'>Lundi</StyleTableCell>
                <StyleTableCell align='left'>12/5/2022</StyleTableCell>
                <StyleTableCell align='left'>
                  Journée Internationnale de l'arbre
                </StyleTableCell>
                <StyleTableCell align='left'>189</StyleTableCell>
                <StyleTableCell align='left'>Lundi</StyleTableCell>
                <StyleTableCell align='left'>1/30/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'darkred'}}
                >
                  Révisions
                </StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  44
                </StyleTableCell>
                <StyleTableCell align='left'>Mardi</StyleTableCell>
                <StyleTableCell align='left'>10/11/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>92</StyleTableCell>
                <StyleTableCell align='left'>Mardi</StyleTableCell>
                <StyleTableCell align='left'>12/6/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>189</StyleTableCell>
                <StyleTableCell align='left'>Mardi</StyleTableCell>
                <StyleTableCell align='left'>1/31/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'darkred'}}
                >
                  Révisions
                </StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  45
                </StyleTableCell>
                <StyleTableCell align='left'>Mercredi</StyleTableCell>
                <StyleTableCell align='left'>10/12/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>93</StyleTableCell>
                <StyleTableCell align='left'>Mercredi</StyleTableCell>
                <StyleTableCell align='left'>12/7/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>190</StyleTableCell>
                <StyleTableCell align='left'>Mercredi</StyleTableCell>
                <StyleTableCell align='left'>2/1/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'darkred'}}
                >
                  Révisions
                </StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  46
                </StyleTableCell>
                <StyleTableCell align='left'>Jeudi</StyleTableCell>
                <StyleTableCell align='left'>10/13/2022</StyleTableCell>
                <StyleTableCell align='left'>
                  Journée de l'éducation civique et patriotique
                </StyleTableCell>
                <StyleTableCell align='left'>94</StyleTableCell>
                <StyleTableCell align='left'>Jeudi</StyleTableCell>
                <StyleTableCell align='left'>12/8/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>191</StyleTableCell>
                <StyleTableCell align='left'>Jeudi</StyleTableCell>
                <StyleTableCell align='left'>2/2/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'darkred'}}
                >
                  Révisions
                </StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  47
                </StyleTableCell>
                <StyleTableCell align='left'>Vendredi</StyleTableCell>
                <StyleTableCell align='left'>10/14/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>95</StyleTableCell>
                <StyleTableCell align='left'>Vendredi</StyleTableCell>
                <StyleTableCell align='left'>12/9/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>192</StyleTableCell>
                <StyleTableCell align='left'>Vendredi</StyleTableCell>
                <StyleTableCell align='left'>2/3/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'darkred'}}
                >
                  Révisions
                </StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  48
                </StyleTableCell>
                <StyleTableCell align='left'>Samedi</StyleTableCell>
                <StyleTableCell align='left'>10/15/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>96</StyleTableCell>
                <StyleTableCell align='left'>Samedi</StyleTableCell>
                <StyleTableCell align='left'>12/10/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>193</StyleTableCell>
                <StyleTableCell align='left'>Samedi</StyleTableCell>
                <StyleTableCell align='left'>2/4/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'darkred'}}
                >
                  Révisions
                </StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'></StyleTableCell>
                <StyleTableCell align='left'>Dimanche</StyleTableCell>
                <StyleTableCell align='left'>10/16/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'black'}}
                ></StyleTableCell>
                <StyleTableCell align='left'></StyleTableCell>
                <StyleTableCell align='left'>Dimanche</StyleTableCell>
                <StyleTableCell align='left'>12/11/2022</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'black'}}
                ></StyleTableCell>
                <StyleTableCell align='left'></StyleTableCell>
                <StyleTableCell align='left'>Dimanche</StyleTableCell>
                <StyleTableCell align='left'>2/5/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'black'}}
                ></StyleTableCell>
              </StyleTableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Link to='/pedagogie/calendrierscolairesecondsemestre'>
          <button className='btn-suite'>
            Lire la suite ... <i className='fa fa-long-arrow-alt-right'></i>
          </button>
        </Link>
      </div>
    </div>
  )
}

export default CalendrierSolaire
