import React from 'react'
import AWrapper from '../about/AWrapper'
import Back from '../common/back/Back'
import ParascolaireCard from './ParascolaireCard'

function Parascolaire() {
  return (
    <>
      <Back title={'Organisation des activités parascolaires'} />
      <div className='separator'></div>
      <ParascolaireCard />
      <div className='separator'></div>
    </>
  )
}

export default Parascolaire
