import React from 'react'
import Back from '../../common/back/Back'
import './roiComponent.css'

import {withStyles, makeStyles} from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

const StyleTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell)

const StyleTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow)

function createData(name, calories, flat, carbs, protein) {
  return {name, calories, flat, carbs, protein}
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
]

const useStyles = makeStyles({
  table: {
    marginTop: '5%',
    marginLeft: '10%',
    width: '70%',
  },
})

function RoiComponent({roiArticles, roiTitre}) {
  const classes = useStyles()
  return (
    <div className='roiTable'>
      <Back title={roiTitre} />
      <div className='reglement'>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label='customised table'>
            <TableHead>
              <TableRow>
                <StyleTableCell style={{width: '100px'}}>
                  Article
                </StyleTableCell>
                <StyleTableCell align='center'>Intitulé</StyleTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {roiArticles.map((roi) => (
                <StyleTableRow key={roi.article}>
                  <StyleTableCell component='th' scope='row'>
                    {roi.article}
                  </StyleTableCell>
                  <StyleTableCell align='left'>{roi.intitulE}</StyleTableCell>
                </StyleTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  )
}

export default RoiComponent
