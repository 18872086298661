import React from 'react'
import Back from '../common/back/Back'
import InscriptionsCard from './InscriptionsCard'
import './inscriptions.css'
import {motion} from 'framer-motion'
import AWrapper from '../about/AWrapper'

function Inscriptions() {
  return (
    <>
      <div className='inscription'>
        <Back title={'Devenez élève au Collège Alfajiri'} />
        <InscriptionsCard />
        <AWrapper />
      </div>
    </>
  )
}

export default Inscriptions
