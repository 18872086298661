import './App.css'
import Header from './components/common/heading/Header'
import {HashRouter as Router} from 'react-router-dom'

import AnimatedRoot from './components/common/AnimatedRoot'
import Footer from './components/common/footer/footer'


/*FontAwesome*/

/*FontAwesome*/

function App() {
  /* const location = useLocation() */
  return (
    <div className='App'>
      <Router>
        <Header />
        <AnimatedRoot /> {<Footer />}{' '}
      </Router>{' '}
    </div>
  )
}

export default App
