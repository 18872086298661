import React from 'react'
import Back from '../common/back/Back'
import RoiCard from './roiCard'

function RoiHome() {
  return (
    <>
      <div className='roiContainer'>
        <Back title={"Lire les Reglèments d'Ordre Intérieur"} />
        <RoiCard />
      </div>
    </>
  )
}

export default RoiHome
