export const coursProgrammes = [{
        id: 1,
        promotion: '1ère - 6ème Primaire',
        totalPeriodique: '',
        branches: [{
                intitulé: 'Religion',
                totalPeriodique: '',
            },
            {
                intitulé: 'Education Civique et Morale',
                totalPeriodique: '',
            },
            {
                intitulé: 'Education à la vie',
                totalPeriodique: '',
            },
            {
                intitulé: 'Lecture Vocabulaire',
                totalPeriodique: '',
            },
            {
                intitulé: 'Grammaire',
                totalPeriodique: '',
            },
            {
                intitulé: 'Elocution-Récitation',
                totalPeriodique: '',
            },
            {
                intitulé: 'Eudcation à la vie',
                totalPeriodique: '',
            },
            {
                intitulé: 'Grammaire Conjugaison',
                totalPeriodique: '',
            },
            {
                intitulé: 'Vocabulaire',
                totalPeriodique: '',
            },
            {
                intitulé: 'Orthographe-Dictée',
                totalPeriodique: '',
            },
            {
                intitulé: 'Rédaction',
                totalPeriodique: '',
            },
            {
                intitulé: 'Numération-Opération',
                totalPeriodique: '',
            },
            {
                intitulé: 'Grandeurs',
                totalPeriodique: '',
            },
            {
                intitulé: 'Formes Géométriques',
                totalPeriodique: '',
            },
            {
                intitulé: 'Problèmes',
                totalPeriodique: '',
            },
            {
                intitulé: 'Educ. Santé et Environnement',
                totalPeriodique: '',
            },
            {
                intitulé: 'Histoire',
                totalPeriodique: '',
            },
            {
                intitulé: 'Géographie',
                totalPeriodique: '',
            },
            {
                intitulé: 'Sciences Naturelles',
                totalPeriodique: '',
            },
            {
                intitulé: 'Dessin',
                totalPeriodique: '',
            },
            {
                intitulé: 'Calligraphie',
                totalPeriodique: '',
            },
            {
                intitulé: 'Chant/Musique',
                totalPeriodique: '',
            },
            {
                intitulé: 'Education Manuelle',
                totalPeriodique: '',
            },
        ],
        show: false,
    },
    {
        id: 2,
        promotion: '7ème CTEB',
        totalPeriodique: 400,
        branches: [{
                intitulé: 'Algèbre',
                totalPeriodique: 40,
            },
            {
                intitulé: 'Arithmétique',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Géométrie',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Statistique',
                totalPeriodique: 10,
            },
            {
                intitulé: 'Anatomie',
                totalPeriodique: 10,
            },
            {
                intitulé: 'Botanique',
                totalPeriodique: 10,
            },
            {
                intitulé: 'Zoologie',
                totalPeriodique: 10,
            },
            {
                intitulé: 'Sciences Physiques',
                totalPeriodique: 10,
            },
            {
                intitulé: 'Technologie',
                totalPeriodique: 10,
            },
            {
                intitulé: "Technologie d'information et de communication (TIC)",
                totalPeriodique: 10,
            },
            {
                intitulé: 'Anglais',
                totalPeriodique: 30,
            },
            {
                intitulé: 'Français',
                totalPeriodique: 70,
            },
            {
                intitulé: 'Religion',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Education à la vie',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Education Civique et Morale',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Géographie',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Histoire',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Dessin',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Musique',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Gymnastique',
                totalPeriodique: 20,
            },
        ],
        show: false,
    },
    {
        id: 3,
        promotion: '8ème CTEB',
        totalPeriodique: 400,
        branches: [{
                intitulé: 'Algèbre',
                totalPeriodique: 40,
            },
            {
                intitulé: 'Arithmétique',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Géométrie',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Statistique',
                totalPeriodique: 10,
            },
            {
                intitulé: 'Anatomie',
                totalPeriodique: 10,
            },
            {
                intitulé: 'Botanique',
                totalPeriodique: 10,
            },
            {
                intitulé: 'Zoologie',
                totalPeriodique: 10,
            },
            {
                intitulé: 'Sciences Physiques',
                totalPeriodique: 10,
            },
            {
                intitulé: 'Technologie',
                totalPeriodique: 10,
            },
            {
                intitulé: "Technologie d'information et de communication (TIC)",
                totalPeriodique: 10,
            },
            {
                intitulé: 'Anglais',
                totalPeriodique: 30,
            },
            {
                intitulé: 'Français',
                totalPeriodique: 70,
            },
            {
                intitulé: 'Religion',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Education à la vie',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Education Civique et Morale',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Géographie',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Histoire',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Dessin',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Musique',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Gymnastique',
                totalPeriodique: 20,
            },
        ],
        show: false,
    },
    {
        id: 4,
        promotion: '1ère Année des Humanités Scientifiques',
        totalPeriodique: 350,
        branches: [{
                intitulé: 'Religion',
                totalPeriodique: 10,
            },
            {
                intitulé: 'Education Civique et Morale',
                totalPeriodique: 10,
            },
            {
                intitulé: 'Education à la Vie',
                totalPeriodique: 10,
            },
            {
                intitulé: 'Algèbre, Statistique & Analyse',
                totalPeriodique: 40,
            },
            {
                intitulé: 'Géométrie et Trigonométrie',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Dessin Scientifique',
                totalPeriodique: 10,
            },
            {
                intitulé: 'Biologie',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Microbiologie',
                totalPeriodique: 10,
            },
            {
                intitulé: 'Géologie',
                totalPeriodique: 10,
            },
            {
                intitulé: 'Chimie',
                totalPeriodique: 30,
            },
            {
                intitulé: 'Physique',
                totalPeriodique: 30,
            },
            {
                intitulé: "Technique d'Information et de Communication",
                totalPeriodique: 10,
            },
            {
                intitulé: 'Français',
                totalPeriodique: 50,
            },
            {
                intitulé: 'Anglais',
                totalPeriodique: 30,
            },
            {
                intitulé: 'Géographie',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Histoire',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Sociologie Africaine',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Education Physique',
                totalPeriodique: 10,
            },
        ],
        show: false,
    },
    {
        id: 5,
        promotion: '2ème Année des Humanités Scientifiques',
        totalPeriodique: 350,
        branches: [{
                intitulé: 'Religion',
                totalPeriodique: 10,
            },
            {
                intitulé: 'Education Civique et Morale',
                totalPeriodique: 10,
            },
            {
                intitulé: 'Education à la Vie',
                totalPeriodique: 10,
            },
            {
                intitulé: 'Algèbre, Statistique & Analyse',
                totalPeriodique: 40,
            },
            {
                intitulé: 'Géométrie et Trigonométrie',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Dessin Scientifique',
                totalPeriodique: 10,
            },
            {
                intitulé: 'Biologie',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Microbiologie',
                totalPeriodique: 10,
            },
            {
                intitulé: 'Géologie',
                totalPeriodique: 10,
            },
            {
                intitulé: 'Chimie',
                totalPeriodique: 30,
            },
            {
                intitulé: 'Physique',
                totalPeriodique: 30,
            },
            {
                intitulé: "Technique d'Information et de Communication",
                totalPeriodique: 10,
            },
            {
                intitulé: 'Français',
                totalPeriodique: 50,
            },
            {
                intitulé: 'Anglais',
                totalPeriodique: 30,
            },
            {
                intitulé: 'Géographie',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Histoire',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Economie Politique',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Education Physique',
                totalPeriodique: 10,
            },
        ],
        show: false,
    },
    {
        id: 6,
        promotion: '3ème Année des Humanités Scientifiques',
        totalPeriodique: 370,
        branches: [{
                intitulé: 'Algèbre, Statistique & Analyse',
                totalPeriodique: 30,
            },
            {
                intitulé: 'Géométrie',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Trigonométrie/Statistique',
                totalPeriodique: 10,
            },
            {
                intitulé: 'Dessin Scientifique',
                totalPeriodique: 10,
            },
            {
                intitulé: 'Biologie Générale',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Système des végétaux supérieurs',
                totalPeriodique: 10,
            },
            {
                intitulé: 'Ecologie',
                totalPeriodique: 10,
            },
            {
                intitulé: 'Géologie/Evolution',
                totalPeriodique: 10,
            },
            {
                intitulé: 'Chimie',
                totalPeriodique: 30,
            },
            {
                intitulé: 'Physique',
                totalPeriodique: 30,
            },

            {
                intitulé: "Technique d'Information et de Communication",
                totalPeriodique: 10,
            },
            {
                intitulé: 'Français',
                totalPeriodique: 50,
            },
            {
                intitulé: 'Anglais',
                totalPeriodique: 40,
            },
            {
                intitulé: 'Education Civique et Morale',
                totalPeriodique: 10,
            },
            {
                intitulé: 'Géographie',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Histoire',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Religion',
                totalPeriodique: 10,
            },
            {
                intitulé: 'Esthétique',
                totalPeriodique: 10,
            },
            {
                intitulé: 'Education Physique',
                totalPeriodique: 10,
            },
        ],
        show: false,
    },
    {
        id: 7,
        promotion: '4ème Année des Humanités Scientifiques',
        totalPeriodique: 370,
        branches: [{
                intitulé: 'Algèbre, Statistique & Analyse',
                totalPeriodique: 40,
            },
            {
                intitulé: 'Géométrie et Trigonométrie',
                totalPeriodique: 20,
            },

            {
                intitulé: 'Dessin Scientifique',
                totalPeriodique: 10,
            },
            {
                intitulé: 'Biologie',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Microbiologie',
                totalPeriodique: 10,
            },

            {
                intitulé: 'Géologie',
                totalPeriodique: 10,
            },
            {
                intitulé: 'Chimie',
                totalPeriodique: 30,
            },
            {
                intitulé: 'Physique',
                totalPeriodique: 30,
            },

            {
                intitulé: "Technique d'Information et de Communication",
                totalPeriodique: 10,
            },
            {
                intitulé: 'Français',
                totalPeriodique: 50,
            },
            {
                intitulé: 'Anglais',
                totalPeriodique: 30,
            },
            {
                intitulé: 'Education Civique et Morale',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Géographie',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Histoire',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Religion',
                totalPeriodique: 10,
            },
            {
                intitulé: 'Philosophie',
                totalPeriodique: 10,
            },
            {
                intitulé: 'Education Physique',
                totalPeriodique: 10,
            },
        ],
        show: false,
    },
    {
        id: 8,
        promotion: '1ère et 2ème Humanité Commerciale et Gestion',
        totalPeriodique: 470,
        branches: [{
                intitulé: 'Religion',
                totalPeriodique: 10,
            },
            {
                intitulé: 'Droit',
                totalPeriodique: 10,
            },

            {
                intitulé: 'Education à la vie',
                totalPeriodique: 10,
            },
            {
                intitulé: 'Education Physique',
                totalPeriodique: 10,
            },
            {
                intitulé: 'Géographie Economique',
                totalPeriodique: 10,
            },

            {
                intitulé: 'Correspondance commerçiale anglaise',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Correspondance commerciale française',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Fiscalité',
                totalPeriodique: 20,
            },

            {
                intitulé: 'Mathématiques Générales',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Mathématiques Générales',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Mathématiques Financières',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Activités complémentaires (visites guidées)',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Anglais',
                totalPeriodique: 30,
            },
            {
                intitulé: 'Documentation commerciale',
                totalPeriodique: 30,
            },
            {
                intitulé: 'Français',
                totalPeriodique: 40,
            },
            {
                intitulé: 'Informatique',
                totalPeriodique: 40,
            },
            {
                intitulé: 'Comptabilité Générale',
                totalPeriodique: 60,
            },
            {
                intitulé: 'Pratique Professionnelle',
                totalPeriodique: 100,
            },
        ],
        show: false,
    },
    {
        id: 9,
        promotion: '3ème Humanité Commerciale et Gestion',
        totalPeriodique: 470,
        branches: [{
                intitulé: 'Religion',
                totalPeriodique: 10,
            },
            {
                intitulé: 'Education à la vie',
                totalPeriodique: 10,
            },
            {
                intitulé: 'Education Civique et Morale',
                totalPeriodique: 10,
            },
            {
                intitulé: 'Education Physique',
                totalPeriodique: 10,
            },
            {
                intitulé: 'Géographie Economique',
                totalPeriodique: 10,
            },
            {
                intitulé: 'Fiscalité',
                totalPeriodique: 10,
            },
            {
                intitulé: 'Mathématiques Financières',
                totalPeriodique: 10,
            },
            {
                intitulé: 'Opérations des banques et crédits',
                totalPeriodique: 10,
            },
            {
                intitulé: 'Activités Complémentaires (visites guidées)',
                totalPeriodique: 10,
            },
            {
                intitulé: 'Mathématiques Générales',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Droit',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Correspondance commerciale française',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Correspondance commerciale anglaise',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Entreprenariat',
                totalPeriodique: 20,
            },

            {
                intitulé: 'Economie Politique',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Anglais',
                totalPeriodique: 30,
            },
            {
                intitulé: 'Français',
                totalPeriodique: 40,
            },
            {
                intitulé: 'Informatique',
                totalPeriodique: 40,
            },
            {
                intitulé: 'Comptabilité Générale',
                totalPeriodique: 60,
            },
            {
                intitulé: 'Pratique Professionnelle',
                totalPeriodique: 100,
            },
        ],
        show: false,
    },
    {
        id: 10,
        promotion: '4ème Humanité Commerciale et Gestion',
        totalPeriodique: 470,
        branches: [{
                intitulé: 'Religion',
                totalPeriodique: 10,
            },
            {
                intitulé: 'Correspondance commerciale française',
                totalPeriodique: 10,
            },
            {
                intitulé: 'Correspondance commerciale anglaise',
                totalPeriodique: 10,
            },
            {
                intitulé: 'Déontologie Professionnelle',
                totalPeriodique: 10,
            },
            {
                intitulé: 'Education à la vie',
                totalPeriodique: 10,
            },
            {
                intitulé: 'Education Civique et Morale',
                totalPeriodique: 10,
            },
            {
                intitulé: 'Education Physique',
                totalPeriodique: 10,
            },
            {
                intitulé: 'Financees Publiques',
                totalPeriodique: 10,
            },
            {
                intitulé: 'Fiscalité',
                totalPeriodique: 10,
            },
            {
                intitulé: 'Activités Complémentaires',
                totalPeriodique: 10,
            },
            {
                intitulé: 'Anglais',
                totalPeriodique: 30,
            },
            {
                intitulé: 'Comptabilité analytique',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Droit',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Economie de développement',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Mathématiques générales',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Organisation des entreprises',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Anglais',
                totalPeriodique: 30,
            },

            {
                intitulé: 'Comptabilité analytique',
                totalPeriodique: 40,
            },
            {
                intitulé: 'Français',
                totalPeriodique: 40,
            },
            {
                intitulé: 'Informatique',
                totalPeriodique: 40,
            },
            {
                intitulé: 'Comptabilité Générale',
                totalPeriodique: 50,
            },
            {
                intitulé: 'Pratique Professionnelle',
                totalPeriodique: 100,
            },
        ],
        show: false,
    },
    {
        id: 11,
        promotion: '1ère, 2ème, 3ème et 4ème Littéraire',
        totalPeriodique: '',
        branches: [{
                intitulé: 'Religion',
                totalPeriodique: 10,
            },
            {
                intitulé: 'Education Civique',
                totalPeriodique: 10,
            },
            {
                intitulé: 'Biologie',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Chimie',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Education Physique',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Géographie',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Histoire',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Mathématiques/Dessin',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Informatique',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Sociologie/ECOPOL/Esthétique',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Philosophie',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Anglais',
                totalPeriodique: 40,
            },
            {
                intitulé: 'Mathématique',
                totalPeriodique: 40,
            },
            {
                intitulé: 'Français',
                totalPeriodique: 50,
            },
            {
                intitulé: 'Latin',
                totalPeriodique: 50,
            },
        ],
        show: false,
    },
    {
        id: 12,
        promotion: 'CSP Secrétaria - Comptabilité - Informatique',
        totalPeriodique: 340,
        branches: [{
                intitulé: 'Déontologie',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Français',
                totalPeriodique: 50,
            },
            {
                intitulé: 'Comptabilité',
                totalPeriodique: 50,
            },
            {
                intitulé: 'Informatique',
                totalPeriodique: 50,
            },
            {
                intitulé: 'Dactylographie',
                totalPeriodique: 50,
            },
            {
                intitulé: 'Anglais',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Arithmétique Commerciale',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Législation sociale',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Organisation des bureaux',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Entreprenariat',
                totalPeriodique: 20,
            },
            {
                intitulé: 'Logistique',
                totalPeriodique: 20,
            },
        ],
        show: false,
    },
]