import React from 'react'
import {FaArtstation, FaFootballBall, FaPray} from 'react-icons/fa'
import './parascolaire.css'
import CardImage from '../common/card/CardImage'
import CardItem from '../common/card/CardItem'
import Title from '../common/title/Title'
import sport3 from './../../autresImages/Second/sport3.jpg'

function ParascolaireCard() {
  return (
    <>
      <section className='aboutHome'>
        <div className='container parascolaire aboutHome flexSB'>
          <CardImage image={sport3} className='imageCard' />

          <div className='right row'>
            <Title
              subtitle={'Par les activités parascolaires'}
              title={'Nous assurons la formation intégrale'}
            />
            <div className='items '>
              <div className='item flexSB'>
                <CardItem
                  itemIcon={<FaPray className='iconReact' />}
                  itemTitle='Spiritualité'
                  itemText="Le Collège Alfajiri est une école conventionnée catholique d'obédiance Jésuite ..."
                />
              </div>
            </div>
            <div className='items '>
              <div className='item flexSB'>
                <CardItem
                  itemIcon={<FaArtstation className='iconReact' />}
                  itemTitle='Culture et Art'
                  itemText='Grâce aux activités culturelles et artisitiques nous offrons à nos élèves un cadre propice pour exprimer et développer leur talents ...'
                />
              </div>
            </div>
            {
              <div className='items '>
                <div className='item flexSB'>
                  <CardItem
                    itemIcon={<FaFootballBall className='iconReact' />}
                    itemTitle='Sport'
                    itemText='Les activités sportives font partie intégrante de la formation intégrale que nous assurons à nos élèves. Le Collège les accompagnes dans les différentes compétitions ...'
                  />
                </div>
              </div>
            }
          </div>
        </div>
      </section>
      <div className='marigin2'></div>
    </>
  )
}

export default ParascolaireCard
