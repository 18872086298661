import React from 'react'
import Back from '../back/Back'
import './comingSoon.css'

const ComingSoon = () => {
  return (
    <div className='coming'>
      <Back title={'Coming Soon ...'} />
      <div className='commingContainer'>
        <div className='left'>
          <div className='welcome'>
            <h1>
              Bienvenu au site du <span>Collège Alfajiri</span>{' '}
            </h1>
          </div>
          <div className='rendezvous'>
            <h3>Cette page est en cours de maintenance</h3>
            <p>Merci de repasser ultérieurement...</p>
          </div>
        </div>
        <div className='right'></div>
      </div>
    </div>
  )
}

export default ComingSoon
