import React from 'react'
/* import eleve7 from './../../../autresImages/Second/eleve7.JPG' */
import eleve6 from './../../../autresImages/Second/eleve6.jpg'
import eleve7 from './../../../autresImages/Second/eleve7.jpg'
import finaliste2 from './../../../autresImages/Second/finaliste2.jpg'
import './InscriptionSecondaire.css'

const InscriptionSecondaireCard = () => {
  return (
    <>
      <div className='container-inscriptionMaternelle'>
        <div className='title'>
          <h3>Bureau des Inscriptions</h3>
          <p>Inscriptions en 7ème CTEB 2023-2024</p>
        </div>
        <div className='details'>
          <div className='detailsPicture'>
            <img src={finaliste2} alt='Image école maternelle' />
          </div>
          <div className='detailsInfo'>
            <h2>Conditions</h2>
            <ul style={{listStyleType: 'square'}}>
              <li>→ Age requis: 12 ans</li>
              <li>
                → Avoir obtenu 60% des points en maths, en français et au total
                de l'année;
              </li>
              <li>
                → Déposer un dossier comprennant l'attestation de fréquentation,
                la carte d'élève, deux photos passeport et les photocopies de :
                carte d'élève, bulletins 5è et 6è primaire et de l'acte de
                naissance;
              </li>
              <li>
                → Réussir et se classer en ordre utilie au concours d'admission
                (français et maths)
              </li>
            </ul>
            {/* <table className='detailsInfoTable'>
                <h2>Conditions :</h2>
              <tbody>
                <tr>
                  <td>Age requis :</td>
                  <td>12 ans</td>
                </tr>
                <tr>
                  <td>Age requis : </td>
                  <td>
                    4 ans (candidat(e)s né(e)s entre le 1er/06/2019 et le
                    31/12/2019)
                  </td>
                </tr>
                <tr>
                  <td>Education : </td>
                  <td>Chrétienne catholique</td>
                </tr>
              </tbody>
            </table> */}
          </div>
        </div>
        <div
          className='procedure-inscriptionMaternelle'
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <div
            className='imageCote'
            style={{
              width: '30%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <img
              style={{width: '100%', height: '48%'}}
              src={eleve7}
              alt='Image école maternelle'
            />
            <img
              style={{width: '100%', height: '48%'}}
              src={eleve6}
              alt='Image école maternelle'
            />
          </div>
          <table
            className='procedure-inscription procedureInscriptionSecondaire'
            style={{width: '65%'}}
          >
            <thead>
              <tr>
                <th>N°</th>
                <th>Date</th>
                <th>Activité</th>
                <th>Heure</th>
                <th>Lieu</th>
                <th>Observation</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Du mardi 27 juin au mercredi 28 juin 2023</td>
                <td>Retrait des fiches d'inscription</td>
                <td>de 8h00 à 11h00</td>
                <td>Dans les enceintes des Ecoles Primaires Alfajiri</td>
                <td></td>
              </tr>
              <tr>
                <td>2</td>
                <td>Lundi 03 juillet 2023</td>
                <td>
                  Remise, sous enveloppe, des ficles remplies et du dossier du
                  candidat.
                </td>
                <td>de 14h30 à 16h30</td>
                <td>Ecole primaire</td>
                <td></td>
              </tr>
              <tr>
                <td>3</td>
                <td>Jeudi 06 juillet 2023</td>
                <td>
                  Affichage de la liste des candidats retenus au concours
                  d'admission, et retrait, <span>de 14h00 à 17h00</span>, du
                  billet de participation au concours.
                </td>
                <td>13h00</td>
                <td>Ecole secondaire</td>
                <td>
                  Présence obligatoire du candidat muni de sa carte d'élève.
                </td>
              </tr>
              <tr>
                <td>4</td>
                <td>Samedi 08 juillet 2023</td>
                <td>Concours d'admission (Français et Mathématique).</td>
                <td>de 7h30 à 12h30</td>
                <td>Ecole secondaire</td>
                <td>
                  Le port de l’uniforme bleu-blanc est exigé. Le billet de
                  participation et la carte d’élève sont obligatoires pour
                  accéder à la salle de concours.
                </td>
              </tr>
              <tr>
                <td>4</td>
                <td>Mercredi 12 juillet </td>
                <td>
                  Affichage de la liste des candidats provisoirement admis en 7
                  ième du CTEB
                </td>
                <td>à 16h30</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>5</td>
                <td>Samedi 27 août </td>
                <td>Réunion avec les parents des élèves admissibles.</td>
                <td>à 12h00</td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className='inscriptionAtttention'>
          <div className='attentionImage'>
            <img src={finaliste2} alt='Photo maternelle' />
          </div>
          <div className='attentionText'>
            <h2>Attention</h2>
            <ul>
              <li>→ Les places disponibles sont très limitées !</li>
            </ul>
          </div>
        </div>
        <div className='signature'>
          <p>Fait à Bukavu, le 21 juin 2023</p>
          <p>R. P. François KANYAMANZA Bahati, S.J</p>
          <p>Recteur</p>
        </div>
      </div>
    </>
  )
}

export default InscriptionSecondaireCard
