import React from 'react'
import {FaClock, FaOpencart, FaPen} from 'react-icons/fa'
import './publication.css'
import AWrapper from '../about/AWrapper'
import CardImage from '../common/card/CardImage'
import CardItem from '../common/card/CardItem'
import Title from '../common/title/Title'
import eleve10 from './../../autresImages/Second/eleve10.jpg'
import {Link} from 'react-router-dom'

function PublicationsCard() {
  return (
    <>
      <section className='aboutHome'>
        <div className='container publication flexSB'>
          {<CardImage image={eleve10} />}
          <div className='right row'>
            <Title subtitle={'Publications'} title={'Lisez nos élèves'} />
            <div className='items '>
              <div className='item flexSB'>
                <Link to={'/revue'}>
                  <CardItem
                    itemIcon={<FaOpencart className='iconReact' />}
                    itemTitle='Revue Ngoma'
                    itemText='Revue bisemestrielle des élèves du Collège. Lisez les différentes reflexions de nos élèves sur diverses thématiques ...'
                  />
                </Link>
              </div>
            </div>
            <div className='items '>
              <div className='item flexSB'>
                <Link to={'/alfanews'}>
                  <CardItem
                    itemIcon={<FaPen className='iconReact' />}
                    itemTitle='Alfa News'
                    itemText="Magasine hebdomadaire reprenant l'actualité scolaire, nationale et internationnale ..."
                  />
                </Link>
              </div>
            </div>
            <div className='items '>
              <div className='item flexSB'>
                <Link to={'/reminder'}>
                  <CardItem
                    itemIcon={<FaClock className='iconReact' />}
                    itemTitle='Revue Reminder'
                    itemText='Revue annuelle des finalistes. Lisez les impressions des élèves finalistes à la fin de leur parcours au Collège ...'
                  />{' '}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className='separator'></div>
      <div className='separator'></div>
      <AWrapper />
      <div className='separator'></div>
    </>
  )
}

export default PublicationsCard
