import React from 'react'
import Back from '../../common/back/Back'

import {withStyles, makeStyles} from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Collapse from '@material-ui/core/Collapse'
import {useState} from 'react'
import {coursProgrammes} from './coursProgrammes'
import {useEffect} from 'react'

const StyleTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell)

const StyleTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow)

const useStyles = makeStyles({
  table: {
    marginTop: '5%',
    marginLeft: '10%',
    width: '70%',
  },
})

export const ProgammeCoursComponent = () => {
  let [appear, setappear] = useState(false)
  const classes = useStyles()
  const hideAppear = (index) => {
    let {show} = coursProgrammes[index]
    /* appear = show */
    setappear(!appear)
    coursProgrammes[index].show = appear
  }

  return (
    <div className='coursComponent'>
      <Back title={'Programme des cours'} />
      <div className='reglement'>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label='customised table'>
            <TableHead>
              <TableRow>
                <StyleTableCell align='center'>N°</StyleTableCell>
                <StyleTableCell align='center'>Promotion</StyleTableCell>
                <StyleTableCell align='center'>Total Périodique</StyleTableCell>
                <StyleTableCell align='center'></StyleTableCell>
              </TableRow>
            </TableHead>
            {coursProgrammes.map((promo, index) => {
              return (
                <TableBody key={promo.id}>
                  <TableRow>
                    <StyleTableCell component='th' scope='row'>
                      {index + 1}
                    </StyleTableCell>
                    <StyleTableCell align='left'>
                      {promo.promotion}
                    </StyleTableCell>
                    <StyleTableCell align='left'>
                      {promo.totalPeriodique}
                    </StyleTableCell>
                    <StyleTableCell align='left'>
                      {' '}
                      <button
                        className='btn-suite'
                        onClick={() => hideAppear(index)}
                      >
                        Voir plus
                      </button>{' '}
                    </StyleTableCell>
                  </TableRow>
                  <TableRow
                    style={
                      promo.show ? {display: 'table-row'} : {display: 'none'}
                    }
                  >
                    <StyleTableCell colSpan={3}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <StyleTableCell
                              component='th'
                              scope='row'
                            ></StyleTableCell>
                            <StyleTableCell align='center'>
                              Branche
                            </StyleTableCell>
                            <StyleTableCell align='center'>
                              Total Périodique
                            </StyleTableCell>
                          </TableRow>
                        </TableHead>
                        {promo.branches.map((branche, index) => {
                          return (
                            <TableBody key={index + 1}>
                              <TableRow>
                                <StyleTableCell>{index + 1}</StyleTableCell>
                                <StyleTableCell>
                                  {branche.intitulé}
                                </StyleTableCell>
                                <StyleTableCell>
                                  {branche.totalPeriodique}
                                </StyleTableCell>
                              </TableRow>
                            </TableBody>
                          )
                        })}
                      </Table>
                    </StyleTableCell>
                  </TableRow>
                </TableBody>
              )
            })}
          </Table>
        </TableContainer>
      </div>
    </div>
  )
}

export default ProgammeCoursComponent
