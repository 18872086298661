import React from 'react'
import {Route, Routes} from 'react-router-dom'
import Apropos from '../about/apropos'
import Inscriptions from '../inscriptions/Inscriptions'
import Parascolaire from '../parascolaire/Parascolaire'
import Pedagogie from '../pedagogie/Pedagogie'
import Publications from '../publications/Publications'
import RoiHome from '../roi/RoiHome'
import Home from './home/home'
import {AnimatePresence} from 'framer-motion'
import Header from './heading/Header'
import RoiMaternelle from '../roi/RoiEcole/RoiMaternelle'
import RoiPrimaire from '../roi/RoiEcole/roiPrimaire'
import RoiSecondaire from '../roi/RoiEcole/RoiSecondaire'
import RoiInternat from '../roi/RoiEcole/RoiInternat'
import CalendrierSolaire from '../pedagogie/pedagogieContents/CalendrierScolaire'
import CalendrierSolaireSecond from '../pedagogie/pedagogieContents/CalendrierScolaireSecond'
import ProgammeCours from '../pedagogie/pedagogieContents/ProgammeCours'
import ProgammeCoursComponent from '../pedagogie/pedagogieContents/ProgammeCoursComponent'
import ComingSoon from './commingsoon/ComingSoon'
import Galerie from '../galerie/Galerie'
import InscriptionMaternelle from '../inscriptions/InscriptionsMaternelle/InscriptionMaternelle'
import InscriptionPrimaire from '../inscriptions/InscriptionsPrimaire/InscriptionPrimaire'
import InscriptionSecondaire from '../inscriptions/InscriptionsSecondaire/InscriptionSecondaire'
import Reminder from '../publications/Revue Reminder/Reminder'
import EventCard from '../evenement/eventCard'
import RentreeScolaire2324 from '../articles/SomeArticles/RentreeScolaire2324'
import AlfaNews from '../publications/Alfa News/AlfaNews'
import Historique from '../articles/SomeArticles/Historique'
import ArgumentaireRevue from '../articles/SomeArticles/ArgumentaireRevue2024'

function AnimatedRoot() {
  return (
    <div>
      <AnimatePresence>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/apropos' element={<Apropos />} />
          <Route path='/roi' element={<RoiHome />} />
          <Route path='/publications' element={<Publications />} />
          <Route path='/inscriptions' element={<Inscriptions />} />
          <Route path='/pedagogie' element={<Pedagogie />} />
          <Route path='/parascolaire' element={<Parascolaire />} />
          <Route path='/roi/roimaternelle' element={<RoiMaternelle />} />
          <Route path='/roi/roiprimaire' element={<RoiPrimaire />} />
          <Route path='/roi/roisecondaire' element={<RoiSecondaire />} />
          <Route path='/roi/roiinternat' element={<RoiInternat />} />
          <Route
            path='/pedagogie/calendrierscolaire'
            element={<CalendrierSolaire />}
          />
          <Route
            path='/pedagogie/calendrierscolairesecondsemestre'
            element={<CalendrierSolaireSecond />}
          />
          <Route
            path='/pedagogie/programmecours'
            element={<ProgammeCoursComponent />}
          />
          <Route path='/galerie' element={<Galerie />} />
          <Route path='/comingsoon' element={<ComingSoon />} />
          <Route
            path='/inscriptionMaternelle'
            element={<InscriptionMaternelle />}
          />
          <Route
            path='/inscriptionPrimaire'
            element={<InscriptionPrimaire />}
          />
          <Route
            path='/inscriptionSecondaire'
            element={<InscriptionSecondaire />}
          />
          <Route path='/reminder' element={<Reminder />} />
          <Route path='/revue' element={<ArgumentaireRevue />} />
          <Route path='/evenement' element={<EventCard />} />
          <Route
            path='/rentreeScolaire2324'
            element={<RentreeScolaire2324 />}
          />
          <Route path='/alfanews' element={<AlfaNews />} />
          <Route path='/historique' element={<Historique />} />
        </Routes>
      </AnimatePresence>
    </div>
  )
}

export default AnimatedRoot
