import ecole1 from '../../../autresImages/Second/rentrEScolaire.jpg'
import ecole2 from '../../../autresImages/Second/rentrEScolaire2.jpg'
import historique1 from '../../../autresImages/Historique/historique1.jpg'
import historique2 from '../../../autresImages/Historique/historique2.jpg'
import historique3 from '../../../autresImages/Historique/historique3.jpg'
import historique4 from '../../../autresImages/Historique/historique4.jpg'

export const ArticleContent = {
  rentreeScolaire2324: {
    titre: 'Rentrée Scolaire 2023-2024',
    description: "L'effectivité de la rentrée scolaire ...",
    images: [ecole1, ecole2],
    content: (
      <p>
        {' '}
        L’ effectivité de la rentrée scolaire au collège Alfajiri s’ est fait
        remarquer très tôt à 6 h00 à la messe matinale par le nombre d’ élèves,
        avec l’ uniforme du collège, accompagnés de leurs parents en l’ église
        paroissiale comme pour présenter l’ année scolaire au Christ et comme
        pour dire«  notre école nous a montré la messe comme une voie vers
        Dieu ». <br /> <br /> A 7 h00, quinze minutes après la messe matinale,
        la cour de l’ école était déjà pleine d’ élèves comme si leur grand
        collège leur manquait déjà.  Et à 7 h25, le premier coup de cloche a
        sonné pour inviter les élèves à se mettre en rangs sur le terrain, en
        face de leurs titulaires. <br /> <br />
        <img
          src={ecole1}
          alt='Rassemblement à la rentrée scolaire'
          width={'100%'}
        />{' '}
        <br /> <br />
        Tout a commencé par l’ hymne national entonné par un élève du degré
        terminal.Après l’ hymne national a suivi l’ allocution du Révérend Père
        Recteur, le Père François Kanyamanza qui a invité les élèves au travail
        et au sérieux dans toutes leurs actions.  Le père recteur a aussi
        brièvement parlé aux élèves concernant le symposium dont les assises ont
        eu lieu à Alfajiri du 31 août au 3 septembre(La veille de la rentrée
        scolaire),{' '}
        <b>
          {' '}
          SYMPOSIUM INTERNATIONAL SUR LA JUSTICE, LA RECONCILIATION ET LA PAIX
          DANS LA REGION DES GRANDS - LACS SOUS L’ INSPIRATION DE L’ ENGAGEMENT
          SOCIO - POLITIQUE DE MGR CHRISTOPHE MUNZIHIRWA{' '}
        </b>
        , et a invité tous les élèves à avoir un«  esprit Munzihirwa » :{' '}
        <b> « Résister contre toute forme de domination et de corruption » </b>
         .Après son allocution, le Révérend Père Recteur a fait une prière dans
        laquelle il a confié toute l’ année scolaire, les élèves, les
        enseignants et tout le complexe entre les mains du Seigneur, et à la fin
        de sa prière, tout le monde, élèves, enseignants et membres de direction
        avons baissé nos têtes pour recevoir la bénédiction.  Après la
        bénédiction, les élèves du cycle long supérieur se sont dirigés vers les
        salles de classe en compagnie de leurs professeurs titulaires pour
        décortiquer le Règlement d’ Ordre Intérieur de l’ école.Deux heures
        étaient consacrées à cette fin. <br /> <br />
        En ce même moment, les élèves du cycle long inférieur se sont dirigés
        dans la grande salle où le conseiller pédagogique leur avait préparé un
        discours sur le mode de vie à l’ école secondaire, le comportement à
        observer mais aussi a souhaité la bienvenue aux élèves venus des écoles
        environnantes. <br /> <br />
        Après l’ allocution du conseiller pédagogique, quelques élèves ont été
        invités à donner leurs premières impressions sur l’ école secondaire.{' '}
        <br /> <br />
        L’ un d’ eux dira : « Je suis très impressionné positivement par le mot
        d’ accueil du Père Recteur aux nouveaux collégiens mais aussi très
        content d’ avoir enfin découvert le fameux collège et l’ homme le plus
        recherché et le plus rare : le Père Recteur. » <br /> <br />
        A un autre de dire : « Nous sommes un peu intimidés par les règlements
        scolaires, il semble y avoir beaucoup de rigueur au secondaire ». <br />{' '}
        <br />
        L’ horaire de ce Lundi était donc spécial.Au cycle long supérieur, les
        élèves avaient passé deux heures avec leurs titulaires et ceux du cycle
        long inferieur étaient restés à écouter le conseiller pédagogique
        pendant trois heures. <br /> <br />
        Le collège Alfajiri a eu la visite de quelques autorités venues se
        rassurer de l’ effectivité de la rentrée scolaire.Il s’ agit entre
        autres du ministre de l’ enseignement primaire, secondaire et technique,
        accompagné de toute sa délégation, du PROVED entouré de ses deux
        conseillers, et d’ un inspecteur itinérant. <br /> <br />
        Les cours se sont déroulés normalement et la journée s’ est clôturée
        comme d’ habitude à 12 h50. <br /> <br />
        <img
          src={ecole2}
          alt='Rassemblement à la rentrée scolaire'
          width={'100%'}
        />{' '}
      </p>
    ),
  },
}

export const historique = {
  historique: {
    titre: 'Historique',
    description: 'Situé actuellement au quartier Nyalukemba ...',
    images: [historique1, historique2],
    content: (
      <p>
        {' '}
        Situé actuellement au quartier Nyalukemba, ville de Bukavu, province du
        Sud - Kivu, en R.D.Congo, le collège Alfajiri a vu le jour en 1938, en
        réponse au besoin d’ un collège pour les fils des colons belges à
        Costermansville, ancienne appellation de la ville de Bukavu. Jusqu 'à
        cette année ces derniers suivaient les cours chez les filles, dans une
        école des sœurs du pensionnat. C’ est ainsi que Monseigneur Leys
        sollicita le concours des Pères Jésuites sur place au Congo. Ceux - ci
        vont décliner l ' offre, ils demandent 1 an de délai, pour constituer la
        communauté de démarrage.  Alors Mgr Leys cherche des confrères PP Blancs
        pour commencer tout de suite. En Octobre 1938, le collège va démarrer
        avec le père Mosmans comme recteur. Le Révérend Père Mosmans se fera
        aider de trois Belges, Christian Met Den Ancxt, Joseph Sterckx et Robert
        Stephenson, qui jouissaient de leurs vacances à Costermansville.Le
        collège ouvrit ses portes le 7 décembre 1938 à 8 heures du matin dans l’
        enceinte de l’ actuelle SNCC (Société Nationale de Chemin de fer au
        Congo), sous le nom de« Collège Saint Charles». Ledit collège organisait
        la section latine et avait comme Sainte Patronne « Notre Dame de la
        Victoire». <br /> <br />
        <img
          src={historique1}
          alt='Ancienne photo du Collège'
          width={'100%'}
        />{' '}
        <br /> <br />
        Le collège débuta le 5 janvier 1939, il comptait sept élèves dont quatre
        venus de l’ école des filles. Au cours du second trimestre de cette
        première année scolaire, le collège admit les élèves vivant en dehors de
        la ville. Au troisième trimestre commença la construction d’ un bâtiment
        pour le collège. Ce bâtiment ne comptait que deux petites classes, un
        dortoir, un réfectoire, une grande classe - salle d’ étude et cinq
        douches. Cette première année se termina avec un effectif de quatorze
        élèves. <br /> <br />
        L’ année scolaire 1939 - 1940 le collège atteignit un effectif d’
        environ vingt - cinq élèves. Au cours de cette année, Monseigneur Leys
        commença les démarches pour la construction du collège tel qu’ il se
        présente aujourd’ hui. Mais l’ ordre de suspendre toutes les démarches
        entreprises pour le collège définitif lui fut intimé par une délégation
        envoyée de la Belgique par le délégué apostolique. La délégation décida
        que la Compagnie de Jésus devait reprendre le collège et que ce dernier,
        faute de personnel, serait dirigé par une équipe mixte des Jésuites -
        Pères Blancs. <br /> <br />
        L’ année scolaire 1940 - 1941 accueillit quarante - deux élèves dont
        vingt - sept internes, ce nombre passa à 35 au cours du premier mois des
        cours. Ainsi, le bâtiment fut allongé et beaucoup d’ autres travaux
        furent effectués pour augmenter la capacité d’ accueil du collège.En
        mars 1941, le père Monnens, sj, arriva au Congo via le Portugal, fuyant
        de Rome, au moment de l’ invasion de la Belgique. Ce dernier vint à
        Bukavu pour s’ occuper de la construction du collège définitif et en
        juillet 1941, les premiers indices du chantier se dessinèrent sur le
        terrain de Nyalukemba avec sept cent ouvriers. <br /> <br />
        <img
          src={historique2}
          alt='Ancienne photo du Collège'
          width={'100%'}
        />{' '}
        <br /> <br />
        En septembre 1941, début de la quatrième année scolaire, arriva le
        premier professeur Jésuite au collège, le père Poelmans. Au cours de ce
        même mois, le collège Saint Charles, dirigé par le père Mosmans, devint
        un collège Jésuite sous la direction du Révérend Père Monnens.La
        première recollection des élèves eut lieu en mars 1942. Celle - ci fut
        clôturée par l’ administration des sacrements de première communion et
        de confirmation aux élèves par Monseigneur Leys. <br /> <br />
        L’ année scolaire 1942 - 1943 fut l’ année de l’ inauguration du nouveau
        bâtiment du collège, un bâtiment non encore achevé, avec une seule
        aile.Cent douze élèves furent repartis dans les différentes classes et
        quatre - vingts d’ entre eux occupèrent les chambres de l’ internat. Les
        Pères Blancs s’ installèrent au collège Jésuite pour y poursuivre leur
        travail d’ enseignement. <br /> <br />
        Au début de l’ année scolaire 1944 - 1945, le collège a ses humanités
        latines complètes et la population estudiantine s’ élève à 259 dont 185
        internes. Cette année connut aussi l’ apparition de la revue«
        Orientation» qui devint plus tard l’ organe officiel d’ expression des
        élèves du collège. L’ année scolaire se termina par la fin de la seconde
        Guerre Mondiale avec comme conséquence le départ aussi bien du personnel
        missionnaire(Pères Blancs et Jésuites) que de nombreux élèves pour la
        Belgique. Les missionnaires furent remplacés par un personnel recruté en
        Europe. <br /> <br />
        Connaissant le dévouement et les compétences pédagogiques des Frères
        Maristes, ainsi que l’ influence chrétienne qu’ ils exercent sur leurs
        élèves de par le monde, le Père Recteur, le Révérend Père Monnens,
        décida de négocier avec ces derniers en vue de faire venir certains d’
        entre eux au collège. Aussitôt pensé, aussitôt exécuté. Il contacta le
        supérieur des Frères Maristes, qui en envoya quatre de sa communauté.{' '}
        <br /> <br />
        En Octobre 1946, le Père Monnens, premier recteur jésuite et
        constructeur du grand bâtiment du collège définitif est nommé
        Représentant Personnel du Souverain Pontife, basé à Addis - Abeba. En
        attendant l’ arrivée d’ un nouveau recteur, le Père De Wolf qui était à
        la fois professeur de rhétorique et Préfet des Etudes est désigné par
        les supérieurs pour diriger provisoirement le grand collège. En décembre
        de la même année, ce dernier sera remplacé par le Père Pol Meulenyzer,
        un vieux pionnier de la mission de Kisantu, qui ne dirigea que pendant
        un an. Durant son bref rectorat, le Père Pol acheva la construction de
        la salle de fête, bâtit la salle de gymnastique, la maison des Frères
        Maristes, la maison des ouvriers et la Chapelle, ainsi que la maison de
        campagne à Cyangugu, et aménagea les premiers tennis. <br /> <br /> A la
        rentrée des classes de l’ année scolaire 1947 - 1948, un nouveau
        recteur, le Père Paul Thienpont, va prendre la relève. Ce dernier
        quittera le collège au courant de l’ année scolaire suivante pour raison
        de santé et le Père José De Wolf, alors directeur des études, va assurer
        l’ intérim jusqu’ à la nomination d’ un nouveau recteur, le Père Paul
        Croonenberghs, le 1e r juillet 1949. Celui - ci clôtura l’ année
        scolaire.Cette fin d’ année fut marquée par de nombreux cas de paralysie
        infantile dû à la poliomyélite, ce qui retarda le début de l’ année
        scolaire suivante. Sous le premier mandat du père Croonenberghs, le
        collège avait doublé en superficie - bâtie. Le nouveau recteur prit«
        Stella Duce» comme gouvernail. <br /> <br />
        Au cours de l’ année scolaire 1949 - 1950 débuta la section Flamande qui
        répondait à la demande des parents flamands. Celle - ci débuta après la
        section commerciale qui avait été ouverte quelques années plus tôt.{' '}
        <br /> <br />
        Le bâtiment de l’ école primaire fut achevé au courant de l’ année
        scolaire 1950 - 1951. <br /> <br />
        En 1954, le père Croonenberghs est recherché à l’ Institut Supérieur
        Saint Ignace à Anvers pour y faire quelques constructions. Avant son
        départ, il admet les premiers congolais au collège.Le père Croonenberghs
        a été baptisé« le second fondateur du collège» par les parents d’
        élèves, les enseignants, les élèves et tous les habitants de Bukavu.Le
        Père Recteur est alors remplacé par le Père De Wolf qui était le
        ministre de la communauté. A son retour en septembre 1958, le Père
        Croonenberghs, qui avait laissé un effectif de 650 élèves en 1954, se
        trouve devant 952 élèves et 45 professeurs. Ce dernier va rester jusqu’
        à l’ indépendance et au - delà. <br /> <br />
        Quelques jours après l’ indépendance, pendant les vacances de l’ année
        scolaire 1959 - 1960, comme beaucoup d’ européens avaient décidé de
        rentrer chez eux, le collège se transforma en hôtel de passage où des
        refugiés étaient accueillis par les prêtres. Ceci ne dura qu’ un mois.{' '}
        <br /> <br /> A la rentrée des classes en septembre 1960, le personnel
        du collège était resté intact et était prêt à mettre ses compétences au
        service du pays. Ainsi, les congolais ne tardèrent pas à y faire
        inscrire leurs enfants. Cette année scolaire qui suivait l’ indépendance
        comptait 694 élèves dont 463 africains mais le nombre d’ internes était
        réduit à 70. Cette année, le collège accueillit des élèves de races
        différentes, de tous pays, de religions différentes, et même des jeunes
        filles. <br /> <br />
        <img
          src={historique3}
          alt='Ancienne photo du Collège'
          width={'100%'}
        />{' '}
        <br /> <br />
        Au mois de novembre 1960, s’ ouvrirent au collège des« cours de
        perfectionnement» pour compléter la formation générale et
        professionnelle des agents de l’ Etat qui avaient remplacé au pied levé
        les fonctionnaires belges. Ces cours étaient dispensés à 200 candidats
        sous la direction d’ une dizaine de professeurs. <br /> <br />
        L’ année 1961 - 1962 s’ est clôturée avec 500 élèves. L’ année 1962 -
        1963 débuta avec un effectif de 811 élèves, dont 570 congolais, avec 261
        internes.Cette année, le collège comptait 26 nationalités et 46 unités
        dans le corps professoral. <br /> <br />
        Au courant de l’ année scolaire 1963 - 1964, du 29 au 31 mai 1964, le
        collège feta son jubilé d’ argent et ouvrit une œuvre sociale sous le
        nom de« œuvre des petits vagabonds», l’ actuel« chéché», a l’ initiative
        du Père André De Ridder, sj. Cette œuvre consistait à ramasser aux coins
        des rues les garçons de 8 à 14 ans, dont personne ne s’ occupait et qui
        trouvaient leur nourriture dans les poubelles du collège et des
        environs. <br /> <br />
        Le Père Croonenberghs sera remplacé en 1972 par le Père Emile Jansen. La
        première année du rectorat de ce dernier connaitra la Zairianisation des
        noms. Cette zairianisation consistait au changement des noms européens
        en noms africains.Le Collège ne fut pas épargné.Ainsi il passa de« Notre
        Dame de la Victoire» à« Alfajiri». <br /> <br />
        En septembre 1976, un nouveau recteur, le Père Pelenda Bikakala, Premier
        Jésuite congolais fut envoyé au collège. Ce dernier resta jusqu’ en 1978
        a l’ arrivée du Père Robert Roelandt.Le salaire très modique de l’
        enseignant occasionnait de nombreuses grèves pour revendiquer du
        gouvernement un salaire digne payé régulièrement. L’ engagement du Père
        Recteur aux cotes de son personnel et ses conférences pour éveiller la
        conscience des intellectuels zaïrois a se prendre en charge lui ont
        suscité de vives poursuites en ce temps du MPR - Parti Etat. Il dut
        ainsi quitter le Zaïre pour le collège du Saint Esprit a Bujumbura.Le
        Père Jules Dubois va lui succéder en 1981. Le Père Jules va mourir dans
        un accident de circulation sur la route du Bas - Zaïre avant la fin de
        son mandat.Ensuite lui succédera le Père Léon de Saint Moulin. <br />{' '}
        <br />
        Après le Père Léon, le Père Charles Verhaezen prit la gestion du collège
        mais celui - ci va se casser la jambe dans un accident avant la fin de
        son mandat et va rentrer en Belgique pour les soins. A son départ, l’
        économe de la communauté, le Père Jan Cornelissen va prendre la
        relève.Ce dernier ne dirigea pas l’ école longtemps, pour raison de
        santé.Le R.Père Provincial fit recours au Père Henri de la Kethule qui
        poursuivit la direction de l’ école comme Père Préfet et le R.Père Jan
        resta supérieur de la communauté. Le Père Henri décida que les filles
        qui, après l’ école primaire Alfajiri devaient transiter par d’ autres
        écoles avant de revenir pour les humanités, pouvaient poursuivre en
        première secondaire au collège Alfajiri. <br /> <br />
        Tout le Sud - Kivu connaitra une année de grève, l’ année 1991 - 1992
        qui fut l’ année de l’ arrivée du Père André Cnockaert. L’ année 1992 -
        1993 fut la toute première avec les filles à l’ Ex - Cycle d’
        Orientation Alfajiri. Cette année fut aussi marquée par le changement du
        système de recrutement de nouveaux élèves en première secondaire au
        collège. Le recrutement des élèves de première se faisait par«
        placement’’ par l’ inspection du pool qui a remis cette responsabilité
        au recteur, chef d’ établissement. <br /> <br />
        L’ appellation du pays change avec la prise du pouvoir par L.D.Kabila le
        17 mai 1997. Le pays cesse d’ être Zaïre et devient la République
        Démocratique du Congo. Le 02 aout 1998 eclate une rebellion dans l’ Est
        et le pays est coupé de l’ Ouest.En consequence, pas de rentrée
        scolaireen Septembre 1998: l’ insécurité bat son plein dans les
        provinces sous occupation. Le Père Cnockaert reste recteur jusqu’ en
        1997 - 1998. Cette année, la rentrée des cours eut lieu en Janvier 1998.{' '}
        <br /> <br />
        <img src={historique4} alt="Ancienne de l'école" width={'100%'} />{' '}
        <br /> <br />
        L’ année scolaire 1998 - 1999 accueillit un nouveau recteur, le Révérend
        Père Carlos Eduardo Meijia. Son mandat fut marqué par le decesen
        novembre 2000 du Directeur des Etudes laïc, le feu MahesheMahi François
        après neuf ans de loyaux services a ce poste, pour se faire remplacer
        par le Père Nzanzu Kapitula. En janvier 2001, il y eut irruption
        volcanique à Goma, au Nord - Kivu, et le collège dut accueillir des
        élèves sinistrés de Goma. <br /> <br />
        Le Révérend Père Kalubi Augustin, actuel délégué du Révérend Père
        Provincial pour l’ éducation arriva au collège comme recteur l’ année
        scolaire 2004 - 2005. Au cours de son mandat, en janvier 2008, il y eut
        un séisme qui fit écrouler trois extrémités du collège: la tour de l’
        école maternelle, celle de l’ Ex - Humanitas, de la salle de projection,
        et entraina des besoins de réfection a la tour de la grande chapelle, l’
        entrée de l’ école primaire en face de la‘’ grotte de Lourdes’’. Ces
        parties furent refaites la même année et les années suivantes sous la
        conduite des travaux du Père André De Ridder. Différents bienfaiteurs
        extérieurs ont participé aux couts de ces travaux. <br /> <br />
        Le Père Augustin sera remplacé en 2010 - 2011 a ce poste par le Révérend
        Père José Minaku Lokuli, socius sortant du R.Père Provincial et actuel
        président de la Conférence des Jésuites d’ Afrique et
        Madagascar(JCAM). C’ est avec le Père José que le collège va fêter ses
        75 ans d’ existence et recevoir aux murs une nouvelle couche de peinture
        au secondaire sous l’ entrepreneuriat du Frère Jésuite Innocent. Les
        murs du collège n’ avaient pas été repeints depuis les événements du
        Mercenaire Belge, le Major Jean Schramme, qui en juillet 1967 avait
        établi son Quartier Général au collège Notre Dame de la Victoire, siège
        qui n’ a duré que quelques mois. Pour accomplir ces travaux si couteux,
        le collège a tendu la main a des bienfaiteurs et a sollicité également
        un concours de dix dollars par an a chaque élève inscrit pendant trois
        ans. <br /> <br />
        Alors que le Père José devait commencer son deuxième mandat de trois ans
        au collège, il fut nommé Provincial en 2014. Le Père Vincent Van Haelst,
        alors Directeur des Etudes, va assurer l’ intérim jusqu’ à l’ arrivée du
        Père Carlos E.Meijia en septembre 2014 comme recteur pour un second
        mandat de six ans. C’ est en Décembre 2019 à partir de la Chine qu’
        éclata la terrible pandémie à Corona Virus qui fit mettre les écoles du
        pays en quarantaine à partir de Février 2020 jusqu’ en Septembre. Après
        le deuxième mandat du Père Carlos arriva, en 2020 - 2021, l’ actuel
        recteur, le R.Père François Kanyamanza Bahati. Son arrivée sera marquée
        par un évènement de triste mémoire, à savoir la gratuité de l’
        enseignement en R.D.Congo, qui occasionna de nombreuses grèves. Le
        gouvernement n’ a pas respecté les Accords conclus avec les
        syndicalistes de l’ Enseignement Primaire, Secondaire et Technique à
        Mbudi, ni à Bibwa. Cette mesure promulguée par la Loi Cadre de 2014, n’
        était pas accompagnée de mesures d’ encadrement pour la rendre efficace
        et la faire accepter. C’ est plus tard qu’ il fut rendu public qu’ elle
        ne concernait que l’ Education de Base, et fut limitée ensuite à l’
        Ecole Primaire seule. Le fonctionnement actuel du collège continue d’ en
        subir les contre - couts. <br /> <br />
        <h3> Différentes activités au sein du Collège Alfajiri </h3>{' '}
        <ul>
          {' '}
          <li>
            {' '}
            <em> Spirituelle </em>{' '}
          </li>{' '}
        </ul>
        Pour confier ses années scolaires au Seigneur Dieu et implorer l’
        intercession de Notre Dame de la Victoire, le collège Alfajiri commence
        ses années scolaires par la messe du Saint Esprit.Cette célébration est
        organisée une ou deux semaines après l’ ouverture de l’ année
        scolaire. Ce temps permet à la chorale, aux acolytes et aux gardiens de
        paix (tous des élèves) de la préparer. Cette messe est généralement
        présidée par le Révérend Père Recteur. Les autres prêtres, enseignant au
        collège, concélèbrent. <br /> <br />
        Le collège organise chaque année la catéchèse préparatoire à l’
        administration des trois sacrements d’ initiation chrétienne à l’ instar
        des autres écoles conventionnées catholiques.La catéchèse est assurée
        par des enseignants du collège avec l’ intervention des novices
        Ursulines, utd, les stagiaires jésuites, certaines religieuses œuvrant
        au sein du collège après une formation organisée par la Paroisse Saint
        Pierre Claver de Nguba.A la fin de l’ année catéchétique, généralement
        en Mai, les sacrements sont administrés aux élèves qui ont suivi
        assidûment la catéchèse et qui ont participé avec fidélité aux
        célébrations de l’ Eucharistie matinale du mercredi et samedi matin. A
        cette messe, les parents d’ élèves sont conviés ainsi que toute l’
        école, administratifs, enseignants et élèves. Journée festive au collège
        et en famille. <br /> <br />
        Chaque lundi, il y a rassemblement sur le terrain de football pendant
        lequel les élèves, accompagnés de leurs enseignants et tous les membres
        de la direction exécutent, non seulement, l’ hymne national en hissant
        le drapeau mais aussi une fervente prière par un élève selon un tour de
        classes, pour consacrer la semaine au Seigneur. <br /> <br />
        Des récollections sont organisées deux fois chaque année, aussi bien
        pour les élèves que pour le personnel éducatif pour se préparer aux
        temps liturgiques forts, la Pâques et la Noël. La première récollection
        a lieu dans la période de l’ Avent et la suivante en Carême. Une autre
        journée de réflexion rassemble les collaborateurs des Peres jésuites de
        la ville: le personnel de la paroisse Saint Pierre Claver de Nguba, le
        personnel de la Maison de retraite Amani, le personnel de la Communauté,
        le personnel de Cheché et le personnel du collège Alfajiri. <br />{' '}
        <br />
        Sont également organisés l’ office d’ imposition des cendres marquant le
        premier mercredi de carême, les chemins de croix, la confession. <br />{' '}
        <br />
        Les élèves vont en retraite de trois jours au Centre Spirituel AMANI
        deux fois au cours de leur cursus scolaire au collège Alfajiri. La
        première retraite accueille les élèves de deuxième des
        humanités(ancienne quatrième secondaire) et la suivante ceux de
        quatrième des humanités(ancienne sixième secondaire). <br /> <br />
        Chaque mercredi et samedi matin à partir de 6 h45, une messe est dite en
        la grande chapelle de l’ école à l’ intention des élèves, enseignants et
        quelques parents qui accompagnent leurs enfants. <br /> <br />
        Chaque jour au collège Alfajiri, les cours commencent par une prière
        matinale dite en classe et se terminent par une prière finale au cours
        de laquelle élèves et éducateurs font une rétrospection sur la journée
        écoulée. <br /> <br />
        <ul>
          <li>
            {' '}
            <em> Conversation spirituelle </em>{' '}
          </li>{' '}
        </ul>{' '}
        Les élèves en difficulté d’ intégration sont accompagnés par la sœur
        Noëlla HABAMUNGU, de la congrégation des Sœurs Picolés, psychologue de
        formation.Par le dialogue et les conseils, cette dernière amène les
        élèves à discerner sur leur vie, à la manière de Saint Ignace de Loyola.{' '}
        <br /> <br />
        A part la sœur Noëlla, l’ école a aussi un père spirituel en la personne
        du Père Christian Ngoso Emimi, qui aide à la promotion du discernement
        et des exercices spirituels. <br /> <br />
        <ul>
          <li>
            {' '}
            <em> Marcher avec les pauvres et les démunis </em>{' '}
          </li>{' '}
        </ul>{' '}
        Pendant la période de carême et celle de l’ avent, les élèves collectent
        des biens en nature et en espèce pour venir en aide soit aux déplacés
        des guerres, soit aux prisonniers, soit aux orphelinats de la place.{' '}
        <br /> <br />
        L’ école dispose aussi d’ un bureau de service social qui soutient les
        élèves issus des milieux aux revenus modestes, dans leur cheminement
        scolaire. Des personnes généreuses et de bonne volonté apportent leur
        appui à travers ce service. <br /> <br />
        <ul>
          {' '}
          <li>
            {' '}
            <em> Fomration continue </em>{' '}
          </li>{' '}
        </ul>
        Le collège Alfajiri organise beaucoup de séminaires et formations sur la
        protection de l’ enfant, sur les droits de l’ enfant, sur l’ éducation
        ignacienne, les caractéristiques de l’ Education jésuites, le leadership
        et l’ éducation financière et les préférences apostoliques, etc. Des
        spécialistes sont invités à l’ école ou au Centre Spirituel pour former
        les enseignants sur ces différents sujets. <br /> <br />
        <ul>
          {' '}
          <li>
            {' '}
            <em> Activités culturelles </em>{' '}
          </li>{' '}
        </ul>
        Au collège Alfajiri la culture n’ est pas négligée. De temps en temps
        des journées de divertissement sont organisées pour ses élèves: des
        défilés de modes, danses folkloriques, récitals des poèmes et pièces de
        théâtre; la fête tutélaire du collège est aussi célébrée chaque 8
        Décembre mais celle - ci a été perturbée par la pandémie COVID 19 à
        cause des mesures barrière décrétées par l’ OMS. La fête du collège est
        organisée d’ habitude pendant deux jours. La première journée de la fête
        ne concerne que les collégiens et quelques ACA (Anciens du Collège
        Alfajiri) qui le veulent.Ce jour - là il y a cours normalement et les
        activités culturelles ne commencent qu’ après 10 heures, dans la grande
        salle. Cet après - midi est appelé« soirée culturelle». <br /> <br />
        Ladite fête n’ a repris que cette année avec timidité et n’ a pas eu
        lieu le 8 Décembre comme à l’ accoutumée mais plutôt le 24 mai 2023.{' '}
        <br /> <br />
        Le deuxième jour de la fête est ouvert à tout le monde: les parents des
        élèves, les élèves d’ autres écoles, les autorités scolaires d’ autres
        écoles… sont invités.Ce jour, (le deuxième), tout se passe dehors, sur
        la cours de l’ école. Certains artistes de la ville sont invités pour
        agrémenter la fête, contrairement à la soirée culturelle qui est animée
        du début à la fin par les collégiens. <br /> <br />
        <ul>
          {' '}
          <li>
            {' '}
            <em> Actiités sportives </em>{' '}
          </li>{' '}
        </ul>
        Trois équipes de football sont organisées au collège Alfajiri: une pour
        les élèves filles, une pour les élèves garçons et une autre pour les
        enseignants.Chaque samedi il y a un match soit interclasse ou
        intersection ou encore inter niveaux.Aux grands jours de l’ année
        scolaire comme le 8 mars, la fête des enseignants, la fête du collège,
        les collégiens invitent d’ autres écoles à jouer contre eux ou encore
        les enseignants du collège contre les enseignants d’ une autre école de
        la ville. <br /> <br />
        <ul>
          {' '}
          <li>
            {' '}
            <em> Activités écologiques </em>{' '}
          </li>{' '}
        </ul>
        Gardons notre école propre!Le collège Alfajiri a une équipe d’ écologie
        qui est très allergique aux paperasses, au caoutchouc, au plastic, … sur
        la cours de l’ école. Cette équipe se charge de ramasser la saleté
        abandonnée par - ci par - là et de planter les arbres dans l’ enclos de
        l’ école. Elle travaille en collaboration avec d’ autres groupes
        écologiques comme celui de l’ UCB (Université Catholique de Bukavu) et
        le service urbain d’ Hygiène et Environnement. <br /> <br />
        Des poubelles ont été placées dans tous les coins et différents niveaux
        du bâtiment de l’ école dans le but de rendre la propreté possible au
        collège.Essayant ainsi de lutter contre la mentalité ambiante de laisser
        aller. <br /> <br />
        <ul>
          {' '}
          <li>
            <em>
              {' '}
              La Mutuelle dfe Solidarité du Personnel, MUSO - ALFA en sigle{' '}
            </em>{' '}
          </li>{' '}
        </ul>
        Les membres du personnel comprennent des hommes et de femmes dont la
        plupart sont responsables de famille. Face a la modicité de traitement
        mensuel payé par le gouvernement, aux limites de la Contribution des
        Parents, aux besoins d’ un loyer décent et autres nécessités de vêtir,
        scolariser, nourrir et soigner sa progéniture en cas de maladie, le
        personnel vient d’ initier cette année une mutuelle de solidarité a
        laquelle pourront désormais recourir sous forme d’ emprunt ceux dont l’
        urgence des problèmes ne peut attendre la paie du mois en cours. Les
        frais d’ adhésion s’ élèvent à cinq dollars et chaque mois une mise de
        vingt cinq dollars est attendue de chaque membre. Les cinq membres du
        bureau désignés par l’ Assemblée Générale ont proposé des statuts avec
        une quarantaine d’ articles et alinéas. Deux commissions crédits et
        contrôle sont issues du vote et désignations d’ une Assemblée Générale
        élective. Deux types de crédit peuvent être accordés: crédit express
        dont le montant varie entre cinquante et deux cent cinquante dollars
        remboursable en deux mensualités et crédit investissement supérieur a
        deux cent cinquante dollars payable en six mensualités. Chaque fin du
        mois la commission de crédit se réunit pour examiner les demandes reçues
        par le gérant et décider du montant crédit à accorder. Le 15 Mai dernier
        un rapport de fonctionnement a été présenté au Révérend Père Recteur par
        les membres du bureau en présence d’ un représentant des deux autres
        commissions, entendez commission de crédit et de contrôle. Le Père
        Recteur a félicité la qualité de travail abattue et les beaux résultats
        obtenus.Ce rapport comprenait aussi bien les résultats du contrôle
        effectué par la commission ad hoc que la situation actualisée a la fin
        du mois d’ avril 2023. La commission de contrôle vient de présenter à l’
        Assemblée Générale les résultats de son premier audit interne de février
        - mars en ce 03 juin 23. Occasion dont se sont saisis les membres du
        bureau pour présenter une situation actualisée a la fin du mois de Mai
        2023 et des soucis et projets d’ avenir de l’ entretien avec le Révérend
        Père Recteur. Des quatre - vingts membres adhérents trente - neuf ont
        répondu à l’ invitation et étaient très satisfaits des rapports et de la
        qualité d’ engagement et d’ abnégation de ceux qui les servent.Des
        recommandations ont été formulées par les participants.{' '}
      </p>
    ),
  },
}

export const argumentaireRevue2024 = {
  argumentaire: {
    titre: 'Argumentaire',
    description: 'Fondé en 1938, le collège Alfajiri ...',
    images: [historique1, historique2],
    content: (
      <p>
        {' '}
        Fondé en 1938, le collège Alfajiri, héro et survivant de l’ histoire s’
        évertue dans la pérennisation de son identité iconique.Le 8 décembre
        1938, 8 décembre 2023, dans les vents et marrées, les joies et chagrins,
        les succès et échecs, le collège Alfajiri ancre son aventure de
        gladiateur. Il s’ agit d’ un parcours de 85 ans, une histoire de peine
        et de longue haleine, de croissance et de persévérance, de bonheur et de
        malheur.Cette histoire, sinueuse et heureuse qui questionne autant qu’
        elle émerveille. C’ est un parcours qui rappelle notre passé et qui
        interpelle notre conscience(attention). Tout de même, placé dans la
        ligne de temps, le collège Alfajiri est en évolution constante et
        irréversible qu’ il nous faut considérer et revisiter
        constamment.Paraissant monotone, pourtant apparente, chaque instant de
        la vie du collège est une nouveauté unique qu’ il nous faut appréhender
        méticuleusement. Ceci, en vue d’ en saisir la pertinence, d’ en déduire
        les principes pilotes à pérenniser, de participer à la formation
        intégrale de qualité à offrir à la jeunesse de Bukavu. <br /> <br />
        <img
          src={historique1}
          alt='Ancienne photo du Collège'
          width={'100%'}
        />{' '}
        <br /> <br />
        En effet, les 85 ans du collège Alfajiri sont un moment de fête et de
        réjouissance.Nous sommes appelés à célébrer ce temple de l’ éducation de
        la jeunesse de notre province et de notre pays.Cependant, plutôt qu’ une
        fête ordinaire, c’ est l’ instant d’ inflexion, le kairos pour la
        réminiscence, un temps de remémoration, un temps de bilan. Nous allons
        effectuer le bilan non seulement de notre progression, nos succès et
        brillances, mais aussi de nos failles, nos régressions et nos échecs,
        des moments percutants et titillants de notre évolution.C’ est le temps
        de la révolution. En héros, nous voulons rééditer les marques
        distinctives qui doivent être les nôtres, en luttant contre tous les
        antivaleurs qui obscurcissent les bonnes consciences de notre monde.C’
        est cela« l’ esprit Munzihirwa», l’ aphorisme de l’ année scolaire en
        cours: une résistance à toute forme de corruption, de dépravation, de
        pollution humaine. Nous allons réécrire l’ histoire de notre devenir,
        cette fois, en partant des caractéristiques des valeurs tant humaines
        que les particularités de l’ éducation jésuite professées par notre alma
        mater, le collège Alfajiri. <br /> <br />
        Nous voulons, non seulement nous réformer, mais aussi informer les
        autres(ceux qui ne sont de notre communauté éducative) à partir de nos
        valeurs, pour ensemble, former une société meilleure marquée par la
        conscience, la compétence, la collaboration et la compassion; les traits
        essentiels de l’ éducation intégrale jésuite que promeut le collège
        Alfajiri. <br /> <br />
        Plutôt que spectateurs et passifs face au devenir du collège Alfajiri,
        il est temps pour nous d’ agir rationnellement et efficacement. Il est
        temps d’ aiguillonner notre esprit critique dans une perspective de
        conservateur ou sous le prisme de réformateur. Il est temps d’ engager
        les initiatives concrètes tourbillonnant autour de la survie héroïque de
        notre cadre de vie, patrimoine historique de la région du Kivu, notre
        très cher collège Alfajiri.Aussi, en face d’ un monde en contre -
        courant, il devient impérieux de cogiter. Et, le temps nous est propice
        pour invertir les tendances miteuses que nous offre notre siècle et de
        consolider ses opportunités, afin de promouvoir une société de paix, de
        réconciliation et de justice.Et ce, grâce à l’ espace d’ échange et de
        dialogue que nous offre la Révue Ngoma, dans son numéro spécial:
        Alfajiri spécial 85: Défis et perspectives. <br /> <br />
        <img
          src={historique2}
          alt='Ancienne photo du Collège'
          width={'100%'}
        />{' '}
        <br /> <br />
        Par ailleurs, Alfajiri spécial 85 est un appel à la projection
        panoramique de l’ horizon 100. C’ est - à - dire qu’ à la lumière de
        notre bilan, nous voulons définir nos objectifs, nos défis et nos
        actions à entreprendre dans les 15 prochaines années avant la
        célébration du centenaire du collège. Dans un contexte de
        mondialisation, de l’ évolution de la technologie, du renversement des
        valeurs tant morales qu’ éthiques, le collège Alfajiri, en tant que
        berceau des consciences et éclaireur du peuple, doit s’ investir dans l’
        actualisation de son modus procendi (manière de procéder) pour enfin
        répondre efficacement aux attentes des générations futures. <br />{' '}
        <br />
        <h3> Quelques questins d 'orientation</h3> <br /> -Comment peut - on
        résumer le bilan de l’ histoire du collège Alfajiri à dater du dernier
        jubilé ? (75 ans célébrés en 2013). <br /> - Quels sont les défis qui se
        présentent à la mission de l’ éducation du collège Alfajiri aujourd’ hui
        ?
        <br /> - Quelles perspectives entreprendre pour une floraison continue
        et totale du collège Alfajiri ? (Projection de l’ horizon 100) <br /> -{' '}
        Comment l’ esprit Munzihirwa peut - il nous inspirer et nous éclairer
        dans le processus de redynamisation et de refondation des valeurs de
        notre monde ? <br /> - Le collège a - t - il réussi dans sa mission,
        comme nous dit le Père Arrupe, de former les hommes et les femmes pour
        les autres ? Pensons à la devise : Au collège se forge en toi l’ homme /
        la femme de demain. <br /> - Que pensez - vous de l’ impact de« 4
        C»(compassion, compétence, conscience collaboration) qu’ inculque l’
        éducation jésuite aux jeunes par rapport à l’ histoire du collège
        Alfajiri, son enjeu et ses défis aujourd’ hui ? <br /> <br />
        <h3> Modalités </h3> <br /> - Peuvent écrire dans la Revue : les actuels
        élèves, anciens élèves, enseignants et membres de direction du collège
        Alfajiri. Tous les bienfaiteurs et amis du collège Alfajiri peuvent
        aussi proposer leurs réflexions. <br /> - L’ article écrit doit contenir
        : une introduction, un développement et une conclusion, le tout en plus
        ou moins 500 mots. <br /> - Les articles sont écrits en anglais, en
        français ou en latin. <br /> - Les articles sont à saisir et à envoyer à
        l’ adresse : <strong> revuengoma @alfajiri.net </strong> - Les
        contributions sont attendues jusqu’ au 20 Mars 2024. <br /> <br />
        <h3> Notes importantes </h3> <br /> - A travers ce numéro, le comité de
        rédaction et de publications du collège Alfajiri lance aussi un concours
        de composition d’ un hymne pour le collège. <br /> - Par ailleurs, à nos
        fidèles férus lecteurs et toute autre personne désireuse de s’ abonner à
        notre Revue, nous lançons la campagne de recrutement des abonnés d’
        honneur. Prière de nous contacter au + 243 82 797 1580(Whatsapp) ou
        revuengoma @alfajiri.net pour plus de renseignement. <br /> <br />{' '}
        <br />
      </p>
    ),
  },
}
