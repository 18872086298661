import React from 'react'
import RoiComponent from './RoiComponent'
import {roiInternat} from './roiEcoleTxt'

function RoiInternat() {
  return (
    <div>
      <RoiComponent
        roiTitre={"Règlement de l'Internat"}
        roiArticles={roiInternat}
      />
    </div>
  )
}

export default RoiInternat
