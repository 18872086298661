import React from 'react'
import Back from '../../common/back/Back'
import InscriptionSecondaireCard from './InscriptionSecondaireCard'

const InscriptionSecondaire = () => {
  return (
    <div className='inscriptionMaternelle'>
      <Back title={'INSCRIPTIONS EN 7e CTEB 2023-2024'} />

      <InscriptionSecondaireCard />
    </div>
  )
}

export default InscriptionSecondaire
