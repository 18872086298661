import React from 'react'
import Back from '../../common/back/Back'

import {withStyles, makeStyles} from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
/* import Button from '@mui/material/Button' */

const StyleTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell)

const StyleTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow)

function createData(name, calories, flat, carbs, protein) {
  return {name, calories, flat, carbs, protein}
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
]

const useStyles = makeStyles({
  table: {
    marginTop: '5%',
    marginLeft: '10%',
    width: '70%',
  },
})

function CalendrierSolaireSecond() {
  const classes = useStyles()
  return (
    <div className='roiTable'>
      <Back title={'Calendrier des Activités : Second Semestre'} />
      <div className='reglement'>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label='customised table'>
            <TableHead>
              <TableRow>
                <StyleTableCell colSpan='4' align='center'>
                  3ème Pédiode (49 jours)
                </StyleTableCell>
                <StyleTableCell colSpan='7' align='center'>
                  4ème Période (49 jours)
                </StyleTableCell>
                <StyleTableCell align='center'>Fin de l'année</StyleTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  97
                </StyleTableCell>
                <StyleTableCell align='left'>Lundi</StyleTableCell>
                <StyleTableCell align='left'>2/06/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'darkred'}}
                >
                  Révisions
                </StyleTableCell>
                <StyleTableCell align='left'>144</StyleTableCell>
                <StyleTableCell align='left'>Lundi</StyleTableCell>
                <StyleTableCell align='left'>4/3/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'lightsalmon'}}
                >
                  Vacances de Paques
                </StyleTableCell>
                <StyleTableCell align='left'>194</StyleTableCell>
                <StyleTableCell align='left'>Lundi</StyleTableCell>
                <StyleTableCell align='left'>5/29/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  98
                </StyleTableCell>
                <StyleTableCell align='left'>Mardi</StyleTableCell>
                <StyleTableCell align='left'>2/07/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'darkred'}}
                >
                  Blocus
                </StyleTableCell>
                <StyleTableCell align='left'>145</StyleTableCell>
                <StyleTableCell align='left'>Mardi</StyleTableCell>
                <StyleTableCell align='left'>4/4/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'lightsalmon'}}
                >
                  Vacances de Pâques
                </StyleTableCell>
                <StyleTableCell align='left'>195</StyleTableCell>
                <StyleTableCell align='left'>Mardi</StyleTableCell>
                <StyleTableCell align='left'>5/30/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  99
                </StyleTableCell>
                <StyleTableCell align='left'>Mercredi</StyleTableCell>
                <StyleTableCell align='left'>2/08/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'darkred'}}
                >
                  Examens SEM I
                </StyleTableCell>
                <StyleTableCell align='left'>146</StyleTableCell>
                <StyleTableCell align='left'>Mercredi</StyleTableCell>
                <StyleTableCell align='left'>4/5/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'lightsalmon'}}
                >
                  Vancances de Pâques
                </StyleTableCell>
                <StyleTableCell align='left'>196</StyleTableCell>
                <StyleTableCell align='left'>Mercredi</StyleTableCell>
                <StyleTableCell align='left'>5/31/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  100
                </StyleTableCell>
                <StyleTableCell align='left'>Jeudi</StyleTableCell>
                <StyleTableCell align='left'>2/09/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'darkred'}}
                >
                  Examens SEM I
                </StyleTableCell>
                <StyleTableCell align='left'>147</StyleTableCell>
                <StyleTableCell align='left'>Jeudi</StyleTableCell>
                <StyleTableCell align='left'>4/6/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'lightsalmon'}}
                >
                  Vacances de Pâques
                </StyleTableCell>
                <StyleTableCell align='left'>197</StyleTableCell>
                <StyleTableCell align='left'>Jeudi</StyleTableCell>
                <StyleTableCell align='left'>6/1/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  101
                </StyleTableCell>
                <StyleTableCell align='left'>Vendredi</StyleTableCell>
                <StyleTableCell align='left'>2/10/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'darkred'}}
                >
                  Examens SEM I
                </StyleTableCell>
                <StyleTableCell align='left'>148</StyleTableCell>
                <StyleTableCell align='left'>Vendredi</StyleTableCell>
                <StyleTableCell align='left'>4/7/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'lightsalmon'}}
                >
                  Vacances de Pâques
                </StyleTableCell>
                <StyleTableCell align='left'>198</StyleTableCell>
                <StyleTableCell align='left'>Vendredi</StyleTableCell>
                <StyleTableCell align='left'>6/2/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'darkred'}}
                >
                  Examens SEM II
                </StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  102
                </StyleTableCell>
                <StyleTableCell align='left'>Samedi</StyleTableCell>
                <StyleTableCell align='left'>2/11/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'darkred'}}
                >
                  Examens SEM I
                </StyleTableCell>
                <StyleTableCell align='left'>149</StyleTableCell>
                <StyleTableCell align='left'>Samedi</StyleTableCell>
                <StyleTableCell align='left'>4/8/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'lightsalmon'}}
                >
                  Vacances de Pâques
                </StyleTableCell>
                <StyleTableCell align='left'>199</StyleTableCell>
                <StyleTableCell align='left'>Samedi</StyleTableCell>
                <StyleTableCell align='left'>6/3/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'darkred'}}
                >
                  Examens SEM II
                </StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'></StyleTableCell>
                <StyleTableCell align='left'>Dimanche</StyleTableCell>
                <StyleTableCell align='left'>2/12/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'darkred'}}
                >
                  AGOP
                </StyleTableCell>
                <StyleTableCell align='left'></StyleTableCell>
                <StyleTableCell align='left'>Dimache</StyleTableCell>
                <StyleTableCell align='left'>4/9/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'lightsalmon'}}
                >
                  Pâques
                </StyleTableCell>
                <StyleTableCell align='left'></StyleTableCell>
                <StyleTableCell align='left'>Dimanche</StyleTableCell>
                <StyleTableCell align='left'>6/4/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'darkred'}}
                >
                  Examens SEM II
                </StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  103
                </StyleTableCell>
                <StyleTableCell align='left'>Lundi</StyleTableCell>
                <StyleTableCell align='left'>2/13/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'darkred'}}
                >
                  Examens SEM I
                </StyleTableCell>
                <StyleTableCell align='left'>150</StyleTableCell>
                <StyleTableCell align='left'>Lundi</StyleTableCell>
                <StyleTableCell align='left'>4/10/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'lightsalmon'}}
                >
                  Reprise des cours / Début de la 4e P
                </StyleTableCell>
                <StyleTableCell align='left'>200</StyleTableCell>
                <StyleTableCell align='left'>Lundi</StyleTableCell>
                <StyleTableCell align='left'>6/5/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'darkred'}}
                >
                  Examens SEM II
                </StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  104
                </StyleTableCell>
                <StyleTableCell align='left'>Mardi</StyleTableCell>
                <StyleTableCell align='left'>2/14/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'darkred'}}
                >
                  Examens SEM I
                </StyleTableCell>
                <StyleTableCell align='left'>151</StyleTableCell>
                <StyleTableCell align='left'>Mardi</StyleTableCell>
                <StyleTableCell align='left'>4/10/2023</StyleTableCell>
                <StyleTableCell align='left'>Délibération</StyleTableCell>
                <StyleTableCell align='left'>201</StyleTableCell>
                <StyleTableCell align='left'>Mardi</StyleTableCell>
                <StyleTableCell align='left'>6/6/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'darkred'}}
                >
                  Examens SEM II
                </StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  105
                </StyleTableCell>
                <StyleTableCell align='left'>Mercredi</StyleTableCell>
                <StyleTableCell align='left'>2/15/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'darkred'}}
                >
                  Examens SEM I
                </StyleTableCell>
                <StyleTableCell align='left'>152</StyleTableCell>
                <StyleTableCell align='left'>Mercredi</StyleTableCell>
                <StyleTableCell align='left'>4/11/2023</StyleTableCell>
                <StyleTableCell align='left'>Délibération</StyleTableCell>
                <StyleTableCell align='left'>202</StyleTableCell>
                <StyleTableCell align='left'>Mercredi</StyleTableCell>
                <StyleTableCell align='left'>6/7/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  105
                </StyleTableCell>
                <StyleTableCell align='left'>Jeudi</StyleTableCell>
                <StyleTableCell align='left'>2/16/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'darkred'}}
                >
                  Examens SEM I
                </StyleTableCell>
                <StyleTableCell align='left'>153</StyleTableCell>
                <StyleTableCell align='left'>Jeudi</StyleTableCell>
                <StyleTableCell align='left'>4/13/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'lightsalmon'}}
                >
                  Vacances de Pâques
                </StyleTableCell>
                <StyleTableCell align='left'>203</StyleTableCell>
                <StyleTableCell align='left'>Jeudi</StyleTableCell>
                <StyleTableCell align='left'>6/8/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  106
                </StyleTableCell>
                <StyleTableCell align='left'>Vendredi</StyleTableCell>
                <StyleTableCell align='left'>2/17/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'lightsalmon'}}
                >
                  Détente
                </StyleTableCell>
                <StyleTableCell align='left'>154</StyleTableCell>
                <StyleTableCell align='left'>Vendredi</StyleTableCell>
                <StyleTableCell align='left'>4/14/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'lightsalmon'}}
                >
                  Vacances de Pâques
                </StyleTableCell>
                <StyleTableCell align='left'>204</StyleTableCell>
                <StyleTableCell align='left'>Vendredi</StyleTableCell>
                <StyleTableCell align='left'>6/9/2023</StyleTableCell>
                <StyleTableCell align='left'>
                  Deuxième délibération
                </StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  107
                </StyleTableCell>
                <StyleTableCell align='left'>Samedi</StyleTableCell>
                <StyleTableCell align='left'>2/18/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'lightsalmon'}}
                >
                  Détente
                </StyleTableCell>
                <StyleTableCell align='left'>155</StyleTableCell>
                <StyleTableCell align='left'>Samedi</StyleTableCell>
                <StyleTableCell align='left'>4/15/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'lightsalmon'}}
                >
                  Vacances de Pâques
                </StyleTableCell>
                <StyleTableCell align='left'>205</StyleTableCell>
                <StyleTableCell align='left'>Samedi</StyleTableCell>
                <StyleTableCell align='left'>6/10/2023</StyleTableCell>
                <StyleTableCell align='left'>Examens</StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'></StyleTableCell>
                <StyleTableCell align='left'>Dimanche</StyleTableCell>
                <StyleTableCell align='left'>2/19/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'lightsalmon'}}
                >
                  Préliminaires
                </StyleTableCell>
                <StyleTableCell align='left'></StyleTableCell>
                <StyleTableCell align='left'>Dimanche</StyleTableCell>
                <StyleTableCell align='left'>4/16/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'black'}}
                ></StyleTableCell>
                <StyleTableCell align='left'></StyleTableCell>
                <StyleTableCell align='left'>Dimanche</StyleTableCell>
                <StyleTableCell align='left'>6/11/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'black'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  109
                </StyleTableCell>
                <StyleTableCell align='left'>Lundi</StyleTableCell>
                <StyleTableCell align='left'>2/19/2023</StyleTableCell>
                <StyleTableCell align='left'>
                  Reprise des cours / Début de la 3P
                </StyleTableCell>
                <StyleTableCell align='left'>156</StyleTableCell>
                <StyleTableCell align='left'>Lundi</StyleTableCell>
                <StyleTableCell align='left'>4/20/2023</StyleTableCell>
                <StyleTableCell align='left'>Reprise des cours</StyleTableCell>
                <StyleTableCell align='left'>206</StyleTableCell>
                <StyleTableCell align='left'>Lundi</StyleTableCell>
                <StyleTableCell align='left'>6/12/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'darkred'}}
                >
                  Session ordinaire
                </StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  110
                </StyleTableCell>
                <StyleTableCell align='left'>Mardi</StyleTableCell>
                <StyleTableCell align='left'>2/21/2023</StyleTableCell>
                <StyleTableCell align='left'>Délibérations</StyleTableCell>
                <StyleTableCell align='left'>157</StyleTableCell>
                <StyleTableCell align='left'>Mardi</StyleTableCell>
                <StyleTableCell align='left'>4/18/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>207</StyleTableCell>
                <StyleTableCell align='left'>Mardi</StyleTableCell>
                <StyleTableCell align='left'>6/13/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  111
                </StyleTableCell>
                <StyleTableCell align='left'>Mercredi</StyleTableCell>
                <StyleTableCell align='left'>2/22/2023</StyleTableCell>
                <StyleTableCell align='left'>Délibérations</StyleTableCell>
                <StyleTableCell align='left'>158</StyleTableCell>
                <StyleTableCell align='left'>Mercredi</StyleTableCell>
                <StyleTableCell align='left'>4/19/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>208</StyleTableCell>
                <StyleTableCell align='left'>Mercredi</StyleTableCell>
                <StyleTableCell align='left'>6/14/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  112
                </StyleTableCell>
                <StyleTableCell align='left'>Jeudi</StyleTableCell>
                <StyleTableCell align='left'>2/23/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>159</StyleTableCell>
                <StyleTableCell align='left'>Jeudi</StyleTableCell>
                <StyleTableCell align='left'>4/20/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'lightsalmon'}}
                >
                  Fête Enseignement & Collège
                </StyleTableCell>
                <StyleTableCell align='left'>209</StyleTableCell>
                <StyleTableCell align='left'>Jeudi</StyleTableCell>
                <StyleTableCell align='left'>6/15/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'darkred'}}
                >
                  TENASOSP
                </StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  113
                </StyleTableCell>
                <StyleTableCell align='left'>Vendredi</StyleTableCell>
                <StyleTableCell align='left'>2/24/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>160</StyleTableCell>
                <StyleTableCell align='left'>Vendredi</StyleTableCell>
                <StyleTableCell align='left'>4/21/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'lightsalmon'}}
                >
                  Fête du travail
                </StyleTableCell>
                <StyleTableCell align='left'>210</StyleTableCell>
                <StyleTableCell align='left'>Vendredi</StyleTableCell>
                <StyleTableCell align='left'>6/16/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'darkred'}}
                >
                  TENASOSP
                </StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  114
                </StyleTableCell>
                <StyleTableCell align='left'>Samedi</StyleTableCell>
                <StyleTableCell align='left'>2/25/2023</StyleTableCell>
                <StyleTableCell align='left'>
                  Communication des points
                </StyleTableCell>
                <StyleTableCell align='left'>161</StyleTableCell>
                <StyleTableCell align='left'>Samedi</StyleTableCell>
                <StyleTableCell align='left'>4/22/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>211</StyleTableCell>
                <StyleTableCell align='left'>Samedi</StyleTableCell>
                <StyleTableCell align='left'>6/17/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'></StyleTableCell>
                <StyleTableCell align='left'>Dimanche</StyleTableCell>
                <StyleTableCell align='left'>2/26/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'black'}}
                ></StyleTableCell>
                <StyleTableCell align='left'></StyleTableCell>
                <StyleTableCell align='left'>Dimanche</StyleTableCell>
                <StyleTableCell align='left'>4/23/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'black'}}
                ></StyleTableCell>
                <StyleTableCell align='left'></StyleTableCell>
                <StyleTableCell align='left'>Dimanche</StyleTableCell>
                <StyleTableCell align='left'>6/18/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'black'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  115
                </StyleTableCell>
                <StyleTableCell align='left'>Lundi</StyleTableCell>
                <StyleTableCell align='left'>2/27/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>162</StyleTableCell>
                <StyleTableCell align='left'>Lundi</StyleTableCell>
                <StyleTableCell align='left'>4/24/2023</StyleTableCell>
                <StyleTableCell align='left'>Dissertation</StyleTableCell>
                <StyleTableCell align='left'>212</StyleTableCell>
                <StyleTableCell align='left'>Lundi</StyleTableCell>
                <StyleTableCell align='left'>6/19/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  116
                </StyleTableCell>
                <StyleTableCell align='left'>Mardi</StyleTableCell>
                <StyleTableCell align='left'>2/28/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>163</StyleTableCell>
                <StyleTableCell align='left'>Mardi</StyleTableCell>
                <StyleTableCell align='left'>4/25/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>213</StyleTableCell>
                <StyleTableCell align='left'>Mardi</StyleTableCell>
                <StyleTableCell align='left'>6/20/2023</StyleTableCell>
                <StyleTableCell align='left'>1ère Délibération</StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  117
                </StyleTableCell>
                <StyleTableCell align='left'>Mercredi</StyleTableCell>
                <StyleTableCell align='left'>3/1/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>164</StyleTableCell>
                <StyleTableCell align='left'>Mercredi</StyleTableCell>
                <StyleTableCell align='left'>4/26/2023</StyleTableCell>
                <StyleTableCell align='left'>Epreuve Orale</StyleTableCell>
                <StyleTableCell align='left'>214</StyleTableCell>
                <StyleTableCell align='left'>Mercredi</StyleTableCell>
                <StyleTableCell align='left'>6/21/2023</StyleTableCell>
                <StyleTableCell align='left'>1ère Délibération</StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  118
                </StyleTableCell>
                <StyleTableCell align='left'>Jeudi</StyleTableCell>
                <StyleTableCell align='left'>3/2/2023</StyleTableCell>
                <StyleTableCell align='left'>Retaire 4B</StyleTableCell>
                <StyleTableCell align='left'>165</StyleTableCell>
                <StyleTableCell align='left'>Jeudi</StyleTableCell>
                <StyleTableCell align='left'>4/27/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>215</StyleTableCell>
                <StyleTableCell align='left'>Jeudi</StyleTableCell>
                <StyleTableCell align='left'>6/22/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  119
                </StyleTableCell>
                <StyleTableCell align='left'>Vendredi</StyleTableCell>
                <StyleTableCell align='left'>2/3/2023</StyleTableCell>
                <StyleTableCell align='left'>Retaire 4B</StyleTableCell>
                <StyleTableCell align='left'>166</StyleTableCell>
                <StyleTableCell align='left'>Vendredi</StyleTableCell>
                <StyleTableCell align='left'>4/28/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>216</StyleTableCell>
                <StyleTableCell align='left'>Vendredi</StyleTableCell>
                <StyleTableCell align='left'>6/23/2023</StyleTableCell>
                <StyleTableCell align='left'>2ème Délibération</StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  120
                </StyleTableCell>
                <StyleTableCell align='left'>Samedi</StyleTableCell>
                <StyleTableCell align='left'>3/4/2023</StyleTableCell>
                <StyleTableCell align='left'>Retaire 4B</StyleTableCell>
                <StyleTableCell align='left'>167</StyleTableCell>
                <StyleTableCell align='left'>Samedi</StyleTableCell>
                <StyleTableCell align='left'>4/29/2023</StyleTableCell>
                <StyleTableCell align='left'>Epreuve Orale</StyleTableCell>
                <StyleTableCell align='left'>217</StyleTableCell>
                <StyleTableCell align='left'>Samedi</StyleTableCell>
                <StyleTableCell align='left'>6/24/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'></StyleTableCell>
                <StyleTableCell align='left'>Dimanche</StyleTableCell>
                <StyleTableCell align='left'>3/5/2023</StyleTableCell>
                <StyleTableCell align='left'>Retaire 4B</StyleTableCell>
                <StyleTableCell align='left'></StyleTableCell>
                <StyleTableCell align='left'>Dimanche</StyleTableCell>
                <StyleTableCell align='left'>4/30/2023</StyleTableCell>
                <StyleTableCell align='left'>JEN</StyleTableCell>
                <StyleTableCell align='left'></StyleTableCell>
                <StyleTableCell align='left'>Dimanche</StyleTableCell>
                <StyleTableCell align='left'>6/25/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'black'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  121
                </StyleTableCell>
                <StyleTableCell align='left'>Lundi</StyleTableCell>
                <StyleTableCell align='left'>3/6/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>168</StyleTableCell>
                <StyleTableCell align='left'>Lundi</StyleTableCell>
                <StyleTableCell align='left'>5/1/2023</StyleTableCell>
                <StyleTableCell align='left'>Fête du Travail</StyleTableCell>
                <StyleTableCell align='left'>218</StyleTableCell>
                <StyleTableCell align='left'>Lundi</StyleTableCell>
                <StyleTableCell align='left'>6/26/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'darkred'}}
                >
                  Exetat
                </StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  122
                </StyleTableCell>
                <StyleTableCell align='left'>Mardi</StyleTableCell>
                <StyleTableCell align='left'>3/7/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>169</StyleTableCell>
                <StyleTableCell align='left'>Mardi</StyleTableCell>
                <StyleTableCell align='left'>5/2/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>219</StyleTableCell>
                <StyleTableCell align='left'>Mardi</StyleTableCell>
                <StyleTableCell align='left'>6/27/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'darkred'}}
                >
                  Exetat
                </StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  123
                </StyleTableCell>
                <StyleTableCell align='left'>Mercredi</StyleTableCell>
                <StyleTableCell align='left'>3/8/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>170</StyleTableCell>
                <StyleTableCell align='left'>Mercredi</StyleTableCell>
                <StyleTableCell align='left'>5/3/2023</StyleTableCell>
                <StyleTableCell align='left'>Fin P3</StyleTableCell>
                <StyleTableCell align='left'>220</StyleTableCell>
                <StyleTableCell align='left'>Mercredi</StyleTableCell>
                <StyleTableCell align='left'>6/28/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'darkred'}}
                >
                  Exetat
                </StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  124
                </StyleTableCell>
                <StyleTableCell align='left'>Jeudi</StyleTableCell>
                <StyleTableCell align='left'>3/9/2023</StyleTableCell>
                <StyleTableCell align='left'>Retraite 4L</StyleTableCell>
                <StyleTableCell align='left'>171</StyleTableCell>
                <StyleTableCell align='left'>Jeudi</StyleTableCell>
                <StyleTableCell align='left'>5/4/2023</StyleTableCell>
                <StyleTableCell align='left'>Début P4</StyleTableCell>
                <StyleTableCell align='left'>221</StyleTableCell>
                <StyleTableCell align='left'>Jeudi</StyleTableCell>
                <StyleTableCell align='left'>6/29/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'darkred'}}
                >
                  Exetat
                </StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  125
                </StyleTableCell>
                <StyleTableCell align='left'>Vendredi</StyleTableCell>
                <StyleTableCell align='left'>3/10/2023</StyleTableCell>
                <StyleTableCell align='left'>Retraite 4L</StyleTableCell>
                <StyleTableCell align='left'>172</StyleTableCell>
                <StyleTableCell align='left'>Vendredi</StyleTableCell>
                <StyleTableCell align='left'>5/5/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>222</StyleTableCell>
                <StyleTableCell align='left'>Vendredi</StyleTableCell>
                <StyleTableCell align='left'>6/30/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'lightsalmon'}}
                >
                  Fête de l'indépendance
                </StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  126
                </StyleTableCell>
                <StyleTableCell align='left'>Samedi</StyleTableCell>
                <StyleTableCell align='left'>3/11/2023</StyleTableCell>
                <StyleTableCell align='left'>Retraite 4L</StyleTableCell>
                <StyleTableCell align='left'>173</StyleTableCell>
                <StyleTableCell align='left'>Samedi</StyleTableCell>
                <StyleTableCell align='left'>5/6/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>223</StyleTableCell>
                <StyleTableCell align='left'>Samedi</StyleTableCell>
                <StyleTableCell align='left'>7/1/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'lightsalmon'}}
                >
                  Clôture année / Proclamation
                </StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'></StyleTableCell>
                <StyleTableCell align='left'>Dimanche</StyleTableCell>
                <StyleTableCell align='left' style={{backgroundColor: 'black'}}>
                  3/12/2023
                </StyleTableCell>
                <StyleTableCell align='left'>Retraite 4L</StyleTableCell>
                <StyleTableCell align='left'></StyleTableCell>
                <StyleTableCell align='left'>Dimanche</StyleTableCell>
                <StyleTableCell align='left'>5/7/2023</StyleTableCell>
                <StyleTableCell align='left'>
                  Journée de la libération
                </StyleTableCell>
                <StyleTableCell align='left'></StyleTableCell>
                <StyleTableCell align='left'>Dimanche</StyleTableCell>
                <StyleTableCell align='left'>7/2/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'black'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  127
                </StyleTableCell>
                <StyleTableCell align='left'>Lundi</StyleTableCell>
                <StyleTableCell align='left'>3/13/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>174</StyleTableCell>
                <StyleTableCell align='left'>Lundi</StyleTableCell>
                <StyleTableCell align='left'>5/8/2023</StyleTableCell>
                <StyleTableCell align='left'>Dissertation</StyleTableCell>
                <StyleTableCell align='left'></StyleTableCell>
                <StyleTableCell align='left'>Lundi</StyleTableCell>
                <StyleTableCell align='left'>7/3/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  128
                </StyleTableCell>
                <StyleTableCell align='left'>Mardi</StyleTableCell>
                <StyleTableCell align='left'>3/14/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>175</StyleTableCell>
                <StyleTableCell align='left'>Mardi</StyleTableCell>
                <StyleTableCell align='left'>5/9/2023</StyleTableCell>
                <StyleTableCell align='left'>
                  Epreuve traditionnelle/Technique
                </StyleTableCell>
                <StyleTableCell align='left'></StyleTableCell>
                <StyleTableCell align='left'>Mardi</StyleTableCell>
                <StyleTableCell align='left'>7/4/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  129
                </StyleTableCell>
                <StyleTableCell align='left'>Mercredi</StyleTableCell>
                <StyleTableCell align='left'>3/15/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>176</StyleTableCell>
                <StyleTableCell align='left'>Mercredi</StyleTableCell>
                <StyleTableCell align='left'>5/10/2023</StyleTableCell>
                <StyleTableCell align='left'>
                  Délibération/Orale Français
                </StyleTableCell>
                <StyleTableCell align='left'></StyleTableCell>
                <StyleTableCell align='left'>Mercredi</StyleTableCell>
                <StyleTableCell align='left'>7/5/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  130
                </StyleTableCell>
                <StyleTableCell align='left'>Jeudi</StyleTableCell>
                <StyleTableCell align='left'>3/16/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>177</StyleTableCell>
                <StyleTableCell align='left'>Jeudi</StyleTableCell>
                <StyleTableCell align='left'>5/11/2023</StyleTableCell>
                <StyleTableCell align='left'>
                  Délibération/Orale Français
                </StyleTableCell>
                <StyleTableCell align='left'></StyleTableCell>
                <StyleTableCell align='left'>Jeudi</StyleTableCell>
                <StyleTableCell align='left'>7/6/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  131
                </StyleTableCell>
                <StyleTableCell align='left'>Vendredi</StyleTableCell>
                <StyleTableCell align='left'>3/17/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>178</StyleTableCell>
                <StyleTableCell align='left'>Vendredi</StyleTableCell>
                <StyleTableCell align='left'>5/12/2023</StyleTableCell>
                <StyleTableCell align='left'>Orale Français</StyleTableCell>
                <StyleTableCell align='left'></StyleTableCell>
                <StyleTableCell align='left'>Vendredi</StyleTableCell>
                <StyleTableCell align='left'>7/7/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  132
                </StyleTableCell>
                <StyleTableCell align='left'>Samedi</StyleTableCell>
                <StyleTableCell align='left'>3/18/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>179</StyleTableCell>
                <StyleTableCell align='left'>Samedi</StyleTableCell>
                <StyleTableCell align='left'>5/13/2023</StyleTableCell>
                <StyleTableCell align='left'>
                  Communication P3/Orale Français
                </StyleTableCell>
                <StyleTableCell align='left'></StyleTableCell>
                <StyleTableCell align='left'>Samedi</StyleTableCell>
                <StyleTableCell align='left'>7/8/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'></StyleTableCell>
                <StyleTableCell align='left'>Dimanche</StyleTableCell>
                <StyleTableCell align='left'>3/19/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'black'}}
                ></StyleTableCell>
                <StyleTableCell align='left'></StyleTableCell>
                <StyleTableCell align='left'>Dimanche</StyleTableCell>
                <StyleTableCell align='left'>5/14/2023</StyleTableCell>
                <StyleTableCell align='left'>AGOP</StyleTableCell>
                <StyleTableCell align='left'></StyleTableCell>
                <StyleTableCell align='left'>Dimanche</StyleTableCell>
                <StyleTableCell align='left'>7/9/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'black'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  133
                </StyleTableCell>
                <StyleTableCell align='left'>Lundi</StyleTableCell>
                <StyleTableCell align='left'>3/20/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>180</StyleTableCell>
                <StyleTableCell align='left'>Lundi</StyleTableCell>
                <StyleTableCell align='left'>5/15/2023</StyleTableCell>
                <StyleTableCell align='left'>
                  Pratique Professionnelle
                </StyleTableCell>
                <StyleTableCell align='left'></StyleTableCell>
                <StyleTableCell align='left'>Lundi</StyleTableCell>
                <StyleTableCell align='left'>7/10/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  134
                </StyleTableCell>
                <StyleTableCell align='left'>Mardi</StyleTableCell>
                <StyleTableCell align='left'>3/21/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>181</StyleTableCell>
                <StyleTableCell align='left'>Mardi</StyleTableCell>
                <StyleTableCell align='left'>5/16/2023</StyleTableCell>
                <StyleTableCell align='left'>
                  Pratique Professionnelle
                </StyleTableCell>
                <StyleTableCell align='left'></StyleTableCell>
                <StyleTableCell align='left'>Mardi</StyleTableCell>
                <StyleTableCell align='left'>7/11/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  135
                </StyleTableCell>
                <StyleTableCell align='left'>Mercredi</StyleTableCell>
                <StyleTableCell align='left'>3/22/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>182</StyleTableCell>
                <StyleTableCell align='left'>Mercredi</StyleTableCell>
                <StyleTableCell align='left'>5/17/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'lightsalmon'}}
                >
                  FARDC
                </StyleTableCell>
                <StyleTableCell align='left'></StyleTableCell>
                <StyleTableCell align='left'>Mercredi</StyleTableCell>
                <StyleTableCell align='left'>7/12/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  136
                </StyleTableCell>
                <StyleTableCell align='left'>Jeudi</StyleTableCell>
                <StyleTableCell align='left'>3/23/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>183</StyleTableCell>
                <StyleTableCell align='left'>Jeudi</StyleTableCell>
                <StyleTableCell align='left'>5/18/2023</StyleTableCell>
                <StyleTableCell align='left'>
                  Pratique Professionnelle
                </StyleTableCell>
                <StyleTableCell align='left'></StyleTableCell>
                <StyleTableCell align='left'>Jeudi</StyleTableCell>
                <StyleTableCell align='left'>7/13/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  137
                </StyleTableCell>
                <StyleTableCell align='left'>Vendredi</StyleTableCell>
                <StyleTableCell align='left'>3/24/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>184</StyleTableCell>
                <StyleTableCell align='left'>Vendredi</StyleTableCell>
                <StyleTableCell align='left'>5/19/2023</StyleTableCell>
                <StyleTableCell align='left'>
                  Pratique Professionnelle
                </StyleTableCell>
                <StyleTableCell align='left'></StyleTableCell>
                <StyleTableCell align='left'>Vendredi</StyleTableCell>
                <StyleTableCell align='left'>7/14/2023</StyleTableCell>
                <StyleTableCell align='left' style={{backgroundColor: 'gray'}}>
                  Fermeture des bureaux
                </StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  138
                </StyleTableCell>
                <StyleTableCell align='left'>Samedi</StyleTableCell>
                <StyleTableCell align='left'>3/25/2023</StyleTableCell>
                <StyleTableCell align='left'>Fin P3</StyleTableCell>
                <StyleTableCell align='left'>185</StyleTableCell>
                <StyleTableCell align='left'>Samedi</StyleTableCell>
                <StyleTableCell align='left'>5/20/2023</StyleTableCell>
                <StyleTableCell align='left'>
                  Pratique Professionnelle
                </StyleTableCell>
                <StyleTableCell align='left'></StyleTableCell>
                <StyleTableCell align='left'>Samedi</StyleTableCell>
                <StyleTableCell align='left'>7/15/2023</StyleTableCell>
                <StyleTableCell align='left' style={{backgroundColor: 'gray'}}>
                  XXXXXXXXXX
                </StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'></StyleTableCell>
                <StyleTableCell align='left'>Dimanche</StyleTableCell>
                <StyleTableCell align='left'>3/26/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'black'}}
                ></StyleTableCell>
                <StyleTableCell align='left'></StyleTableCell>
                <StyleTableCell align='left'>Dimanche</StyleTableCell>
                <StyleTableCell align='left'>5/21/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'black'}}
                ></StyleTableCell>
                <StyleTableCell align='left'></StyleTableCell>
                <StyleTableCell align='left'>Dimanche</StyleTableCell>
                <StyleTableCell align='left'>7/16/2023</StyleTableCell>
                <StyleTableCell align='left' style={{backgroundColor: 'black'}}>
                  XXXXXXXXXX
                </StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  139
                </StyleTableCell>
                <StyleTableCell align='left'>Lundi</StyleTableCell>
                <StyleTableCell align='left'>3/27/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'lightsalmon'}}
                >
                  Vacances
                </StyleTableCell>
                <StyleTableCell align='left'>186</StyleTableCell>
                <StyleTableCell align='left'>Lundi</StyleTableCell>
                <StyleTableCell align='left'>5/22/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'darkred'}}
                >
                  Examen II
                </StyleTableCell>
                <StyleTableCell align='left'></StyleTableCell>
                <StyleTableCell align='left'>Lundi</StyleTableCell>
                <StyleTableCell align='left'>7/31/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'lightsalmon'}}
                >
                  St Ignace de Loyola
                </StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  140
                </StyleTableCell>
                <StyleTableCell align='left'>Mardi</StyleTableCell>
                <StyleTableCell align='left'>3/28/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'lightsalmon'}}
                >
                  Vacances
                </StyleTableCell>
                <StyleTableCell align='left'>187</StyleTableCell>
                <StyleTableCell align='left'>Mardi</StyleTableCell>
                <StyleTableCell align='left'>5/23/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'darkred'}}
                >
                  Examen II
                </StyleTableCell>
                <StyleTableCell align='left'></StyleTableCell>
                <StyleTableCell align='left'>Mardi</StyleTableCell>
                <StyleTableCell align='left'>8/1/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'lightsalmon'}}
                >
                  Fête des Parents
                </StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  141
                </StyleTableCell>
                <StyleTableCell align='left'>Mercredi</StyleTableCell>
                <StyleTableCell align='left'>3/29/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'lightsalmon'}}
                >
                  Vacances
                </StyleTableCell>
                <StyleTableCell align='left'>188</StyleTableCell>
                <StyleTableCell align='left'>Mercredi</StyleTableCell>
                <StyleTableCell align='left'>5/24/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'darkred'}}
                >
                  Examen II
                </StyleTableCell>
                <StyleTableCell align='left'></StyleTableCell>
                <StyleTableCell align='left'>Mercredi</StyleTableCell>
                <StyleTableCell align='left'>8/2/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  142
                </StyleTableCell>
                <StyleTableCell align='left'>Jeudi</StyleTableCell>
                <StyleTableCell align='left'>3/30/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'lightsalmon'}}
                >
                  Vacances
                </StyleTableCell>
                <StyleTableCell align='left'>189</StyleTableCell>
                <StyleTableCell align='left'>Jeudi</StyleTableCell>
                <StyleTableCell align='left'>5/25/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'darkred'}}
                >
                  Examen II
                </StyleTableCell>
                <StyleTableCell align='left'></StyleTableCell>
                <StyleTableCell align='left'>Jeudi</StyleTableCell>
                <StyleTableCell align='left'></StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  143
                </StyleTableCell>
                <StyleTableCell align='left'>Vendredi</StyleTableCell>
                <StyleTableCell align='left'>3/31/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'lightsalmon'}}
                >
                  Vacances
                </StyleTableCell>
                <StyleTableCell align='left'>190</StyleTableCell>
                <StyleTableCell align='left'>Vendredi</StyleTableCell>
                <StyleTableCell align='left'>5/26/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'darkred'}}
                >
                  Examen II
                </StyleTableCell>
                <StyleTableCell align='left'></StyleTableCell>
                <StyleTableCell align='left'>Vendredi</StyleTableCell>
                <StyleTableCell align='left'>9/2/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'>
                  144
                </StyleTableCell>
                <StyleTableCell align='left'>Samedi</StyleTableCell>
                <StyleTableCell align='left'>4/1/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'lightsalmon'}}
                >
                  Vacances
                </StyleTableCell>
                <StyleTableCell align='left'>191</StyleTableCell>
                <StyleTableCell align='left'>Samedi</StyleTableCell>
                <StyleTableCell align='left'>5/27/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'darkred'}}
                >
                  Examen II
                </StyleTableCell>
                <StyleTableCell align='left'></StyleTableCell>
                <StyleTableCell align='left'>Samedi</StyleTableCell>
                <StyleTableCell align='left'>9/3/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'gray'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell component='th' scope='row'></StyleTableCell>
                <StyleTableCell align='left'>Dimanche</StyleTableCell>
                <StyleTableCell align='left'>4/2/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'lightsalmon'}}
                >
                  Rameaux
                </StyleTableCell>
                <StyleTableCell align='left'></StyleTableCell>
                <StyleTableCell align='left'>Dimanche</StyleTableCell>
                <StyleTableCell align='left'>5/28/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'darkred'}}
                >
                  Pentecôte
                </StyleTableCell>
                <StyleTableCell align='left'></StyleTableCell>
                <StyleTableCell align='left'>Dimanche</StyleTableCell>
                <StyleTableCell align='left'>9/4/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'black'}}
                ></StyleTableCell>
              </StyleTableRow>
              <StyleTableRow>
                <StyleTableCell
                  component='th'
                  scope='row'
                  style={{backgroundColor: 'lightsalmon'}}
                ></StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'lightsalmon'}}
                ></StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'lightsalmon'}}
                ></StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'lightsalmon'}}
                ></StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'lightsalmon'}}
                ></StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'lightsalmon'}}
                ></StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'lightsalmon'}}
                ></StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'lightsalmon'}}
                ></StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'lightsalmon'}}
                ></StyleTableCell>
                <StyleTableCell align='left'>Lundi</StyleTableCell>
                <StyleTableCell align='left'>9/5/2023</StyleTableCell>
                <StyleTableCell
                  align='left'
                  style={{backgroundColor: 'lightblue'}}
                ></StyleTableCell>
              </StyleTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  )
}

export default CalendrierSolaireSecond
