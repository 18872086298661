import React from 'react'
import Back from '../../common/back/Back'

import {withStyles, makeStyles} from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import {useState} from 'react'

const StyleTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell)

const StyleTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow)

const useStyles = makeStyles({
  table: {
    marginTop: '5%',
    marginLeft: '10%',
    width: '70%',
  },
})

export const ProgammeCours = () => {
  const [appear, setappear] = useState(false)
  const classes = useStyles()
  return (
    <div>
      <Back title={'Programme des cours'} />
      <div className='reglement'>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label='customised table'>
            <TableHead>
              <TableRow>
                <StyleTableCell align='center'>N°</StyleTableCell>
                <StyleTableCell align='center'>Promotion</StyleTableCell>
                <StyleTableCell align='center'>Total Périodique</StyleTableCell>
                <StyleTableCell align='center'></StyleTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <StyleTableCell component='th' scope='row'>
                  1
                </StyleTableCell>
                <StyleTableCell align='left'>
                  1ère - 6ème Primaire
                </StyleTableCell>
                <StyleTableCell align='left'>450</StyleTableCell>
                <StyleTableCell align='left'>
                  {' '}
                  <button
                    className='btn-suite'
                    onClick={() =>
                      appear ? setappear(false) : setappear(true)
                    }
                  >
                    Voir Plus
                  </button>{' '}
                </StyleTableCell>
              </TableRow>
              <TableRow
                style={appear ? {display: 'table-row'} : {display: 'none'}}
              >
                <StyleTableCell colSpan={6}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <StyleTableCell
                          component='th'
                          scope='row'
                        ></StyleTableCell>
                        <StyleTableCell align='center'>Branche</StyleTableCell>
                        <StyleTableCell align='center'>
                          Total Périodique
                        </StyleTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <StyleTableCell>1</StyleTableCell>
                        <StyleTableCell>Religion</StyleTableCell>
                        <StyleTableCell>10</StyleTableCell>
                      </TableRow>
                      <TableRow>
                        <StyleTableCell>2</StyleTableCell>
                        <StyleTableCell>
                          Education Civique et Morale
                        </StyleTableCell>
                        <StyleTableCell>10</StyleTableCell>
                      </TableRow>
                      <TableRow>
                        <StyleTableCell>2</StyleTableCell>
                        <StyleTableCell>Education à la Vie</StyleTableCell>
                        <StyleTableCell>10</StyleTableCell>
                      </TableRow>
                      <TableRow>
                        <StyleTableCell></StyleTableCell>
                        <StyleTableCell>Education à la Vie</StyleTableCell>
                        <StyleTableCell>10</StyleTableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </StyleTableCell>
              </TableRow>
            </TableBody>
            <TableBody>
              <TableRow>
                <StyleTableCell component='th' scope='row'>
                  2
                </StyleTableCell>
                <StyleTableCell align='left'>7ème CTEB</StyleTableCell>
                <StyleTableCell align='left'>400</StyleTableCell>
                <StyleTableCell align='left'>
                  {' '}
                  <button className='btn-suite'>Voir Plus</button>{' '}
                </StyleTableCell>
              </TableRow>
              <TableRow
                style={appear ? {display: 'table-row'} : {display: 'none'}}
              >
                <StyleTableCell colSpan={6}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <StyleTableCell
                          component='th'
                          scope='row'
                        ></StyleTableCell>
                        <StyleTableCell align='center'>Branche</StyleTableCell>

                        <StyleTableCell align='center'>
                          Total Périodique
                        </StyleTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <StyleTableCell>1</StyleTableCell>
                        <StyleTableCell>Algèbre</StyleTableCell>

                        <StyleTableCell>40</StyleTableCell>
                      </TableRow>
                      <TableRow>
                        <StyleTableCell>2</StyleTableCell>
                        <StyleTableCell>Arithmétique</StyleTableCell>

                        <StyleTableCell>10</StyleTableCell>
                      </TableRow>
                      <TableRow>
                        <StyleTableCell>3</StyleTableCell>
                        <StyleTableCell>Géométrie</StyleTableCell>
                        <StyleTableCell>20</StyleTableCell>
                      </TableRow>
                      <TableRow>
                        <StyleTableCell>4</StyleTableCell>
                        <StyleTableCell>Statisitque</StyleTableCell>
                        <StyleTableCell>40</StyleTableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </StyleTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  )
}

export default ProgammeCours
