import React from 'react'
import './inscriptions.css'
import {
  FaBaby,
  FaBuilding,
  FaChild,
  FaPersonBooth,
  FaRunning,
} from 'react-icons/fa'
import AWrapper from '../about/AWrapper'
import CardItem from '../common/card/CardItem'
import {motion} from 'framer-motion'
import {Link} from 'react-router-dom'

function InscriptionsCard() {
  return (
    <>
      <motion.section
        className='aboutHome inscriptionBlock'
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        exit={{opacity: 0}}
      >
        <div className='roiCard droit row'>
          <div className='items roiItems inscriprtionsItems'>
            <div className='item flexSB roiItem'>
              <Link to='/inscriptionMaternelle'>
                <CardItem
                  itemIcon={<FaBaby className='iconReact' />}
                  itemTitle='Ecole maternelle'
                  itemText='Inscriptions en deuxième maternelle pour les enfants agés de quatre ans.'
                />
              </Link>
            </div>
            <div className='item flexSB roiItem'>
              <Link to='/inscriptionPrimaire'>
                <CardItem
                  itemIcon={<FaChild className='iconReact' />}
                  itemTitle='Ecoles Primaires'
                  itemText='Inscription en première primaire pour les enfants âgés de six ans ...'
                />
              </Link>
            </div>
            <div className='item flexSB roiItem'>
              <Link to={'/inscriptionSecondaire'}>
                <CardItem
                  itemIcon={<FaPersonBooth className='iconReact' />}
                  itemTitle='Ecole Secondaire'
                  itemText="Inscriptions à l'école secondaire pour les enfants âgés de douze ans ..."
                />
              </Link>
            </div>
            <div className='item flexSB roiItem'>
              <CardItem
                itemIcon={<FaRunning className='iconReact' />}
                itemTitle='C.S.P.'
                itemText='Inscription au Centre de Spécialisation Professionnelle ...'
              />
            </div>
            <div className='item flexSB roiItem'>
              <CardItem
                itemIcon={<FaBuilding className='iconReact' />}
                itemTitle='Internat'
                itemText="Devenez interne au Collège pour jouir du cadre éducatif et formationnel de l'internat..."
              />
            </div>
          </div>
        </div>
      </motion.section>
    </>
  )
}

export default InscriptionsCard
