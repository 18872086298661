import React from 'react'
import primaire from './../../../autresImages/Second/eleve9.jpg'

const InscriptionPrimaireCard = () => {
  return (
    <>
      <div className='inscriptionPrimaire'>
        <div className='inscriptionPrimairePhoto'>
          <img src={primaire} alt='Photo Elèves école primaire' />
        </div>
        <div className='inscriptionPrimaireInfo'>
          <h3>Note :</h3>
          <p>
            Il n'y a pas d'inscription au primaire car nous n'avons pas de place
            disponible.
          </p>
        </div>
      </div>
    </>
  )
}

export default InscriptionPrimaireCard
