import React from 'react'
import maternelle from './../../../autresImages/Second/maternelle.jpg'
import maternelle3 from './../../../autresImages/Second/maternelle3.jpg'

const InscriptionMaternelleCard = () => {
  return (
    <>
      <div className='container-inscriptionMaternelle'>
        <div className='title'>
          <span>Rappel : </span> <br /> Il nous est impossible de donner une
          suite favorable à toutes les demandes
        </div>
        <div className='details'>
          <div className='detailsPicture'>
            <img src={maternelle3} alt='Image école maternelle' />
          </div>
          <div className='detailsInfo'>
            <table className='detailsInfoTable'>
              <tbody>
                <tr>
                  <td>Places disponibles :</td>
                  <td>140</td>
                </tr>
                <tr>
                  <td>Age requis : </td>
                  <td>
                    4 ans (candidat(e)s né(e)s entre le 1er/06/2019 et le
                    31/12/2019)
                  </td>
                </tr>
                <tr>
                  <td>Education : </td>
                  <td>Chrétienne catholique</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className='procedure-inscriptionMaternelle'>
          <table className='procedure-inscription'>
            <thead>
              <tr>
                <th>N°</th>
                <th>Activité</th>
                <th>Lieu</th>
                <th>Date</th>
                <th>Heure</th>
                <th>Observation</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Retrait des fiches</td>
                <td>Direction de l'école maternelle</td>
                <td>Du 1er au 03 juin 2023</td>
                <td>De 13h00 à 16h30</td>
                <td></td>
              </tr>
              <tr>
                <td>2</td>
                <td>Dépots des fiches</td>
                <td>Direction de l'école maternelle</td>
                <td>Du 2 au 05 juin 2023</td>
                <td>De 13h00 à 16h30</td>
                <td>
                  Veuillez attacher une copie de l'acte de naissance et de la
                  carte de baptême ou du certificat de naissance et deux photos
                  passeports. Nous n'accepterons que les Fiches originales.
                </td>
              </tr>
              <tr>
                <td>3</td>
                <td>
                  Affichage de la liste des noms des candidats retenus à
                  l'interview
                </td>
                <td>Direction de l'école maternelle et sur www.alfajiri.net</td>
                <td>Samedi 29 juin 2023</td>
                <td>15h30</td>
                <td></td>
              </tr>
              <tr>
                <td>4</td>
                <td>Interview</td>
                <td>A l'école maternelle</td>
                <td>Du mercredi 05 au vendredi 07 juillet 2023</td>
                <td>A partir de 7h30</td>
                <td>
                  Le retrait du macaron qui donne accès à l'interview est à
                  retirer à la direction de l'école maternelle le lundi 03
                  juillet et le mardi 04 juillet 2023, de 8h30 à 12h30. A cette
                  occasion, la présence physique du candidat(e) à l'interview
                  est obligatoire; la date et l'heure de l'interview vous seront
                  egalement communiquées.
                </td>
              </tr>
              <tr>
                <td>5</td>
                <td>Affichage de la liste provisoire des candidats retenus</td>
                <td>A l'école primaire Alfajiri I et sur www.alfajiri.net</td>
                <td>Samedi 08 juillet 2023</td>
                <td>16h00</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className='inscriptionAtttention'>
          <div className='attentionImage'>
            <img src={maternelle} alt='Photo maternelle' />
          </div>
          <div className='attentionText'>
            <h2>Attention</h2>
            <ul>
              <li>
                1. Le non-respect de l'une des étapes entraine l'exclusion du
                candidat du processus d'inscription
              </li>
              <li>
                2. L'usage d'un faux (manipulation des dates, fausse
                information), le trafic d'influence ou la tentative de
                corruption annulent l'inscription.
              </li>
              <li>
                3. Les formulaires obtenus en deuxième main (ou copie) ne seront
                pas acceptés.
              </li>
            </ul>
          </div>
        </div>
        <div className='signature'>
          <p>Fait à Bukavu, le 3 mai 2023</p>
          <p>La direction</p>
        </div>
      </div>
    </>
  )
}

export default InscriptionMaternelleCard
