import React, {useState} from 'react'
import Title from '../../title/Title'
import './hero.css'
import {motion} from 'framer-motion'

import eleve2 from '../../../../autresImages/Second/eleve2.jpg'
import eleve3 from '../../../../autresImages/Second/eleve3.jpg'
import eleve10 from '../../../../autresImages/Second/eleve10.jpg'
import eleve11 from '../../../../autresImages/Second/eleve11.jpg'
import finaliste2 from '../../../../autresImages/Second/finaliste2.jpg'
import maternelle2 from '../../../../autresImages/Second/maternelle2.jpg'
import spiritualite2 from '../../../../autresImages/Second/spiritualite2.jpg'
import spiritualite11 from '../../../../autresImages/Second/spiritualite11.jpg'
import spiritualite20 from '../../../../autresImages/Second/spiritualite20.jpg'
import sport7 from '../../../../autresImages/Second/sport7.jpg'
import sport8 from '../../../../autresImages/Second/sport8.jpg'
import sport34 from '../../../../autresImages/Second/sport34.jpg'
import theatre3 from '../../../../autresImages/Second/theatre3.jpg'
import sport31 from '../../../../autresImages/Second/sport31.jpg'
import {Link} from 'react-router-dom'

function Hero() {
  const image2 = [
    eleve2,
    eleve3,
    eleve10,
    eleve11,
    finaliste2,
    maternelle2,
    spiritualite11,
    spiritualite2,
    spiritualite20,
    sport31,
    sport34,
    sport7,
    sport8,
    theatre3,
  ]
  const [indexImage, setIndex] = useState(0)
  const bgImageStyle = {
    backgroundImage: `url(${image2[indexImage]})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top',
    backgroundSize: 'cover',
    /* backgroundColor: '#1172b7', */
    backgroundBlendMode: 'multiply',
    /*  height: '50%', */
    width: '100%',
  }
  const compteur = () => {
    if (indexImage === image2.length - 1) {
      setIndex(0)
    } else {
      setIndex(indexImage + 1)
    }
  }
  setTimeout(compteur, 5000)

  const location = (loc) => {
    window.open(loc, '_blank')
    return null
  }
  return (
    <motion.section
      style={bgImageStyle}
      className='hero'
      initial={{width: 0}}
      animate={{width: '100%'}}
      exit={{x: window.innerWidth, transition: {duration: 0.1}}}
      /*  initial={{opacity: 0}}
        animate={{opacity: 1}}
        exit={{opacity: 1}} */
    >
      <div className='container hero-container'>
        <div className='row'>
          <Title subtitle='BIENVENU AU COLLEGE ALFAJIRI' title='Stella duce' />
          <p>L'ecole du magis</p>
          <div className='button'>
            <a
              target='_blank'
              onClick={() => location('https://mail52.lwspanel.com/roundcube/')}
            >
              <button className='primary-btn'>
                CONNEXION <i className='fa fa-long-arrow-alt-right'></i>
              </button>
            </a>
            <button>
              COURS ET PROGRAMME <i className='fa fa-long-arrow-alt-right'></i>
            </button>
          </div>
        </div>
      </div>
    </motion.section>
  )
}

export default Hero
