import React from 'react'
import Back from '../common/back/Back'
import PedagogieCard from './PedagogieCard'

function Pedagogie() {
  return (
    <>
      <Back title={'Organisation des enseignements'} />
      <div className='separator'></div>
      <PedagogieCard />
    </>
  )
}

export default Pedagogie
