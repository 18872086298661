import React from 'react'
import Back from '../common/back/Back'
import './articles.css'

const ArticleTemplate1 = ({title, content, image1, image2}) => {
  return (
    <div style={{width: '100%'}}>
      <Back title={title} />
      <div
        className='article-container'
        style={{display: 'flex', flexDirection: 'column'}}
      >
        <div
          style={{
            backgroundColor: '#900',
            height: '60vh',
            width: '100%',
            display: 'flex',
            justifyContent: 'space-around',
          }}
        ></div>
        <div
          style={{
            backgroundColor: '#fff',
            width: '75%',
            margin: '0 auto',
            padding: '5%',
            position: 'relative',
            bottom: 300,
          }}
        >
          <h2
            style={{
              marginBottom: '5%',
              fontFamily: 'serif',
              fontSize: '50px',
            }}
          >
            {title}
          </h2>
          <p style={{fontFamily: 'serif', textAlign: 'justify'}}>{content}</p>
        </div>
      </div>
    </div>
  )
}

export default ArticleTemplate1
