import React from 'react'
import {useState} from 'react'
import eleve from './../../autresImages/Second/eleve.jpg'
import batiment8 from './../../autresImages/Second/batiment8.jpg'
import spiritualite11 from './../../autresImages/Second/spiritualite11.jpg'
import sport3 from './../../autresImages/Second/sport3.jpg'
import sport20 from './../../autresImages/Second/sport20.jpg'
import theatre3 from './../../autresImages/Second/theatre3.jpg'

const Slider = () => {
  const images = [eleve, spiritualite11, batiment8, sport3, sport20, theatre3]
  const [currentState, setCurrentState] = useState(2)
  const bgImageStyle = {
    backgroundImage: `url(${images[currentState]})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundColor: '#1172b7',
    backgroundBlendMode: 'multiply',
    height: '100%',
    width: '100%',
  }
  const compteur = () => {
    if (currentState === images.length - 1) {
      setCurrentState(0)
    } else {
      setCurrentState(currentState + 1)
    }
  }

  setTimeout(compteur, 5000)
  return (
    <div className='countiner-style'>
      <div style={bgImageStyle} className='slider'></div>
    </div>
  )
}

export default Slider
