import React, {useState} from 'react'
import './header.css'
import logoCollege from './../../../autresImages/logosAlfajiri.png'

function Head() {
  const [click, setClick] = useState(false)
  return (
    <div>
      <section className='head'>
        <div className='container flexSB'>
          <div className='logoContent'>
            <div className='logoCollege'>
              <img
                src={logoCollege}
                alt='Logo Collège'
                height={70}
                width={75}
              />{' '}
            </div>
            <div className='logo'>
              <h1>COLLEGE ALFAJIRI</h1>
              <span style={{color: '#fff'}}>
                Au Collège se forge en toi l'homme / la femme de demain
              </span>
            </div>
          </div>
          <div
            className={click ? 'mobile-social' : 'social'}
            onClick={() => setClick(false)}
          >
            <a href='#' rel=''>
              <i className='fab fa-facebook-f icon'></i>
            </a>
            <a
              target='_blank'
              rel='noreferrer'
              href='https://instagram.com/collegealfajiriofficiel?igshid=NGVhN2U2NjQ0Yg=='
            >
              <i className='fab fa-instagram icon'></i>
            </a>
            <a href='#'>
              <i className='fab fa-twitter icon'></i>
            </a>
            <a href='#'>
              <i className='fab fa-youtube icon'></i>
            </a>
          </div>

          {/* <i
            className='fa fa-dove icon toggleSocial'
            onClick={() => setClick(!click)}
          ></i> */}
        </div>
      </section>
    </div>
  )
}

export default Head
