import React from 'react'
import './mobileHead.style.css'

function MobileHead({setClick, click}) {
  return (
    <div className='mobile_container'>
      <div className='mobile_logo'>
        <div className='mobile_logo_container'></div>
        <div className='mobile_title'>
          <h2>COLLEGE ALFAJIRI</h2>
          <h3>Stella Duce</h3>
        </div>
      </div>
      <div className='mobile_button'>
        <a
          href='#'
          onClick={setClick}
          class='btn btn-primary mobile_button_link mobileBtn'
        >
          <i className={click ? `fa fa-times` : `fa fa-bars`}></i>
        </a>
      </div>
    </div>
  )
}

export default MobileHead
