import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import Head from './Head'
import {motion} from 'framer-motion'
import FirstHead from './FirstHead'
import MobileHead from './MobileHead'

function Header() {
  const [click, setClick] = useState(false)
  return (
    <>
      <motion.div
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        exit={{opacity: 0}}
      >
        {<MobileHead setClick={() => setClick(!click)} />}
        <FirstHead />
        <Head />
        <header className='header'>
          <nav className='flexSB'>
            <div className='menus'>
              <ul
                className={click ? 'mobile-nav' : 'flexSB'}
                onClick={() => setClick(false)}
              >
                <li>
                  {' '}
                  <Link to='/'>Accueil</Link>{' '}
                </li>
                <li>
                  {' '}
                  <Link to='/apropos'>A propos</Link>{' '}
                </li>
                <li>
                  {' '}
                  <Link to='/roi'>R.O.I.</Link>{' '}
                </li>
                <li>
                  {' '}
                  <Link to='/publications'>Pulications</Link>{' '}
                </li>
                <li>
                  {' '}
                  <Link to='/inscriptions'>Inscription</Link>{' '}
                </li>
                <li>
                  {' '}
                  <Link to='/pedagogie'>Pédagogie</Link>{' '}
                </li>
                <li>
                  {' '}
                  <Link to='/parascolaire'>Parascolaire</Link>{' '}
                </li>
              </ul>
              {/* {
              <div className='start'>
                <div className='button'></div>
              </div>
            } */}
            </div>
            <button className='toggleHeader' onClick={() => setClick(!click)}>
              {click ? (
                <i className='fa fa-times'></i>
              ) : (
                <i className='fa fa-bars'></i>
              )}{' '}
            </button>
          </nav>
        </header>
      </motion.div>
    </>
  )
}

export default Header
