import React from 'react'
import {FaSchool, FaGraduationCap, FaWalking} from 'react-icons/fa'

function AWrapper() {
  return (
    <>
      <section className='awrapper'>
        <div className='container grid'>
          <div className='box flex'>
            <div className='im'></div>
            <FaSchool className='iconReact' />
            <div className='text'>
              <h1>1938</h1>
              <h1>Création</h1>
            </div>
          </div>
          <div className='box flex'>
            <div className='im'></div>
            <FaWalking className='iconReact' />
            <div className='text'>
              <h1>84</h1>
              <h1>Personnels</h1>
            </div>
          </div>
          <div className='box flex'>
            <div className='im'></div>
            <FaGraduationCap className='iconReact' />
            <div className='text'>
              <h1>1589</h1>
              <h1>Diplômes</h1>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default AWrapper
