import React from 'react'
import {Link} from 'react-router-dom'
import {FaAddressBook, FaCalendar, FaDiscourse} from 'react-icons/fa'
import AWrapper from '../about/AWrapper'
import CardImage from '../common/card/CardImage'
import CardItem from '../common/card/CardItem'
import Title from '../common/title/Title'

import maternelle3 from './../../autresImages/Second/maternelle3.jpg'

function PedagogieCard() {
  return (
    <>
      <section className='aboutHome'>
        <div className='container flexSB'>
          {<CardImage image={maternelle3} />}
          <div className='right row'>
            <Title
              subtitle={'Nos enseignements'}
              title={'Organisation des cours'}
            />
            <div className='items '>
              <div className='item flexSB'>
                <Link to={'/pedagogie/calendrierscolaire'}>
                  <CardItem
                    itemIcon={<FaCalendar className='iconReact' />}
                    itemTitle='Calendrier Scolaire'
                    itemText='Restez informé de tous ce qui se passe au Collège en consultant le calendrier des activités pédagogiques, parascolaires ...'
                  />
                </Link>
              </div>
            </div>
            <div className='items '>
              <div className='item flexSB'>
                <CardItem
                  itemIcon={<FaAddressBook className='iconReact' />}
                  itemTitle='Horaire des cours'
                  itemText="De l'école primaire à l'école secondaire, consultez l'horaire des différentes classes ..."
                />
              </div>
            </div>
            <div className='items '>
              <div className='item flexSB'>
                <Link to={'/pedagogie/programmecours'}>
                  <CardItem
                    itemIcon={<FaDiscourse className='iconReact' />}
                    itemTitle='Programme des cours'
                    itemText='Voici le programme et la pondération de différentes matières et branches du Secondaire ...'
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <AWrapper />
    </>
  )
}

export default PedagogieCard
