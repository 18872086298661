import React from 'react'
import AproposCard from '../../about/ApropoCard'
import InscriptionsCard from '../../inscriptions/InscriptionsCard'
import Hero from './hero/hero'
import ParascolaireCard from '../../parascolaire/ParascolaireCard'
import GalerieContent from '../../galerie/GalerieContent'
import AWrapper2 from '../../inscriptions/AWrapper2'

function Home() {
  return (
    <>
      <div>
        <Hero />
        <div className='margin'></div>
        <AproposCard />
        <div className='homeSeparator'></div>
        <div className='homeInscription'>
          <InscriptionsCard />
        </div>
        <div className='homeSeparator'></div>
        <ParascolaireCard />
        <AWrapper2 />
        <GalerieContent />
      </div>
    </>
  )
}

export default Home
