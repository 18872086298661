import React from 'react'
import ArticleTemplate1 from '../ArticleTemplate1'
import {argumentaireRevue2024} from '.'
import argumentaire from '../../../documents/argumentaireNgoma2024.pdf'

const ArgumentaireRevue = () => {
  const OpenArgument = () => {
    const openDocument = (document) => {
      window.open(document)
    }
  }
  return (
    <div>
      <ArticleTemplate1
        title={argumentaireRevue2024.argumentaire.titre}
        content={argumentaireRevue2024.argumentaire.content}
      />{' '}
      <div style={{zIndex: 1000}} onClick={() => OpenArgument(argumentaire)}>
        Pour télécharger l 'intégralité de l' argumenntaire cliquez ici{' '}
      </div>{' '}
    </div>
  )
}

export default ArgumentaireRevue
