import batiment8 from './../../autresImages/Second/batiment8.jpg'
import batiment9 from './../../autresImages/Second/batiment9.jpg'
import chorale from './../../autresImages/Second/chorale.jpg'
import culture from './../../autresImages/Second/culture.jpg'
import culture2 from './../../autresImages/Second/culture2.jpg'
import culture3 from './../../autresImages/Second/culture3.jpg'
import culture4 from './../../autresImages/Second/culture4.jpg'
import eleve2 from './../../autresImages/Second/eleve2.jpg'
import eleve3 from './../../autresImages/Second/eleve3.jpg'
import eleve6 from './../../autresImages/Second/eleve6.jpg'
import eleve8 from './../../autresImages/Second/eleve8.jpg'
import eleve11 from './../../autresImages/Second/eleve11.jpg'
import finaliste from './../../autresImages/Second/finaliste.jpg'
import finaliste2 from './../../autresImages/Second/finaliste2.jpg'
import internat from './../../autresImages/Second/internat.jpg'
import maternelle3 from './../../autresImages/Second/maternelle3.jpg'

export const imageDatae = [{
        id: 1,
        image: batiment8,
        titre: 'Batiment',
        description: 'Batiment 7èmes et 8èmes Education de Base',
    },
    {
        id: 2,
        image: batiment9,
        titre: 'Batiment',
        description: 'Tour principale du Collège',
    },
    {
        id: 3,
        image: chorale,
        titre: 'Chorale Stella Duce',
        description: "Chorale des élèves du Collège à l'occasion de la messe des sacrements",
    },
    {
        id: 4,
        image: culture,
        titre: 'Culture',
        description: "Interprétation d'une chanson par une élève",
    },
    {
        id: 5,
        image: culture2,
        titre: 'Culture',
        description: "Interprétation d'une danse par un groupe d'élèves",
    },
    {
        id: 6,
        image: culture3,
        titre: 'Culture',
        description: "Interprétation d'une chanson par une élève",
    },
    {
        id: 7,
        image: culture4,
        titre: 'Culture',
        description: "Interprétation d'une chanson danse traditionnelle par un groupe d'élèves",
    },
    {
        id: 8,
        image: eleve2,
        titre: 'Gouvernement des élèves',
        description: "L'équipe du gouvernement des élèves",
    },
    {
        id: 9,
        image: eleve3,
        titre: 'Elèves',
        description: 'Groupe des élèves finalistes',
    },
    {
        id: 10,
        image: eleve6,
        titre: 'Elèves',
        description: 'Groupe des élèves finalistes',
    },
    {
        id: 11,
        image: eleve8,
        titre: 'Elèves',
        description: "Elèves finalistes à l''occasion de la remise des diplômes d'excellence",
    },
    {
        id: 12,
        image: eleve11,
        titre: 'Elèves',
        description: 'Gouvernement des élèves accompagné du Premier Ministre',
    },
    {
        id: 13,
        image: finaliste,
        titre: 'Elèves',
        description: "Elèves finalistes accompagnés de l'ancien Père Directeur des Etudes",
    },
    {
        id: 14,
        image: finaliste2,
        titre: 'Elèves',
        description: "Elèves finalistes accompagnés de l'ancien Père Recteur",
    },
    {
        id: 15,
        image: internat,
        titre: 'Internat',
        description: "Elèves internes accompagnés du Directeur de l'Internat",
    },
    {
        id: 16,
        image: maternelle3,
        titre: 'Elèves',
        description: "Elèves de l'école maternelle lors d'une visite",
    },
]