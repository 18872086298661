import React from 'react'
import Back from '../common/back/Back'
import PublicationsCard from './PublicationsCard'

function Publications() {
  return (
    <>
      <Back title={'Parcourez les publications de nos élèves ...'} />
      {/*  {<div className='separator'></div>} */}
      <PublicationsCard />
    </>
  )
}

export default Publications
