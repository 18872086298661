import React from 'react'
import './AlfaNews.style.css'
import Back from '../../common/back/Back'
import ArticleCard from '../../common/articleCard/ArticleCard'
import alfaNews2 from '../../../documents/news1.pdf'
import alfaNews3 from '../../../documents/news3.pdf'
import alfaNews1 from '../../../documents/news2.pdf'
import alfaNews4 from '../../../documents/news4.pdf'
import alfaNews6 from '../../../documents/news6.pdf'
import alfaNews7 from '../../../documents/news7.pdf'
import alfaNewsSpecial from '../../../documents/newsSpecial.pdf'

const AlfaNews = () => {
  const openDocument = (document) => {
    window.open(document)
  }
  return (
    <div>
      <Back />
      <div className='alfaNews'>
        <a onClick={() => openDocument(alfaNews1)} className='newsContainer'>
          <ArticleCard
            titre={'N°001/2022'}
            sousTitre={"C'est la rentrée ! Enfin ..."}
            image={require('../../../autresImages/Second/eleve.jpg')}
            className='newsContainer'
          />{' '}
        </a>
        <a onClick={() => openDocument(alfaNews3)} className='newsContainer'>
          <ArticleCard
            titre={'N°06/2022'}
            sousTitre={'Parlons de la vie au Collège ...'}
            image={require('../../../autresImages/Second/eleve10.jpg')}
          />{' '}
        </a>
        <a onClick={() => openDocument(alfaNews2)} className='newsContainer'>
          <ArticleCard
            titre={'N°03/Novembre 2023'}
            sousTitre={"La lecture developpe l'esprit ..."}
            image={require('../../../autresImages/Second/eleve3.jpg')}
          />{' '}
        </a>
        <a onClick={() => openDocument(alfaNews4)} className='newsContainer'>
          <ArticleCard
            titre={'N°04/Novembre 2023'}
            sousTitre={"La nécessité d'une bonne information ..."}
            image={require('../../../autresImages/Second/eleve9.jpg')}
          />{' '}
        </a>
        <a onClick={() => openDocument(alfaNews6)} className='newsContainer'>
          <ArticleCard
            titre={'N°06/Novembre 2023'}
            sousTitre={"Le plaisir et la noblesse d'informer ..."}
            image={require('../../../autresImages/Second/eleve11.jpg')}
          />{' '}
        </a>
        <a onClick={() => openDocument(alfaNews7)} className='newsContainer'>
          <ArticleCard
            titre={'N°07/Décembre 2023'}
            sousTitre={"C'est Noël ..."}
            image={require('../../../autresImages/Second/spiritualite2.jpg')}
          />{' '}
        </a>
        <a
          onClick={() => openDocument(alfaNewsSpecial)}
          className='newsContainer'
        >
          <ArticleCard
            titre={'Numéro Spécial'}
            sousTitre={'Pour notre Alma Mater...'}
            image={require('../../../autresImages/Second/batiment8.jpg')}
          />{' '}
        </a>
      </div>
    </div>
  )
}

export default AlfaNews
