import React from 'react'
import './InscriptionPrimaire.css'
import Back from '../../common/back/Back'
import InscriptionPrimaireCard from './InscriptionPrimaireCard'
const InscriptionPrimaire = () => {
  return (
    <>
      <div className='inscriptionEcolePrimaire'>
        <Back title={"Inscription à l'Ecole Primaire"} />
        <InscriptionPrimaireCard />
      </div>
    </>
  )
}

export default InscriptionPrimaire
