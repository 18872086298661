import React from 'react'

function CardItem({itemIcon, itemTitle, itemText}) {
  return (
    <>
      <div className='img'>{itemIcon}</div>
      <div className='text'>
        <h2>{itemTitle}</h2>
        <p>{itemText}</p>
      </div>
    </>
  )
}

export default CardItem
