import React from 'react'
import {useLocation} from 'react-router-dom'
import {motion} from 'framer-motion'

import Slider from '../Slider'

function Back({title}) {
  const location = useLocation()

  return (
    <>
      <motion.section
        className='back'
        initial={{width: 0}}
        animate={{width: '100%'}}
        exit={{x: window.innerWidth, transition: {duration: 0.1}}}
      >
        <Slider className='slider' />
        <div className='back-title'>
          <h2>Accueil / {location.pathname.split('/')[1]}</h2>
          <h1>{title}</h1>
        </div>
      </motion.section>

      <div className='marigin'></div>
    </>
  )
}

export default Back
