import React from 'react'
import RoiComponent from './RoiComponent'
import {roiSecondaire} from './roiEcoleTxt'

function RoiSecondaire() {
  return (
    <div>
      <RoiComponent
        roiTitre={"Règlements de l'Ecole Secondaire"}
        roiArticles={roiSecondaire}
      />
    </div>
  )
}

export default RoiSecondaire
