import React from 'react'
import './InscriptionMaternelle.css'
import Back from '../../common/back/Back'
import InscriptionMaternelleCard from './InscriptionMaternelleCard'

const InscriptionMaternelle = () => {
  return (
    <div className='inscriptionMaternelle'>
      <Back title={'INSCRIPTIONS EN 2e MATERNELLE 2023-2024'}></Back>

      <InscriptionMaternelleCard />
    </div>
  )
}

export default InscriptionMaternelle
